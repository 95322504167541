import { CountryCode } from './enums';

export enum Permission {
  // STUDENT
  STUDENT = 'STUDENT',
  UPLOAD_STUDENT_INFO = 'UPLOAD_STUDENT_INFO',
  UPDATE_STUDENT_INFO = 'UPDATE_STUDENT_INFO',
  READ_STUDENT_INFO = 'READ_STUDENT_INFO',
  CREATE_STUDENT_REPORT = 'CREATE_STUDENT_REPORT',

  // FILE SYSTEM
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPDATE_FILE = 'UPDATE_FILE',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  DELETE_FILE = 'DELETE_FILE',
  READ_USER_INFO = 'READ_USER_INFO',

  // UNIT MANAGEMENT
  CREATE_UNIT = 'CREATE_UNIT',
  UPDATE_UNIT = 'UPDATE_UNIT',
  DELETE_UNIT = 'DELETE_UNIT',
  READ_UNIT = 'READ_UNIT',

  // CONTACT SYSTEM
  UPDATE_SUPPORT_TICKET = 'UPDATE_SUPPORT_TICKET',
  READ_SUPPORT_TICKET = 'READ_SUPPORT_TICKET',

  // CERTIFICATE BATCH
  CREATE_CERTIFICATE_BATCH = 'CREATE_CERTIFICATE_BATCH',
  READ_CERTIFICATE_BATCH = 'READ_CERTIFICATE_BATCH',
  UPDATE_CERTIFICATE_BATCH = 'UPDATE_CERTIFICATE_BATCH',
  DELETE_CERTIFICATE_BATCH = 'DELETE_CERTIFICATE_BATCH',

  // CERTIFICATE TEMPLATE
  CREATE_CERTIFICATE_TEMPLATE = 'CREATE_CERTIFICATE_TEMPLATE',
  READ_CERTIFICATE_TEMPLATE = 'READ_CERTIFICATE_TEMPLATE',
  UPDATE_CERTIFICATE_TEMPLATE = 'UPDATE_CERTIFICATE_TEMPLATE',

  // CERTIFICATE
  UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE',

  // READ_ALL_EVENT = 'READ_ALL_EVENT',
  READ_EVENT_STATISTIC = 'READ_EVENT_STATISTIC', // value without s at the end
  CREATE_EVENT = 'CREATE_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  VERIFY_EVENT = 'VERIFY_EVENT',
  READ_ALL_EVENT = 'READ_ALL_EVENT',
}

export type PermissionGroup = Permission;

export type AccessLevel = {
  _id?: string;
  name: string;
  predefinedId?: string;
  description: string;
  permissions: Permission[];
  createdAt: number;
  createdBy: string | User;
  deletedAt?: number;
};

export enum Gender {
  MALE = 'Nam',
  FEMALE = 'Nữ',
  OTHER = 'Khác',
}

export type Student = {
  _id?: string;
  // Thông tin chung
  familyName?: string; // Họ
  firstName: string; // Tên
  phone: string; // Số điện thoại
  email: string; // Email HCMUT
  studentId: string; // Mã sinh viên
  dob?: string; // Ngày sinh
  gender: Gender; // Giới tính
  hometown?: string; // Quê quán
  ethnic?: string; // Dân tộc
  address?: string; // Địa chỉ thường trú
  religion?: string; // Tôn giáo
  job?: string; // Nghề nghiệp

  // Thông tin công dân
  citizenShip: {
    citizenId: string; // Số CMND
    citizenIdIssueDate: number; // Ngày cấp CMND
    citizenIdIssuePlace: string; // Nơi cấp CMND
    nation?: CountryCode; // Quốc tịch
  };

  // Trình độ học vấn
  standards?: {
    academic?: string; // Trình độ văn hoá (Ví dụ: Hệ 12 / 12)
    professional?: string; // Trình độ chuyên môn
    politicalTheory?: string; // Trình độ lý luận chính trị
    computer?: string; // Trình độ tin học
    foreignLanguage?: string; // Trình độ ngoại ngữ
  };

  // Thông tin Đoàn Hội
  union: {
    memberId: string; // Mã định danh đoàn viên
    issuedDate: number; // Thời gian vào đoàn
    resolutionNumber: string; // Số Nghị quyết chuẩn y kết nạp đoàn viên
    issuedPlace: string; // Nơi ký
    cardNumber: string; // Số thẻ đoàn viên
    membership?: string; // Đối tượng đoàn viên
    practice?: string; // Rèn luyện đoàn viên
    qualification?: string; // Đánh giá, xếp loại đoàn viên
    status?: string; // (Chức vụ) Tình trạng đoàn viên
    group?: string; // "Hội"
    rewards?: string[]; // Khen thưởng TODO: Change to Reward[]
    discipline?: string[]; // Kỷ luật
    unit?: string; // Đơn vị
  };

  // Thông tin Đảng
  party?: {
    partyIssueDate: number; // Ngày vào đảng
  };
  createdAt: number;
  deletedAt?: number;
};

export enum StudentUpdateLogType {
  SINGLE = 'single',
  BULK = 'bulk',
}

export type StudentUpdateLog = {
  _id?: string;
  type: StudentUpdateLogType;
  documentId: string; // For single update
  isSuccess: boolean;
  message: string;
  createdAt: number;
};

export enum EventUpdateLogType {
  SINGLE = 'single',
  BULK = 'bulk',
}

export type EventUpdateLog = {
  _id?: string;
  type: EventUpdateLogType;
  documentId?: string; // For single update
  isSuccess?: boolean;
  message: string;
  createdAt: number;
};

export type User = {
  _id?: string;
  name: string;
  googleId: string;
  picture: string;
  email: string;
  // accessLevels: (string | AccessLevel)[];
  permissions?: Permission[]; // For frontend
  isManager: boolean;
};

export type Attachment = {
  _id?: string;
  originalName: string;
  filename: string;
  directoryId: string;
  refName: string;
  size: number;
  mimetype: string;
  createdAt: number;
  updatedAt: number;
};

export type Directory = {
  _id?: string;
  name: string;
  parentId?: string;
  directoryList: {
    _id: string;
    name: string;
  }[];
  predefined?: boolean;
  createdAt: number;
};

export type CertParameter = {
  _id: string;
  value: string | null;
  key: string;
  name: string;
  paramType?: 'image' | 'text';
  required: boolean;
  coeffX: number;
  coeffY: number;
  originX?: 'center' | 'left' | 'right';
  originY?: 'center' | 'top' | 'bottom';
  fontSize?: number;
  fontFamily?: string;
  textAlign?:
    | 'left'
    | 'center'
    | 'right'
    | 'justify'
    | 'justify-left'
    | 'justify-center'
    | 'justify-right';
  fontWeight?: string | number;
  underline?: boolean;
  fontStyle?: '' | 'normal' | 'italic' | 'oblique';
  fill?: string;
  width?: number;
  height?: number;
  hidden?: boolean;
};

export type CertificateBatch = {
  _id: string;
  name: string;
  batchId: string;
  description: string;
  template: string | CertificateTemplate;

  // Signee section
  signee?: string;
  signeePosition?: string;
  signature?: Attachment;
  signatureUrl?: string;

  // Decision section
  decisionId?: string;
  decisionDate?: number;
  decisionDocument?: Attachment;
  decisionQrUrl?: string;

  createdBy: User;
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
};

export type CertificateTemplate = {
  _id: string;
  name: string;
  description: string;
  parameters: CertParameter[];
  background: string | Attachment;
  backgroundUrl: string;
  thumbnail: string;
  createdBy: User;
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
};

export type Certificate = {
  _id: string;
  certificateId: string;
  recipient: string;
  batch: CertificateBatch;
  template: CertificateTemplate;
  thumbnail: string;
  parameters: CertParameter[];
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
};

export enum EventActionModule {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  VERIFICATION = 'VERIFICATION',
  CHECK_IN = 'CHECK_IN',
  ATTENDANCE = 'ATTENDANCE',
  REGISTRATION = 'REGISTRATION',
  REPORT = 'REPORT',
}

export enum EventStatus {
  PRE_EVENT = 'pre-event',
  OPEN_FOR_REGISTRATION = 'open-for-registration',
  ON_EVENT = 'on-event',
  POST_EVENT = 'post-event',
  CLOSED = 'closed',
}

export type EventAction = {
  _id: string;
  module: EventActionModule;
  title: string;
  description: string;
  attachments: Attachment[];
  createdAt: number;
  updatedAt: number;
};

export type CheckRequest = {
  _id: string;
  participantID: string;
  isSuccess: boolean;
  createdAt: number;
  updatedAt: number;
};

export type EventPeriod = {
  _id: string;
  event: string;
  periodType: 'checkin' | 'attendance' | 'registration';
  title: string;
  startAt: number;
  endAt: number;
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
};

export type RegistrationPeriod = EventPeriod & {
  periodType: 'registration';
  allowedRoles: Array<string>;
  maxRegistration: number;
};

export type EventVerificationReview = {
  _id: string;
  user: string;
  isVerified: boolean;
  comment: string;

  attachments: Attachment[];

  createdAt: number;
  updatedAt: number;
};

export type EventVerificationChange = {
  metadata: {
    title: string;
    description: string;
    location: string;
  };
  startAt?: number;
  endAt?: number;
  participants: {
    add: Array<string>;
    remove: Array<string>;
  };
};

export enum EventStatusInfo {
  VERIFIED = 'VERIFIED',
  EDITING = 'PENDING',
  CHANGE_REQUESTED = 'REJECTED',
}

export type EventVerification = {
  _id: string;
  event: string;
  requestor: string;
  isMerged: boolean;
  reviews: EventVerificationReview[];

  changes: EventVerificationChange;

  createdAt: number;

  updatedAt: number;

  deletedAt?: number;
};

export enum EventPermissions {
  LEADER = 'LEADER',

  // Modification
  MODIFY_TIMELINE = 'MODIFY_TIMELINE',
  MODIFY_DATA = 'MODIFY_DATA',
  MODIFY_PARTICIPANT = 'MODIFY_PARTICIPANT',
  SUMBIT_FOR_VERIFICATION = 'SUMBIT_FOR_VERIFICATION',

  // Checkin
  CHECKIN = 'CHECKIN',

  // Attendance
  REGISTER_ATTENDANCE = 'REGISTER_ATTENDANCE',

  GENERATE_REPORT = 'GENERATE_REPORT',
  CLOSE_EVENT = 'CLOSE_EVENT',
}

export enum ActionType {
  CREATED = 'NEW',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  DEFAULT = 'DEFAULT',
}

export type RoleCard = {
  title: string;
  description: string;
  permissions: EventPermissions[];
  actionType: ActionType;
  setShowRoleModal?: (value: boolean) => void;
};

export type EventRole = {
  _id: string;
  title: string;
  description: string;
  maxRegistration: number;
  permissions: EventPermissions[];
  deactivatedAt?: number;
};

export type Event = {
  _id: string;

  // Metadata
  status: EventStatus;
  title: string;
  description: string;
  location: string;
  poster?: {
    preview: string;
    original: string;
  };

  // Roles
  roles: EventRole[];

  // Period
  type: string;
  startAt: number;
  endAt: number;
  createdBy: {
    _id: string;
    name: string;
    email: string;
  };
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
};

export type Participant = {
  _id: string;
  user: {
    _id: string;
    name: string;
    email: string;
  };
  role: string;
  checkedIn: boolean;
  attendedPeriods: {
    _id: string;
    period: string;
    attendedAt: number;
  }[];
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
};

export enum SupportTicketSubject {
  COURSE_REGISTRATION = 'COURSE_REGISTRATION',
  SCHOLARSHIP = 'SCHOLARSHIP',
  TUITION_FEE = 'TUITION_FEE',
  CIVIC_ACTITIVIES = 'CIVIC_ACTITIVIES',
  GRADE_GRADUATION = 'GRADE_GRADUATION',
}

export enum SupportTicketStatus {
  UNVERIFIED = 'UNVERIFIED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FINISHED = 'FINISHED',
  PROCESSING = 'PROCESSING',
}

export type Message = {
  _id: string;
  content: string;
  attachments: Attachment[];
  createdAt: number;
  createdBy: User;
};

export type SupportTicket = {
  _id: string;

  title: string;
  subject: SupportTicketSubject;
  status: SupportTicketStatus;
  messages: Message[];
  createdAt: number;
  createdBy: User;
  updatedAt: number;
  reason?: string;
};

export enum UnitTicketSubject {
  COURSE_REGISTRATION = 'COURSE_REGISTRATION',
  SCHOLARSHIP = 'SCHOLARSHIP',
  TUITION_FEE = 'TUITION_FEE',
  CIVIC_ACTITIVIES = 'CIVIC_ACTITIVIES',
  GRADE_GRADUATION = 'GRADE_GRADUATION',
}

export type UnitTicket = {
  _id: string;

  supportTicketId: SupportTicket;
  unitId: {
    _id: string;
    name: string;
  };
  title: string;
  messages: Message[];

  createdAt: number;
  createdBy: User;
  updatedAt: number;
};

export enum UnitType {
  ORGANIZATION = 'ORGANIZATION',
  TEACHER = 'TEACHER',
}

export type Unit = {
  _id: string;

  name: string;
  description: string;
  type: UnitType;
  users: User[];

  createdAt: number;
  createdBy: string;
  updatedAt: number;

  contactEmail: string;
  contactPhone: string;
};
