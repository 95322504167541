import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface ContinueModalProps {
  isOpen: boolean;
  isSending: boolean;
  onContinueClick?: React.MouseEventHandler | undefined;
  onCloseClick?: React.MouseEventHandler | undefined;
  title: string;
  content: string;
}

const ContinueModal = ({
  isOpen,
  isSending,
  onContinueClick,
  onCloseClick,
  title,
  content,
}: ContinueModalProps) => {
  return (
    <Modal isOpen={isOpen} centered={true} toggle={onCloseClick}>
      <ModalHeader toggle={onCloseClick}>
        <h1 className='tw-content-center tw-mb-0 tw-text-[1.5rem] tw-font-bold tw-text-primary'>
          {title}
        </h1>
      </ModalHeader>

      <ModalBody className='tw-p-[1rem] register tw-flex tw-flex-col tw-text-[1rem] '>
        {content}
      </ModalBody>
      <ModalFooter>
        <Button
          color='secondary'
          className='btn btn-primary waves-effect waves-light'
          onClick={onCloseClick}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          className='btn btn-primary waves-effect waves-light'
          onClick={onContinueClick}
          disabled={isSending}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContinueModal;
