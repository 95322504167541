import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo-light.png';

const Header = () => {
  return (
    <React.Fragment>
      <header id='page-topbar' className='left-0'>
        <div className='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box'>
              <Link to='/' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logo} alt='' width='55' height='55' />
                </span>
                <span className='logo-lg'>
                  <img src={logo} alt='' height='55' />
                </span>
              </Link>

              <Link to='/' className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logo} alt='' width='55' height='55' />
                </span>
                <span className='logo-lg'>
                  <img src={logo} alt='' height='55' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
