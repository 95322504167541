import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Container } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import StudentService from 'services/student.service';
import { StudentUpdateLog, StudentUpdateLogType } from 'types';

const limit = 10;

const History = () => {
  useTitle('Change History', {
    restoreOnUnmount: true,
  });

  const [updateLogs, setUpdateLogs] = React.useState<StudentUpdateLog[]>([]);

  const [page, setPage] = React.useState<number>(1);

  const [finish, setFinish] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    const fetchUpdateLogs = async () => {
      try {
        const { data } = await StudentService.getUpdateLogs(limit, limit * (page - 1));
        const { payload } = data;
        if (payload.length === 0) setFinish(true);
        setUpdateLogs((previousUpdateLogs) => previousUpdateLogs.concat(payload));
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        setIsLoading(false);
      }
    };

    fetchUpdateLogs().catch((err) => console.log(err));
  }, [page]);

  if (isLoading) return <Loading width={600} height={600} />;

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Thông tin sinh viên' breadcrumbItem='Lịch sử cập nhật' />
          <ul className='verti-timeline list-unstyled mb-4'>
            {updateLogs.map((updateLog, index) => (
              <li
                key={updateLog._id || index}
                className={`event-list ${
                  updateLog.type === StudentUpdateLogType.BULK ? 'active' : ''
                }`}
              >
                <div
                  className={`${
                    updateLog.type === StudentUpdateLogType.BULK
                      ? 'event-timeline-dot-large'
                      : 'event-timeline-dot'
                  }`}
                >
                  <i
                    className={`bx ${
                      updateLog.type === StudentUpdateLogType.BULK
                        ? 'bxs-right-arrow-circle font-size-24'
                        : 'bx-right-arrow-circle font-size-18'
                    }`}
                  />
                </div>
                <div className='row'>
                  <div className='col-2'>
                    <h5 className='font-size-14'>
                      {' '}
                      {new Date(updateLog.createdAt).toLocaleString('vi-VN')}
                      <i className='bx bxs-right-icon font-size-16 text-primary align-middle ms-2' />{' '}
                    </h5>
                  </div>
                  <div className='col-10'>
                    <div>{updateLog.message}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Button
            type='button'
            color='primary'
            className='w-md'
            disabled={finish}
            onClick={() => setPage(page + 1)}
          >
            Xem thêm
          </Button>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default History;
