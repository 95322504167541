import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  // Badge,
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap';

// import { subjectTranslate } from 'helpers/translate';
import useTitle from 'hooks/useTitle';

const Message = () => {
  return (
    <Card>
      <CardBody>
        <div className='d-flex mb-4'>
          {/* <img className='d-flex me-3 rounded-circle avatar-sm' src='' alt='skote' /> */}
          <div className='flex-grow-1'>
            <small className='text-muted'>by support@domain.com</small>
          </div>
        </div>

        <h4 className='mt-0 font-size-16'>This Week&apos;s Top Stories</h4>

        <p>Dear Lorem Ipsum,</p>
        <p>
          Praesent dui ex, dapibus eget mauris ut, finibus vestibulum enim. Quisque arcu leo,
          facilisis in fringilla id, luctus in tortor. Nunc vestibulum est quis orci varius viverra.
          Curabitur dictum volutpat massa vulputate molestie. In at felis ac velit maximus
          convallis.
        </p>
        <p>
          Sed elementum turpis eu lorem interdum, sed porttitor eros commodo. Nam eu venenatis
          tortor, id lacinia diam. Sed aliquam in dui et porta. Sed bibendum orci non tincidunt
          ultrices. Vivamus fringilla, mi lacinia dapibus condimentum, ipsum urna lacinia lacus, vel
          tincidunt mi nibh sit amet lorem.
        </p>
        <p>Sincerly,</p>
        <hr />

        <Row>
          <Col xl='2' xs='6'>
            <Card>
              <img className='card-img-top img-fluid' src='' alt='skote' />
              <div className='py-2 text-center'>
                <Link to='' className='fw-medium'>
                  Download
                </Link>
              </div>
            </Card>
          </Col>
          <Col xl='2' xs='6'>
            <Card>
              <img className='card-img-top img-fluid' src='' alt='skote' />
              <div className='py-2 text-center'>
                <Link to='' className='fw-medium'>
                  Download
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const UnitTicketContact = () => {
  useTitle('Ticket detail', {
    restoreOnUnmount: true,
  });

  const [toggleReply, setToggleReply] = useState(false);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='OContact' breadcrumbitem='Contact system' />
        </Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className='card-title mb-4'>Title</CardTitle>
                <Row className='mb-4'>
                  <Row>
                    <Col>
                      {/* <Badge color='primary' className='font-size-14 mb-2'>
                        {subjectTranslate(UnitTicketSubject.COURSE_REGISTRATION)}
                      </Badge> */}
                    </Col>
                  </Row>
                  <Row>
                    <Message />
                    <Message />
                  </Row>
                  {!toggleReply && (
                    <div className='text-end'>
                      <Button
                        type='button'
                        color='primary'
                        className='ms-1 btn mt-2'
                        onClick={() => setToggleReply(true)}
                      >
                        Reply
                      </Button>
                    </div>
                  )}
                  {toggleReply && (
                    <Card>
                      <CardBody>
                        <div className='mt-4'>
                          <h5 className='font-size-16 mb-3'>Reply</h5>

                          <div className='mb-3'>
                            <Label htmlFor='commentmessage-input'>Message</Label>
                            <Input
                              tag='textarea'
                              id='commentmessage-input'
                              placeholder='Your message...'
                              rows={3}
                            ></Input>
                          </div>

                          <div className='text-end'>
                            <Button
                              type='button'
                              color='primary'
                              outline
                              className='btn mt-2 me-1'
                              onClick={() => setToggleReply(false)}
                            >
                              Cancel
                            </Button>
                            <Button type='button' color='primary' className='ms-1 btn mt-2'>
                              Send
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UnitTicketContact;
