import React from 'react';

import CreatingContactSVG from '../../assets/svg/Group296.svg';
import SubjectsSVG from '../../assets/svg/Group300.svg';
import KeepContactSVG from '../../assets/svg/Group307.svg';

const OISP = () => {
  return (
    <React.Fragment>
      <div className='tw-w-full tw-mb-[30px] md:tw-mb-[50px] 2xl:tw-mb-[100px] lg:tw-mt-[-20px] xl:tw-mt-0 '>
        <h1 className=' tw-text-[28px] md:tw-text-[32px] xl:tw-text-[40px] tw-px-4 md:tw-px-6 2xl:tw-text-[48px] tw-font-extrabold tw-text-[#3D4863] tw-text-center tw-mb-[25px] '>
          OISP is always attentive to your needs
        </h1>
        <p className='tw-text-[14px] tw-px-[20px] sm:tw-px-14 md:tw-px-14 lg:tw-px-14 2xl:tw-text-[20px] tw-text-[#3D4863]  tw-text-center tw-mb-[40px] lg:tw-mb-[60px] xl:tw-mb-[80px] '>
          Whether you have a problem with academic affairs or want to know more about a scholarship,
          OISP is open to hear every of your concerns.
          <br />
          Download the app version to contact OISP and try other features now!
        </p>
        <div className='tw-flex tw-flex-col tw-w-[280px] sm:tw-w-[300px] lg:tw-w-[90%] md:tw-w-[40%] lg:tw-px-[40px] xl:tw-px-[50px] lg:tw-flex-row lg:tw-gap-[30px] 2xl:tw-gap-[16px] tw-justify-center tw-mx-auto lg:tw-justify-between  tw-items-center'>
          <div className='tw-w-full lg:tw-w-1/3 xl:tw-w-[28%]'>
            <img
              src={CreatingContactSVG}
              alt=''
              className='tw-mb-[8px] md:tw-mb-[16px] tw-mx-auto lg:tw-mb-[16px] tw-w-full'
            />
            <p className='tw-text-center tw-mx-auto tw-mb-0 tw-h-[50px] tw-text-[14px] 2xl:tw-text-[20px] tw-text-[#3D4863] tw-w-full'>
              Creating a contact is quick and easy with a form
            </p>
          </div>
          <div className='tw-w-full lg:tw-w-1/3 xl:tw-w-[28%]'>
            <img
              src={SubjectsSVG}
              alt=''
              className='tw-mb-[8px] md:tw-mb-[16px] tw-mx-auto lg:tw-mb-[16px] tw-w-full'
            />
            <p className='tw-text-center tw-mx-auto tw-mb-0 tw-h-[50px] tw-text-[14px] 2xl:tw-text-[20px] tw-text-[#3D4863] tw-w-full'>
              See what subjects are trending at the moment
            </p>
          </div>
          <div className='tw-w-full lg:tw-w-1/3 xl:tw-w-[28%]'>
            <img
              src={KeepContactSVG}
              alt=''
              className='tw-mb-[8px] md:tw-mb-[16px] tw-mx-auto lg:tw-mb-[16px] tw-w-full'
            />
            <p className='tw-text-center tw-mx-auto tw-mb-0 tw-h-[50px] tw-text-[14px] 2xl:tw-text-[20px] tw-text-[#3D4863] tw-w-full'>
              Keep track of your old contacts in a single place
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OISP;
