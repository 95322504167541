import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { Attachment, Directory, ResponseData } from 'types';

const StorageService = {
  getDirectoryInfo: async (directoryId = '') =>
    axios.get<ResponseData<Directory>>(`${API_URL}admin/storage/directory/${directoryId}`),

  getAttachmentList: async (directoryId = '') =>
    axios.get<ResponseData<Attachment[]>>(
      `${API_URL}admin/storage/directory/attachmentList/${directoryId}`
    ),

  createDirectory: async (parentId: string, name: string) =>
    axios.post<
      ResponseData<{
        newDirectory: Directory;
        updatedParentDirectory: Directory;
      }>
    >(`${API_URL}admin/storage/directory`, { parentId, name }),

  deleteDirectory: async (directoryId: string) =>
    axios.delete<
      ResponseData<{
        deletedDirectory: Directory;
        updatedParentDirectory: Directory;
      }>
    >(`${API_URL}admin/storage/directory/${directoryId}`),

  renameDirectory: async (directoryId: string, newName: string) =>
    axios.patch<
      ResponseData<{
        updatedDirectory: Directory;
        updatedParentDirectory: Directory;
      }>
    >(`${API_URL}admin/storage/directory/${directoryId}`, { newName }),

  uploadAttachment: async (directoryId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('directoryId', directoryId);

    return axios.post<ResponseData<Attachment>>(`${API_URL}admin/storage/attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  downloadAttachment: async (attachmentId: string) =>
    axios.get<ArrayBuffer>(`${API_URL}admin/storage/attachment/download/${attachmentId}`, {
      responseType: 'arraybuffer',
    }),

  deleteAttachment: async (attachmentId: string) =>
    axios.delete<ResponseData<Attachment>>(`${API_URL}admin/storage/attachment/${attachmentId}`),

  renameAttachment: async (attachmentId: string, newName: string) =>
    axios.patch<ResponseData<Attachment>>(`${API_URL}admin/storage/attachment/${attachmentId}`, {
      newName,
    }),
};

export default StorageService;
