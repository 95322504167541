import { useFormik } from 'formik';
import _ from 'lodash';
import mime from 'mime';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
// import timeSince from 'helpers/time-since';
import useTitle from 'hooks/useTitle';
import CertificateBatchService from 'services/certificate-batch.service';
import CertificateTemplateService from 'services/certificate-template.service';
import { CertParameter, CertificateTemplate } from 'types';

import temporaryThumbnail from '../../assets/images/tempThumbnail.png';

import { CreateRecipientModal } from './AddRecipientModal';
import { CertificateContainer } from './CertificateContainer';
import CreateProgressModal from './CreateProgressModal';
// import CertificateEditspace from './CertificateEditspace';

const limit = 20;

const initialValue = {
  name: '',
  batchId: '',
  description: '',
  signee: '',
  signeePosition: '',
  decisionId: '',
  decisionDate: new Date(),
  template: '',
};

const initialTemplate: CertificateTemplate = {
  _id: '',
  name: '',
  description: '',
  parameters: [],
  background: {
    _id: '',
    originalName: '',
    filename: '',
    directoryId: '',
    refName: '',
    size: 0,
    mimetype: '',
    createdAt: 0,
    updatedAt: 0,
  },
  backgroundUrl: '',
  thumbnail: '',
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};

const specialParameters = [
  'signee',
  'signeePosition',
  'signature',
  'date',
  'decisionId',
  'decisionDate',
  'decisionQr',
];

type TemplateCardProps = {
  template: CertificateTemplate;
  displayTemplate: CertificateTemplate;
  onClick: (template: CertificateTemplate) => void;
};

const TemplateCard = ({ template, displayTemplate, onClick }: TemplateCardProps) => {
  return (
    <Col xs={4} sm={4}>
      <Card
        className={`hover-pointer border ${
          displayTemplate === template ? 'bg-primary text-white' : ''
        }`}
        onClick={() => {
          onClick(template);
        }}
      >
        <CardBody>
          <img
            alt={template.name}
            className='px-2 py-2 img-fluid mx-auto d-block rounded'
            src={template.thumbnail}
            style={{ maxHeight: '80px', transitionDelay: '200ms' }}
          />
          <div className='d-flex mt-4'>
            <div className='flex-grow-1 overflow-hidden'>
              <h5
                className={`text-truncate font-size-15 ${
                  displayTemplate === template ? 'text-white' : 'text-dark'
                }`}
              >
                {template.name}
              </h5>
              <p className={` ${displayTemplate === template ? 'text-white' : 'text-muted'}`}>
                {template.description}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const CreateCertificate = () => {
  useTitle('Create certificate', {
    restoreOnUnmount: true,
  });

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [certificates, setCertificates] = useState<
    Array<{
      recipientEmail: string;
      parameters: CertParameter[];
    }>
  >([]);
  const [certificateCount, setCertificateCount] = useState(0);
  const [submitBatch, setSubmitBatch] = useState(initialValue);
  const [signatureFile, setSignatureFile] = useState<File | undefined>(undefined);
  const [signatureUploadError, setSignatureUploadError] = useState<string>('');
  const [qrFile, setQrFile] = useState<File | undefined>(undefined);
  const [qrUploadError, setQrUploadError] = useState<string>('');
  const [templateList, setTemplateList] = useState<CertificateTemplate[]>([]);
  const [displayTemplate, setDisplayTemplate] = useState<CertificateTemplate>(initialTemplate);
  const [stages, setStages] = useState<
    Array<{ name: string; status: 'Waiting' | 'In progress' | 'Done' | 'Skipped' }>
  >([
    { name: 'Information', status: 'Waiting' },
    { name: 'Signature', status: 'Waiting' },
    { name: 'Decision', status: 'Waiting' },
  ]);
  const [loading, setLoading] = useState(false);
  const [createRecipientModal, setCreateRecipientModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const createCertificateBatch = async () => {
    setLoading(true);
    try {
      setStages((oldStages) => {
        const newStages = [...oldStages];
        newStages[0].status = 'In progress';
        return newStages;
      });
      const newCertificateBatch = {
        name: submitBatch.name,
        batchId: submitBatch.batchId,
        description: submitBatch.description,
        template: displayTemplate._id,
      };

      console.log('submit batch', submitBatch);
      console.log('new batch basic info', newCertificateBatch);
      console.log('info', certificates);

      const { data } = await CertificateBatchService.createCertificateBatch(
        newCertificateBatch,
        certificates
      );

      const batchId = data?.payload?.batch?._id;

      // Update signee section
      if (submitBatch.signee && submitBatch.signeePosition) {
        setStages((oldStages) => {
          const newStages = [...oldStages];
          newStages[0].status = 'Done';
          newStages[1].status = 'In progress';
          return newStages;
        });
        await CertificateBatchService.updateBatchSignee(
          batchId,
          submitBatch.signee,
          submitBatch.signeePosition,
          signatureFile
        );
      } else {
        setStages((oldStages) => {
          const newStages = [...oldStages];
          newStages[0].status = 'Done';
          newStages[1].status = 'Skipped';
          return newStages;
        });
      }

      // Update decision section
      if (submitBatch.decisionId && submitBatch.decisionDate && qrFile) {
        setStages((oldStages) => {
          const newStages = [...oldStages];
          if (newStages[1].status !== 'Skipped') newStages[1].status = 'Done';
          newStages[2].status = 'In progress';
          return newStages;
        });
        await CertificateBatchService.updateBatchDecision(
          batchId,
          submitBatch.decisionId,
          new Date(submitBatch.decisionDate).getTime(),
          qrFile
        );
      } else {
        setStages((oldStages) => {
          const newStages = [...oldStages];
          if (newStages[1].status !== 'Skipped') newStages[1].status = 'Done';
          newStages[2].status = 'Skipped';
          return newStages;
        });
      }

      toast.success('A new certificate batch has been added successfully');
      navigate('/certificate');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const validation = useFormik({
    initialValues: initialValue,
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your batch name'),
      batchId: Yup.string()
        .matches(
          /^[A-Z0-9]+(-?[A-Z0-9]+)*$/,
          'Batch ID must be in uppercase (A-Z), digit (0-9) and separated by dash - (optional)'
        )
        .required('Please enter your batch ID'),
      description: Yup.string().default(''),
      signee: Yup.string().optional(),
      signeePosition: Yup.string().optional(),
      decisionId: Yup.string().optional(),
      decisionDate: Yup.date().optional(),
    }),
    onSubmit: (values) => {
      setStep(2);
      const updatedBatch = _.cloneDeep(submitBatch);

      updatedBatch.name = values.name;
      updatedBatch.batchId = values.batchId;
      updatedBatch.description = values.description;
      updatedBatch.signee = values.signee;
      updatedBatch.signeePosition = values.signeePosition;
      updatedBatch.decisionId = values.decisionId;
      updatedBatch.decisionDate = values.decisionDate;

      setSubmitBatch(updatedBatch);
    },
  });

  const addRecipient = (
    certList: Array<{
      recipientEmail: string;
      parameters: CertParameter[];
    }>
  ) => {
    setCertificates(certList);
    setCertificateCount(certList.length);
    setCreateRecipientModal(false);
  };

  const handleSignatureFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile: File | undefined = event.target.files?.[0];
    if (targetFile) {
      if (
        targetFile.type === mime.getType('webp') ||
        targetFile.type === mime.getType('tiff') ||
        targetFile.type === mime.getType('svg') ||
        targetFile.type === mime.getType('png') ||
        targetFile.type === mime.getType('jpeg') ||
        targetFile.type === mime.getType('vnd.microsoft.icon') ||
        targetFile.type === mime.getType('bmp')
      ) {
        setSignatureUploadError('');
        setSignatureFile(targetFile);
      } else {
        setSignatureUploadError('File format is incorrect');
      }
    } else {
      setSignatureUploadError('Please select a file');
    }
  };

  const handleQrFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile: File | undefined = event.target.files?.[0];
    if (targetFile) {
      if (targetFile.type === mime.getType('pdf') || targetFile.type === mime.getType('docx')) {
        setQrUploadError('');
        setQrFile(targetFile);
      } else {
        setQrUploadError('File format is incorrect');
      }
    } else {
      setQrUploadError('Please select a file');
    }
  };

  // const specialParamsMapping = (params: string[]): Map<string, string> => {
  //   const specialParamsMap = new Map<string, string>();

  //   params.forEach((param) => {
  //     if (submitBatch[param]) {
  //       specialParamsMap.set(param, submitBatch[param] as string);
  //     }
  //   });

  //   return specialParamsMap;
  // };

  useEffect(() => {
    CertificateTemplateService.getCertificateTemplateList(limit, 0)
      .then((res) => {
        const { data } = res;
        const { payload } = data;
        console.log(payload);
        setTemplateList(payload.certificateTemplateList);
        setDisplayTemplate(payload.certificateTemplateList[0] || initialTemplate);
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      });
  }, []);

  return (
    <React.Fragment>
      <CreateProgressModal show={loading} stages={stages} />

      <CreateRecipientModal
        // specialParamsMap={specialParamsMapping(specialParameters)}
        specialParams={specialParameters}
        certificateTemplate={displayTemplate}
        show={createRecipientModal}
        onCloseClick={() => {
          setCreateRecipientModal(false);
        }}
        onSubmit={(
          certList: Array<{
            recipientEmail: string;
            parameters: CertParameter[];
          }>
        ) => addRecipient(certList)}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title='Certificate'
            breadcrumbItem='Create new certificate'
            backTo='/certificate'
          />
          <Row>
            <Col>
              <Row>
                <div className='mt-2 mb-2 prog-bar'>
                  <Progress
                    value={(step - 1) * 50}
                    color='primary'
                    style={{ height: '1px' }}
                  ></Progress>
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'space-between',
                      justifyItems: 'center',
                    }}
                  >
                    <div className='prog-step'>
                      <button
                        className='position-relative top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill'
                        style={{ width: '2rem', height: '2rem' }}
                      >
                        1
                      </button>
                      <div className='translate-middle prog-text position-relative'>
                        Basic information
                      </div>
                    </div>
                    <div className='prog-text prog-step'>
                      <button
                        className={` ${
                          step >= 2 ? 'btn-primary' : 'btn-secondary'
                        } position-relative top-0 start-50 translate-middle btn btn-sm btn-primary rounded-pill`}
                        style={{ width: '2rem', height: '2rem' }}
                      >
                        2
                      </button>
                      <div
                        className=' position-relative'
                        style={{ transform: 'translate(0%, -50%)' }}
                      >
                        Choose template
                      </div>
                    </div>

                    <div className='prog-text prog-step'>
                      <button
                        className={` ${
                          step >= 3 ? 'btn-primary' : 'btn-secondary'
                        } position-relative top-0 start-100 translate-middle btn btn-sm btn-primary rounded-pill`}
                        style={{ width: '2rem', height: '2rem' }}
                      >
                        3
                      </button>
                      <div
                        className='prog-text position-relative'
                        style={{ transform: 'translate(50%, -50%)' }}
                      >
                        Choose recipient
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <Card>
                <CardBody>
                  <TabContent activeTab={step}>
                    <TabPane tabId={1}>
                      <CardTitle className='mb-4'>Basic information</CardTitle>
                      <Form onSubmit={validation.handleSubmit} autoComplete='off' className='mt-3'>
                        <FormGroup className='mb-4' row>
                          <Label htmlFor='name' className='col-form-label col-lg-3'>
                            Name
                          </Label>
                          <Col lg={9}>
                            <Input
                              id='name'
                              className='form-control'
                              name='name'
                              type='text'
                              placeholder='Enter certificate name...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ''}
                              invalid={
                                validation.touched.name && validation.errors.name ? true : false
                              }
                            />
                            {validation.touched.name && validation.errors.name ? (
                              <FormFeedback type='invalid'>{validation.errors.name}</FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className='mb-4' row>
                          <Label htmlFor='batchId' className='col-form-label col-lg-3'>
                            Batch's ID
                          </Label>
                          <Col lg={9}>
                            <Input
                              id='batchId'
                              className='form-control'
                              name='batchId'
                              type='text'
                              placeholder='Enter batch ID...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.batchId || ''}
                              invalid={
                                validation.touched.batchId && validation.errors.batchId
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.batchId && validation.errors.batchId ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.batchId}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className='mb-4' row>
                          <Label htmlFor='description' className='col-form-label col-lg-3'>
                            Description
                          </Label>
                          <Col lg={9}>
                            <Input
                              tag='textarea'
                              id='description'
                              rows={7}
                              className='form-control'
                              name='description'
                              placeholder='Enter certificate description...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ''}
                              invalid={
                                validation.touched.description && validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description && validation.errors.description ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <CardTitle className='mb-4'>Signee</CardTitle>

                        <FormGroup className='mb-4' row>
                          <Label htmlFor='signee' className='col-form-label col-lg-3'>
                            Signee
                          </Label>
                          <Col lg={9}>
                            <Input
                              tag='textarea'
                              id='signee'
                              className='form-control'
                              name='signee'
                              placeholder='Enter certificate signee...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.signee || ''}
                              invalid={
                                validation.touched.description && validation.errors.signee
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.signee && validation.errors.signee ? (
                              <FormFeedback type='invalid'>{validation.errors.signee}</FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup className='mb-4' row>
                          <Label htmlFor='signeePosition' className='col-form-label col-lg-3'>
                            Signee's status
                          </Label>
                          <Col lg={9}>
                            <Input
                              tag='textarea'
                              id='signeePosition'
                              rows={3}
                              className='form-control'
                              name='signeePosition'
                              placeholder='Enter signee position...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.signeePosition || ''}
                              invalid={
                                validation.touched.signeePosition &&
                                validation.errors.signeePosition
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.signeePosition &&
                            validation.errors.signeePosition ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.signeePosition}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup className='mb-4' row>
                          <Label htmlFor='signature' className='col-form-label col-lg-3'>
                            Signature
                          </Label>
                          <Col lg={9}>
                            <Input
                              className='form-control'
                              id='signature'
                              type='file'
                              onChange={handleSignatureFileUpload}
                              invalid={signatureUploadError !== ''}
                            />
                            {signatureUploadError ? (
                              <FormFeedback type='invalid' className='font-size-12'>
                                {signatureUploadError}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <CardTitle className='mb-4'>Decision</CardTitle>

                        <FormGroup className='mb-4' row>
                          <Label htmlFor='decisionId' className='col-form-label col-lg-3'>
                            Decision's ID
                          </Label>
                          <Col lg={9}>
                            <Input
                              id='decisionId'
                              className='form-control'
                              name='decisionId'
                              type='text'
                              placeholder='Enter certificate decision ID...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.decisionId || ''}
                              invalid={
                                validation.touched.decisionId && validation.errors.decisionId
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.decisionId && validation.errors.decisionId ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.decisionId}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup className='mb-4' row>
                          <Label htmlFor='decisionDate' className='col-form-label col-lg-3'>
                            Decision's date
                          </Label>
                          <Col lg={9}>
                            <Input
                              id='decisionDate'
                              className='form-control'
                              type='date'
                              name='decisionDate'
                              placeholder='Enter certificate decision date...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.decisionDate && validation.errors.decisionDate
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className='mb-4' row>
                          <Label htmlFor='decisionDocument' className='col-form-label col-lg-3'>
                            Decision's document
                          </Label>
                          <Col lg={9}>
                            <Input
                              className='form-control'
                              id='formFileLg'
                              type='file'
                              onChange={handleQrFileUpload}
                              invalid={qrUploadError !== ''}
                            />
                            {qrUploadError ? (
                              <FormFeedback type='invalid' className='font-size-12'>
                                {qrUploadError}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <Row className='justify-content-end'>
                          <div className='text-end'>
                            <Button type='submit' color='primary' disabled={loading}>
                              Next
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <CardTitle className='mb-4'>Template</CardTitle>
                      <Row>
                        <Col
                          xl={5}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '24px',
                          }}
                        >
                          <CardTitle className='mb-4' style={{ alignSelf: 'start' }}>
                            Preview
                          </CardTitle>
                          <CertificateContainer
                            canvasId='canvasTemplate'
                            templateBackground={displayTemplate.backgroundUrl || temporaryThumbnail}
                            certificateTemplate={displayTemplate}
                            displayCertificate={null}
                            editMode={false}
                            buttonOptions={{
                              copyButton: false,
                              viewButton: false,
                              downloadButton: false,
                            }}
                          />
                        </Col>

                        <Col>
                          <CardTitle className='mb-4'>Template list</CardTitle>
                          <Row>
                            {templateList.map((template: CertificateTemplate) => {
                              return (
                                <TemplateCard
                                  key={template._id}
                                  template={template}
                                  displayTemplate={displayTemplate}
                                  onClick={setDisplayTemplate}
                                />
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                      <div className='text-end mt-4'>
                        <Button
                          type='button'
                          color='primary'
                          outline
                          className='btn mt-2 me-1'
                          onClick={() => setStep(1)}
                          disabled={loading}
                        >
                          Back
                        </Button>
                        <Button
                          type='button'
                          color='primary'
                          className='ms-1 btn mt-2'
                          disabled={loading}
                          onClick={() => setStep(3)}
                        >
                          Next
                        </Button>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <CardTitle className='mb-4'>Recipients</CardTitle>
                      <div className='text-end'>
                        <Button
                          type='button'
                          color='primary'
                          disabled={loading}
                          onClick={() => {
                            setCreateRecipientModal(true);
                          }}
                        >
                          Add recipient
                        </Button>
                      </div>
                      <Table className='project-list-table table-nowrap align-middle table-borderless'>
                        <thead>
                          <tr>
                            <th scope='col' style={{ width: '100px' }}>
                              #
                            </th>
                            <th scope='col'>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {certificates
                            .slice(limit * (currentPage - 1), limit * currentPage - 1)
                            .map((certificate) => (
                              <tr key={certificate.recipientEmail}>
                                <td>
                                  <div className='text-truncate font-size-14'>
                                    {certificates.indexOf(certificate) + 1}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-truncate font-size-14'>
                                    {certificate.recipientEmail}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Pagination
                        count={certificateCount}
                        pageSize={limit}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                      <div className='text-end mt-4'>
                        <Button
                          type='button'
                          color='primary'
                          outline
                          className='btn mt-2 me-1'
                          onClick={() => setStep(2)}
                          disabled={loading}
                        >
                          Back
                        </Button>
                        <Button
                          type='button'
                          color='primary'
                          className='ms-1 btn mt-2'
                          disabled={loading}
                          onClick={() => {
                            createCertificateBatch();
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    </TabPane>
                    {/* <TabPane tabId={3}>
                      <CardTitle className='mb-4'>Certificate design</CardTitle>
                      
                      <CertificateEditspace
                        certificate={certificate}
                        user={memberList[0] || null}
                        placeholderList={placeholderList}
                        templateSelect={false}
                        setPlaceholderList={setPlaceholderList}
                      /> 

                      <div className='text-end mt-4'>
                        <Button
                          type='button'
                          color='primary'
                          outline
                          className='btn mt-2 me-1'
                          onClick={() => setStep(2)}
                          disabled={loading}
                        >
                          Back
                        </Button>
                        <Button
                          type='button'
                          color='primary'
                          className='ms-1 btn mt-2'
                          disabled={loading}
                          onClick={() => {
                            createCertificateBatch([]);
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCertificate;
