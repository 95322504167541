import classNames from 'classnames';
import { Formik, useFormik } from 'formik';
import _ from 'lodash';
import mime from 'mime';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import { SupportSubjectInformation } from 'common/supportSubject';
import systemUser from 'common/systemUser';
import formatBytes from 'helpers/format-bytes';
import { handleAxiosError } from 'helpers/handleError';
import timeSince from 'helpers/time-since';
import ContactSystemService from 'services/contact-system.service';
import {
  Attachment,
  Message,
  SupportTicket,
  SupportTicketStatus,
  SupportTicketSubject,
} from 'types';

type AttachedFileProps = {
  id: string | undefined;
  attachment: Attachment;
};

const AttachedFile = ({ id, attachment }: AttachedFileProps) => {
  const download = () => {
    if (attachment._id && id)
      ContactSystemService.downloadAttachment(id, attachment._id)
        .then((res) => {
          const { data } = res;

          const blob: Blob = new Blob([data]);
          const url: string = URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement('a');
          document.body.appendChild(link);

          link.href = url;
          link.download = `${attachment.filename}.${mime.getExtension(attachment.mimetype) || ''}`;
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          handleAxiosError(error, (message) => toast.error(message));
        });
  };

  return (
    <Card className='shadow-none border'>
      <CardBody className='p-3' style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className='me-3'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <i
            className='mdi mdi-image font-size-24 align-middle text-muted'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          />
        </div>
        <div className='overflow-hidden me-auto' style={{ display: 'flex', alignItems: 'center' }}>
          <div className='font-size-16 text-truncate '>
            {attachment.filename}.{mime.getExtension(attachment.mimetype)}
          </div>
        </div>
        <div className=' overflow-hidden me-auto' style={{ display: 'flex', alignItems: 'center' }}>
          <div className='text-muted text-truncate'>{formatBytes(attachment.size)}</div>
        </div>
        <div>
          <Button
            type='button'
            color='primary'
            className='btn ml-2'
            onClick={() => {
              if (attachment._id) download();
            }}
          >
            Download
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

type MessageBoxProps = {
  id: string | undefined;
  message: Message;
  status?: 'REJECTED' | 'FINISHED';
};

const MessageBox = ({ id, message, status }: MessageBoxProps) => {
  return (
    <Card
      className={classNames(
        'shadow-none border',
        status === 'REJECTED' && 'border-danger',
        status === 'FINISHED' && 'border-success',
        !status && 'border-light'
      )}
    >
      <CardBody>
        <div className='d-flex mb-4'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className='text-muted'>by {message.createdBy.name}</div>
            <div>
              {Date.now() - message.createdAt >= 846000000
                ? new Date(message.createdAt).toLocaleString('vi-VN')
                : timeSince(message.createdAt)}
            </div>
          </div>
        </div>

        <div style={{ whiteSpace: 'pre-line' }}>{message.content}</div>
        <hr />
        {message.attachments.map((file) => {
          return <AttachedFile key={file.filename + file.mimetype} attachment={file} id={id} />;
        })}
      </CardBody>
    </Card>
  );
};

type SupportTicketTabProps = {
  id: string | undefined;
  status: SupportTicketStatus;
  ticket: SupportTicket;
  refetch: () => Promise<void>;
};

type PreviewFile = File & {
  preview: string;
};

const initialMessage = {
  message: '',
};

const SupportTicketTab = ({ id, ticket, status, refetch }: SupportTicketTabProps) => {
  const [toggleReply, setToggleReply] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<PreviewFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [openResolveModal, setOpenResolveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);

  const sendMessage = async (content: string) => {
    try {
      setLoading(true);
      if (id) await ContactSystemService.addMessage(id, content, selectedFiles);

      toast.success('Message sent');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setToggleReply(false);
      setSelectedFiles([]);
      await refetch();
      setLoading(false);
    }
  };

  const approve = async (subject: SupportTicketSubject) => {
    try {
      setLoading(true);
      if (id) await ContactSystemService.approveTicket(id, subject);

      toast.success('Message sent');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      await refetch();
      setLoading(false);
    }
  };

  const reject = async (reason: string) => {
    try {
      setLoading(true);
      if (id) await ContactSystemService.rejectTicket(id, reason);

      toast.success('Message sent');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      await refetch();
      setLoading(false);
    }
  };

  const handleAcceptedFiles = (files: File[]) => {
    const newFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setSelectedFiles(selectedFiles.concat(newFiles));
  };

  const validation = useFormik({
    initialValues: initialMessage,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      message: Yup.string().required('Please enter your reply'),
    }),
    onSubmit: (values, { resetForm }) => {
      sendMessage(values.message);
      resetForm();
    },
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={openResolveModal}
        toggle={() => setOpenResolveModal(false)}
        centered={true}
        size='large'
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Resolve support ticket</h5>
          <button
            type='button'
            onClick={() => setOpenResolveModal(false)}
            className='btn-close position-absolute end-0 top-0 m-3'
          />
        </div>
        <div className='modal-content '>
          <ModalBody className='px-4 py-4 text-center '>
            <div className='text-start mb-4'>
              After approving, you can start a conversation with this user, while rejecting will
              close this support ticket and it can no longer receive reply.
            </div>
            <div className='text-end'>
              <Button
                type='button'
                color='primary'
                outline
                className='btn mt-2 me-1'
                disabled={loading}
                onClick={() => setOpenResolveModal(false)}
              >
                Cancel
              </Button>
              <Button
                type='button'
                color='danger'
                className='ms-1 btn mt-2'
                disabled={loading}
                onClick={() => {
                  setOpenRejectModal(true);
                  setOpenResolveModal(false);
                }}
              >
                Reject
              </Button>
              <Button
                type='button'
                color='success'
                className='ms-1 btn mt-2'
                disabled={loading}
                onClick={() => {
                  setOpenApproveModal(true);
                  setOpenResolveModal(false);
                }}
              >
                Approve
              </Button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={openRejectModal}
        toggle={() => setOpenRejectModal(false)}
        centered={true}
        size='large'
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Reject support ticket</h5>
          <button
            type='button'
            onClick={() => setOpenRejectModal(false)}
            className='btn-close position-absolute end-0 top-0 m-3'
          />
        </div>
        <div className='modal-content '>
          <ModalBody className='px-4 py-4 text-center '>
            <div className='text-start mb-4'>Please give a detailed reason for this rejection</div>
            <Formik
              initialValues={{ reason: '' }}
              validationSchema={Yup.object({
                reason: Yup.string().required('Rejection reason is required.'),
              })}
              onSubmit={({ reason }, { resetForm }) => {
                reject(reason);
                setOpenRejectModal(false);
                resetForm();
              }}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className='input-group text-start'>
                      <Input
                        type='textarea'
                        id='reason'
                        name='reason'
                        className='form-control'
                        value={values.reason}
                        invalid={!!errors.reason && !!touched.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows='3'
                        placeholder='Enter your reason for rejection.'
                      />
                      {touched.reason && errors.reason ? (
                        <FormFeedback type='invalid'>{errors.reason}</FormFeedback>
                      ) : null}
                    </div>
                    <div className='text-end'>
                      <Button
                        type='button'
                        color='primary'
                        outline
                        className='btn mt-2 me-1'
                        disabled={loading}
                        onClick={() => setOpenRejectModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='danger'
                        className='ms-1 btn mt-2'
                        disabled={loading}
                      >
                        Reject
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={openApproveModal}
        toggle={() => setOpenApproveModal(false)}
        centered={true}
        size='large'
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Approve support ticket</h5>
          <button
            type='button'
            onClick={() => setOpenApproveModal(false)}
            className='btn-close position-absolute end-0 top-0 m-3'
          />
        </div>
        <div className='modal-content '>
          <ModalBody className='px-4 py-4 text-center '>
            <div className='text-start mb-4'>Please choose the appropriate subject!</div>
            <Formik
              initialValues={{ subject: undefined }}
              validationSchema={Yup.object({
                subject: Yup.mixed<SupportTicketSubject>()
                  .oneOf(Object.values(SupportTicketSubject))
                  .required('Subject are required'),
              })}
              onSubmit={({ subject }, { resetForm }) => {
                if (subject) {
                  approve(subject);
                  setOpenApproveModal(false);
                  resetForm();
                }
              }}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <Input
                      name='subject'
                      type='select'
                      className='form-select'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      invalid={touched.subject && errors.subject ? true : false}
                    >
                      <option selected>No subject chosen.</option>
                      {_.keys(SupportTicketSubject).map((key) => (
                        <option key={key} value={_.toString(SupportTicketSubject[key])}>
                          {SupportSubjectInformation[_.toString(SupportTicketSubject[key])].title}
                        </option>
                      ))}
                    </Input>
                    {!!touched.subject && !!errors.subject ? (
                      <FormFeedback className='text-start' type='invalid'>
                        {errors.subject}
                      </FormFeedback>
                    ) : null}
                    <div className='text-end'>
                      <Button
                        type='button'
                        color='primary'
                        outline
                        className='btn mt-2 me-1'
                        disabled={loading}
                        onClick={() => setOpenApproveModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='success'
                        className='ms-1 btn mt-2'
                        disabled={loading}
                      >
                        Approve
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>

      {!toggleReply && status === SupportTicketStatus.PROCESSING && (
        <div className='text-end'>
          <Button
            type='button'
            color='primary'
            className=' ms-1 btn'
            onClick={() => setToggleReply(true)}
          >
            Reply
          </Button>
        </div>
      )}
      {status === SupportTicketStatus.PENDING && (
        <div className='text-end'>
          <Button
            type='button'
            color='primary'
            className=' ms-1 btn mt-2'
            onClick={() => setOpenResolveModal(true)}
          >
            Resolve
          </Button>
        </div>
      )}
      {(status === SupportTicketStatus.FINISHED || status === SupportTicketStatus.REJECTED) && (
        <div className='text-end'>You can no longer reply this support ticket</div>
      )}

      {toggleReply && (
        <Card>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              autoComplete='off'
            >
              <div className='mt-4'>
                <h5 className='font-size-16 mb-3'>Reply</h5>

                <FormGroup className='mb-4' row>
                  <div className='mb-3'>
                    <Label htmlFor='message'>Message</Label>
                    <Input
                      tag='textarea'
                      id='message'
                      rows={5}
                      className='form-control'
                      name='message'
                      placeholder='Your message...'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.message || ''}
                      invalid={
                        validation.touched.message && validation.errors.message ? true : false
                      }
                    />
                    {validation.touched.message && validation.errors.message ? (
                      <FormFeedback type='invalid'>{validation.errors.message}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>

                <Label htmlFor='attachments'>Attachment</Label>
                <div>
                  <Dropzone
                    onDrop={(acceptedFiles: File[]) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div className='dz-message needsclick' {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className='dz-message needsclick'>
                            <div className='mb-3'>
                              <i className='display-6 text-muted bx bxs-cloud-upload' />
                            </div>
                            <div style={{ fontSize: '16px' }}>
                              Drag and drop a file here or click to upload
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <ul className='list-unstyled mb-0' id='file-previews'>
                    {selectedFiles.map((file: PreviewFile) => {
                      return (
                        <li
                          className='mt-2 dz-image-preview'
                          key={file.name + file.type + selectedFiles.indexOf(file).toString()}
                        >
                          <div className='border rounded'>
                            <div className='d-flex flex-wrap gap-2 p-2'>
                              <div className='flex-shrink-0 me-3'>
                                <div className='avatar-sm bg-light rounded p-2'>
                                  <img
                                    data-dz-thumbnail=''
                                    className='img-fluid rounded d-block'
                                    src={file.preview}
                                    alt={file.name}
                                  />
                                </div>
                              </div>
                              <div className='flex-grow-1'>
                                <div className='pt-1'>
                                  <h5 className='fs-md mb-1' data-dz-name>
                                    {file.webkitRelativePath}
                                  </h5>
                                  <strong
                                    className='error text-danger'
                                    data-dz-errormessage
                                  ></strong>
                                </div>
                              </div>
                              <div className='flex-shrink-0 ms-3'>
                                <Button
                                  variant='danger'
                                  size='sm'
                                  onClick={() => {
                                    const newFiles = [...selectedFiles];
                                    newFiles.splice(selectedFiles.indexOf(file), 1);
                                    setSelectedFiles(newFiles);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className='text-end mt-4'>
                  <Button
                    type='button'
                    color='primary'
                    outline
                    className='btn mt-2 me-1'
                    onClick={() => setToggleReply(false)}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    color='primary'
                    className='ms-1 btn mt-2'
                    disabled={loading}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      )}
      <Row className='mt-4'>
        {ticket.status === SupportTicketStatus.REJECTED && ticket.reason && (
          <MessageBox
            message={{
              _id: 'rejected_message',
              content: ticket.reason,
              attachments: [],
              createdAt: ticket.updatedAt,
              createdBy: systemUser,
            }}
            id={id}
            status='REJECTED'
          />
        )}
        {ticket.status === SupportTicketStatus.FINISHED && ticket.reason && (
          <MessageBox
            message={{
              _id: 'rejected_message',
              content: ticket.reason,
              attachments: [],
              createdAt: ticket.updatedAt,
              createdBy: systemUser,
            }}
            id={id}
            status='FINISHED'
          />
        )}
        {ticket.messages
          .slice()
          .reverse()
          .map((message: Message) => {
            return <MessageBox key={message._id} message={message} id={id} />;
          })}
      </Row>
    </React.Fragment>
  );
};

export default SupportTicketTab;
