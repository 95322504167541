import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import DeleteModal from 'Components/Common/DeleteModal';
import Loading from 'Components/Common/LoadingIndicator';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import StorageService from 'services/storage.service';
import { Attachment, Directory } from 'types';

import AttachmentList from './AttachmentList';
import DirectoryList from './DirectoryList';
import FileLeftBar from './FileLeftBar';

type PreviewFile = File & {
  preview: string;
};

const Storage = () => {
  useTitle('Storage Management', {
    restoreOnUnmount: true,
  });

  const [directory, setDirectory] = useState<Directory>();
  const [attachmentList, setAttachmentList] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [deleteDirectoryId, setDeleteDirectoryId] = useState<string | undefined>();

  const [renameDirectoryId, setRenameDirectoryId] = useState<string | undefined>();
  const [newNameDirectory, setNewNameDirectory] = useState<string | undefined>();

  const [newDirectoryModal, setNewDirectoryModal] = useState<boolean>(false);
  const [newDirectoryName, setNewDirectoryName] = useState<string>('');

  const [deleteAttachmentId, setDeleteAttachmentId] = useState<string | undefined>();

  const [renameAttachmentId, setRenameAttachmentId] = useState<string | undefined>();
  const [newNameAttachment, setNewNameAttachment] = useState<string | undefined>();

  const [selectedFiles, setselectedFiles] = useState<PreviewFile[]>([]);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const directoryId: string | null = searchParams.get('folder');

  useEffect(() => {
    const fetchDirectory = async () => {
      setLoading(true);
      try {
        const { data } = await StorageService.getDirectoryInfo(directoryId || '');
        const { payload } = data;
        setDirectory(payload);

        const { data: attachmentData } = await StorageService.getAttachmentList(directoryId || '');
        const { payload: attachmentPayload } = attachmentData;
        setAttachmentList(attachmentPayload);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        setLoading(false);
      }
    };

    fetchDirectory();
  }, [directoryId]);

  if (!directory || loading) {
    return (
      <div className='mb-4'>
        <Loading width={600} height={600} />
      </div>
    );
  }

  const onRenameDirectory = async () => {
    try {
      const { data } = await StorageService.renameDirectory(
        renameDirectoryId || '',
        newNameDirectory || ''
      );
      const { payload } = data;
      setDirectory(payload.updatedParentDirectory);
      toast.success('Rename successfully!');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setRenameDirectoryId(undefined);
      setNewNameDirectory(undefined);
    }
  };

  const onNewDirectory = async () => {
    try {
      if (!directory || !directory._id)
        throw new Error('Cannot create directory without parent directory id');
      const { data } = await StorageService.createDirectory(directory?._id, newDirectoryName);
      const { payload } = data;
      setDirectory(payload.updatedParentDirectory);
      toast.success('Create folder successfully!');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setNewDirectoryModal(false);
      setNewDirectoryName('');
    }
  };

  const onDeleteDirectory = () => {
    StorageService.deleteDirectory(deleteDirectoryId || '')
      .then(({ data }) => {
        const { payload } = data;
        setDirectory(payload.updatedParentDirectory);
        toast.success('Delete folder successfully!');
      })
      .catch((error) => {
        handleAxiosError(error, (message) => toast.error(message));
      })
      .finally(() => {
        setDeleteDirectoryId(undefined);
      });
  };

  const onRenameAttachment = () => {
    StorageService.renameAttachment(renameAttachmentId || '', newNameAttachment || '')
      .then(({ data }) => {
        const { payload } = data;
        const newAttachmentList = attachmentList?.map((attachment) => {
          if (attachment._id === payload._id) return payload;
          return attachment;
        });
        setAttachmentList(newAttachmentList);
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      })
      .finally(() => {
        setRenameAttachmentId(undefined);
        setNewNameAttachment(undefined);
      });
  };

  const onDeleteAttachment = () => {
    StorageService.deleteAttachment(deleteAttachmentId || '')
      .then(({ data }) => {
        const newAttachmentList = attachmentList?.filter(
          (attachment) => attachment._id !== data.payload._id
        );
        setAttachmentList(newAttachmentList);
        toast.success('Delete attachment successfully!');
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      })
      .finally(() => {
        setDeleteAttachmentId(undefined);
      });
  };

  const handleAcceptedFiles = (files: File[]) => {
    if (files.length > 1) {
      toast.error('Only one file is allowed!');
      return;
    }
    const newFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles(newFiles);
  };

  const onDownloadAttachment = (id: string, downloadName: string) => {
    StorageService.downloadAttachment(id)
      .then((res) => {
        const { data } = res;

        const blob: Blob = new Blob([data]);
        const url: string = URL.createObjectURL(blob);
        const link: HTMLAnchorElement = document.createElement('a');
        document.body.appendChild(link);

        link.href = url;
        link.download = downloadName;
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((error) => {
        handleAxiosError(error, (message) => toast.error(message));
      });
  };

  const onUploadAttachment = async () => {
    setIsUploading(true);
    try {
      const { data } = await StorageService.uploadAttachment(directoryId || '', selectedFiles[0]);
      const { payload } = data;
      const newAttachmentList = [...(attachmentList || []), payload];
      setAttachmentList(newAttachmentList);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setUploadModal(false);
      setIsUploading(false);
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteDirectoryId !== undefined}
        title='Delete Folder'
        content='Are you sure you want to delete this folder?'
        onDeleteClick={onDeleteDirectory}
        onCloseClick={() => setDeleteDirectoryId(undefined)}
      />

      <DeleteModal
        show={deleteAttachmentId !== undefined}
        title='Delete File'
        content='Are you sure you want to delete this file?'
        onDeleteClick={onDeleteAttachment}
        onCloseClick={() => setDeleteAttachmentId(undefined)}
      />

      {/* Rename Directory Modal */}
      <Modal
        isOpen={renameDirectoryId !== undefined}
        toggle={() => setRenameDirectoryId(undefined)}
        centered
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Rename</h5>
          <button
            type='button'
            onClick={() => setRenameDirectoryId(undefined)}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <Row>
            <Col xs={12}>
              <div className='mb-3'>
                <Label>Tên mới</Label>
                <Input
                  name='name'
                  type='text'
                  placeholder=' Folder 1'
                  value={newNameDirectory || ''}
                  onChange={(e) => setNewNameDirectory(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => setRenameDirectoryId(undefined)}
          >
            Close
          </button>
          <button
            onClick={() => {
              onRenameDirectory();
              return;
            }}
            type='button'
            className='btn btn-primary'
          >
            Save
          </button>
        </div>
      </Modal>

      {/* New Directory Modal */}
      <Modal isOpen={newDirectoryModal} toggle={() => setNewDirectoryModal(false)} centered>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>New Folder</h5>
          <button
            type='button'
            onClick={() => setNewDirectoryModal(false)}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <Row>
            <Col xs={12}>
              <div className='mb-3'>
                <Label>Folder name</Label>
                <Input
                  name='name'
                  type='text'
                  placeholder=' Folder 1'
                  value={newDirectoryName || ''}
                  onChange={(e) => setNewDirectoryName(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => setNewDirectoryModal(false)}
          >
            Close
          </button>
          <button
            onClick={() => {
              onNewDirectory();
              return;
            }}
            type='button'
            className='btn btn-primary'
          >
            Save
          </button>
        </div>
      </Modal>

      {/* Rename Attachment Modal */}
      <Modal
        isOpen={renameAttachmentId !== undefined}
        toggle={() => setRenameAttachmentId(undefined)}
        centered
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Rename</h5>
          <button
            type='button'
            onClick={() => setRenameAttachmentId(undefined)}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <Row>
            <Col xs={12}>
              <div className='mb-3'>
                <Label>Tên mới</Label>
                <Input
                  name='name'
                  type='text'
                  placeholder=' File 1'
                  value={newNameAttachment || ''}
                  onChange={(e) => setNewNameAttachment(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => setRenameAttachmentId(undefined)}
          >
            Close
          </button>
          <button
            onClick={() => {
              onRenameAttachment();
              return;
            }}
            type='button'
            className='btn btn-primary'
          >
            Save
          </button>
        </div>
      </Modal>

      {/* Upload Modal */}
      <Modal
        size='xl'
        isOpen={uploadModal}
        toggle={() => setUploadModal(false)}
        onClosed={() => setselectedFiles([])}
        centered
      >
        <div className='modal-content'>
          <ModalBody className='px-4 py-5 text-center'>
            <Dropzone
              onDrop={(acceptedFiles: File[]) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className='dropzone'>
                  <div className='dz-message needsclick' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className='dz-message needsclick'>
                      <div className='mb-3'>
                        <i className='display-4 text-muted bx bxs-cloud-upload' />
                      </div>
                      <h4>Click or drag the file here!</h4>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
            <ul className='list-unstyled mb-0' id='file-previews'>
              {selectedFiles.map((file: PreviewFile) => {
                return (
                  <li className='mt-2 dz-image-preview' key=''>
                    <div className='border rounded'>
                      <div className='d-flex flex-wrap gap-2 p-2'>
                        <div className='flex-shrink-0 me-3'>
                          <div className='avatar-sm bg-light rounded p-2'>
                            <img
                              data-dz-thumbnail=''
                              className='img-fluid rounded d-block'
                              src={file.preview}
                              alt={file.name}
                            />
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <div className='pt-1'>
                            <h5 className='fs-md mb-1' data-dz-name>
                              {file.webkitRelativePath}
                            </h5>
                            <strong className='error text-danger' data-dz-errormessage></strong>
                          </div>
                        </div>
                        <div className='flex-shrink-0 ms-3'>
                          <Button
                            variant='danger'
                            size='sm'
                            onClick={() => {
                              // Support for multiple files
                              // const newFiles = [...selectedFiles];
                              // newFiles.splice(index, 1);
                              // setselectedFiles(newFiles);

                              setselectedFiles([]);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className='text-center mt-4'>
              <button
                disabled={selectedFiles.length <= 0 || isUploading}
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  onUploadAttachment();
                  return;
                }}
              >
                Upload
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Storage' />

          <div className='d-xl-flex'>
            <div className='w-100'>
              <div className='row'>
                <FileLeftBar
                  directory={directory}
                  onNewDirectoryClick={() => setNewDirectoryModal(true)}
                  onNewAttachmentClick={() => setUploadModal(true)}
                />
                <div className='col-md-9'>
                  <Card>
                    <CardBody>
                      <DirectoryList
                        directory={directory}
                        onDeleteClick={(id: string) => setDeleteDirectoryId(id)}
                        onRenameClick={(id: string, currentName: string) => {
                          setRenameDirectoryId(id);
                          setNewNameDirectory(currentName);
                        }}
                      />
                      <AttachmentList
                        attachmentList={attachmentList}
                        onDeleteClick={(id: string) => setDeleteAttachmentId(id)}
                        onRenameClick={(id: string, currentName: string) => {
                          console.log(id);
                          setRenameAttachmentId(id);
                          setNewNameAttachment(currentName);
                        }}
                        onDownloadClick={onDownloadAttachment}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Storage;
