import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  // Dropdown,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  Row,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import { intersectObserver } from 'helpers/intersectObserver';
import useTitle from 'hooks/useTitle';
import CertificateService from 'services/certificate.service';
import { Certificate } from 'types';

import tempThumbnail from '../../../assets/images/tempThumbnail.png';

type CertificateCardProps = {
  certificate: Certificate;
};

const limit = 20;

// const sampleCertis: Array<Certificate> = [
//   {
//     _id: '1',
//     name: 'sample1',
//     description: 'des1',
//     createdBy: {
//       name: '',
//       googleId: '',
//       picture: '',
//       email: '',
//       isManager: false,
//     },
//     templateCode: 0,
//     alertCount: 0,
//     createdAt: 0,
//     updatedAt: 0,
//   },
//   {
//     _id: '2',
//     name: 'sample2',
//     description: 'des1',
//     createdBy: {
//       name: '',
//       googleId: '',
//       picture: '',
//       email: '',
//       isManager: false,
//     },
//     templateCode: 0,
//     alertCount: 0,
//     createdAt: 0,
//     updatedAt: 0,
//   },
// ];

const CertificateCard = ({ certificate }: CertificateCardProps) => {
  const navigate = useNavigate();

  return (
    <Col
      xs={6}
      sm={4}
      md={4}
      lg={5}
      xl={4}
      xxl={3}
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <div
        className='hover-pointer hiddenAnim cert-item'
        onClick={() => navigate('/user/certificate/' + certificate._id)}
      >
        <div className='cert-item-imgCtn'>
          <img
            alt={certificate.certificateId}
            className='cert-item-img'
            src={certificate.thumbnail || tempThumbnail}
          />
        </div>
        <div className='d-flex mt-4'>
          <div className='flex-grow-1 overflow-hidden' style={{ textAlign: 'center' }}>
            {certificate.certificateId}
          </div>
        </div>
      </div>
    </Col>
  );
};

const UserCertificateList = () => {
  useTitle('Certificate', {
    restoreOnUnmount: true,
  });

  const [certificateList, setCertificateList] = useState<Certificate[]>([]);
  const [certificateCount, setCertificateCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchCertificateList = useCallback(async () => {
    try {
      const { data } = await CertificateService.getCertificateList(
        limit,
        limit * (currentPage - 1)
      );
      const { payload } = data;

      setCertificateList(payload.certificateList);
      setIsLoading(false);
      setCertificateCount(payload.total);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [currentPage]);

  useEffect(() => {
    fetchCertificateList();
  }, [fetchCertificateList]);

  useEffect(() => {
    const hiddenElements = document.querySelectorAll('.hiddenAnim');
    hiddenElements.forEach((element) => intersectObserver.observe(element));
  }, [certificateList]);

  if (isLoading) return <Loading width={600} height={600} />;

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Certificate' />

          <Row>
            <Col>
              <Card style={{ height: '480px', overflow: 'hidden' }}>
                {/* <div className='wave' /> */}
                <div className='wave2' />
                <CardBody className='cert-main'>
                  <div className='cert-textCol' style={{ marginBottom: '24px', marginTop: '24px' }}>
                    <div className='cert-text1 '> You have</div>
                    <div className='cert-text2'> {certificateCount} certificates</div>
                    <div className='cert-text1 ' style={{ marginTop: '8px' }}>
                      {' '}
                      Keep trying your best to achieve more
                    </div>
                    <div className='cert-CTA'>
                      <Button
                        type='button'
                        color='primary'
                        className='btn'
                        href={'https://oisp.hcmut.edu.vn/'}
                        target='blank'
                      >
                        More activities from OISP
                      </Button>
                    </div>
                  </div>
                  <div className='' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: 'bold', alignSelf: 'center', color: '0b2878' }}>
                      Featured certificate
                    </div>
                    {certificateCount > 0 && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}
                      >
                        <div
                          style={{
                            fontWeight: 'bold',
                            alignSelf: 'center',
                            color: '0b2878',
                            fontSize: '24px',
                          }}
                        >
                          {certificateList[0].certificateId}
                        </div>
                        <img
                          className='cert-display hiddenAnim'
                          style={{ marginTop: '24px' }}
                          alt={certificateList[0].certificateId}
                          src={certificateList[0].thumbnail || tempThumbnail}
                        />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Your certificates</CardTitle>
                  <Row style={{ marginBottom: '24px' }}>
                    {certificateList.map((certificate: Certificate) => {
                      return <CertificateCard key={certificate._id} certificate={certificate} />;
                    })}
                  </Row>
                  <Pagination
                    count={certificateCount}
                    pageSize={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserCertificateList;
