import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

import circle from '../../assets/animations/Circle.json';
import AppDownload from '../../assets/images/AppDownload.png';
import buttonAppStore from '../../assets/svg/AppStore.svg';
import buttonGooglePlay from '../../assets/svg/GooglePlay.svg';

const AppVersion = () => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: circle,
    rendererSettings: {
      preserveAspectRatio: 'none',
    },
  };
  return (
    <React.Fragment>
      <div className='tw-w-full tw-mb-[30px] tw-px-[20px] xl:tw-mb-[50px] xl:tw-mt-[-50px] 2xl:tw-mt-0 '>
        <div className='tw-flex tw-flex-col xl:tw-flex-row tw-w-full tw-items-center just tw-justify-between '>
          <div className='tw-mb-[100px] xl:tw-mb-0'>
            <div className='tw-relative'>
              <div className='tw-w-[360px] tw-h-[360px] xs:tw-w-[380px] xs:tw-h-[380px] md:tw-w-[440px] md:tw-h-[440px] lg:tw-w-[500px] lg:tw-h-[500px] xl:tw-w-[450px] xl:tw-h-[450px] 2xl:tw-w-[600px] 2xl:tw-h-[600px] '>
                <Lottie
                  options={defaultOptions1}
                  height='100%'
                  width='100%'
                  style={{
                    position: 'relative',
                    pointerEvents: 'none',
                  }}
                />
              </div>
              <div className='tw-w-full tw-h-full tw-absolute tw-inset-1/2 tw-translate-x-[-50%] tw-translate-y-[-50%]'>
                <img
                  src={AppDownload}
                  alt='App Download'
                  className=' tw-w-[65%] tw-h-[68%] tw-absolute tw-inset-1/2 tw-translate-x-[-50%] tw-translate-y-[-50%]'
                />
              </div>
            </div>
          </div>
          <div className='tw-mb-[10px] lg:tw-mb-[60px] xl:tw-mb-0 tw-items-center tw-justify-center tw-mt-[-80px] xl:tw-mt-0 '>
            <h1 className='tw-text-[28px] md:tw-text-[32px] tw-w-full lg:tw-text-[36px] xl:tw-text-[40px] 2xl:tw-text-[48px] tw-text-center xl:tw-text-right tw-font-extrabold tw-text-[#3D4863] tw-mb-[24px]'>
              App version is now available!
            </h1>
            <p className='tw-text-center xl:tw-text-right tw-text-[14px] 2xl:tw-text-[20px] tw-text-[#3D4863]'>
              Did you miss any notification? Do you want to communicate with OISP faster?
              <br />
              Or are you unable to open your PC at the moment?
              <br />
              The light-weight OISP Union App version is here to solve all of your problems.
              <br />
              <br />
              Now available on mobile devices for both Android and iOS!
            </p>
            <div className='tw-flex-row tw-flex tw-space-x-4 xl:tw-space-x-8 tw-justify-center tw-items-center xl:tw-justify-end xl:tw-items-end'>
              <Link to='https://apps.apple.com/us/app/oisp/id6468775101'>
                <button>
                  <img
                    className='tw-w-[140px] tw-h-[100px] md:tw-w-[160px] md:tw-h-[160px] lg:tw-h-[120px] 2xl:tw-w-[200px] 2xl:tw-h-[200px]'
                    src={buttonAppStore}
                    alt='button App Store'
                  />
                </button>
              </Link>
              <Link to='https://play.google.com/store/apps/details?id=com.fessior.oisp&fbclid=IwY2xjawEYHwlleHRuA2FlbQIxMAABHcSjOS2aK2BCDeRD54V4IpSZaJb4Gqi_2FkvNGTnU1dCP6qjd5-R6a_o4w_aem_ztiF0W_AmfE8BY-DOlRWSw&pli=1'>
                <button>
                  <img
                    className='tw-w-[140px] tw-h-[100px] md:tw-w-[160px] md:tw-h-[160px] lg:tw-h-[120px] 2xl:tw-w-[200px] 2xl:tw-h-[200px]'
                    src={buttonGooglePlay}
                    alt='button Google Play'
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppVersion;
