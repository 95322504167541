import { startCase } from 'lodash';
import { Col, Modal, ModalBody } from 'reactstrap';

import { EventPermissions, EventRole } from 'types';

type RoleModalProps = {
  role: EventRole | null;
  onCloseClick: () => void;
};

const RoleModal = ({ role, onCloseClick }: RoleModalProps) => {
  if (!role)
    return (
      <Modal isOpen={role !== null} toggle={onCloseClick} centered={true} size='md'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Role</h5>
        </div>
        <div className='modal-content'>
          <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-gap-4 '></ModalBody>
        </div>
      </Modal>
    );
  return (
    <Modal isOpen={role !== null} toggle={onCloseClick} centered={true} size='md'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>{role.title}</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content'>
        <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-gap-4 tw-text-[12px] xs:tw-text-[16px]'>
          <div className='tw-relative tw-w-full tw-flex tw-flex-row'>
            <Col xs={3} className='fw-semibold !tw-px-0'>
              Name:
            </Col>
            <Col>
              <p className='text-muted tw-mb-1'>{role.title}</p>
            </Col>
          </div>
          <div className='tw-relative tw-w-full tw-flex tw-flex-row'>
            <Col xs={3} className='fw-semibold !tw-px-0'>
              Description:
            </Col>
            <Col>
              <p className='text-muted tw-mb-1'>{role.description}</p>
            </Col>
          </div>
          <div className='tw-relative tw-w-full tw-flex tw-flex-row'>
            <Col xs={3} className='fw-semibold !tw-px-0'>
              Permissions:
            </Col>
            <Col></Col>
          </div>
          <div className='tw-relative tw-w-full tw-grid tw-grid-cols-1 xs:tw-grid-cols-2 tw-gap-1'>
            {role.permissions.map((permission: EventPermissions) => {
              return (
                <div
                  key={permission}
                  className='tw-relative tw-flex font-size-12 tw-bg-[#3D4863] tw-rounded-lg tw-text-white tw-px-2 tw-py-1 tw-font-bold tw-items-center tw-whitespace-nowrap'
                >
                  {startCase(permission)}
                </div>
              );
            })}
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default RoleModal;
