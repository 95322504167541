import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, User } from 'types';

const AuthService = {
  changeSystemKey: async (oldSystemKey: string, newSystemKey: string) =>
    axios.post<ResponseData<User>>(`${API_URL}admin/system-key`, { oldSystemKey, newSystemKey }),
  loginWithGoogle: async (idToken: string) =>
    axios.post<ResponseData<string>>(`${API_URL}auth/login`, { idToken }),
  login: async (username: string, password: string) =>
    axios.post<ResponseData<string>>(`${API_URL}auth/login/v2`, { username, password }),
  signup: async (idToken: string, password: string, confirmPassword: string) =>
    axios.post<ResponseData<string>>(`${API_URL}auth/signup`, {
      idToken,
      password,
      confirmPassword,
    }),
  forgotPassword: async (email: string) =>
    axios.post<ResponseData<string>>(`${API_URL}auth/forgot-password`, { email }),
  resetPassword: async (ticket: string, newPassword: string, confirmPassword: string) =>
    axios.post<ResponseData<User>>(`${API_URL}auth/reset-password`, {
      ticket,
      newPassword,
      confirmPassword,
    }),
  validateResetPasswordTicket: async (token: string) =>
    axios.get<ResponseData<boolean>>(
      `${API_URL}auth/reset-password-ticket/validate?ticket=${token}`
    ),
};

export default AuthService;
