import React, { useEffect } from 'react';

import sidebarToggle from 'helpers/sidebar-toggle';

import SidebarContent from './SidebarContent';

type SidebarProps = {
  type?: string;
};

const Sidebar = ({ type }: SidebarProps) => {
  const handleOutSideClick = () => {
    const sidebar = document.getElementById('sidebarMenu');
    const sidebarButton = document.getElementById('vertical-menu-btn');
    document.onclick = (event) => {
      if (
        sidebar &&
        sidebarButton &&
        !sidebar.contains(event.target as Node) &&
        !sidebarButton.contains(event.target as Node) &&
        sidebar.classList.contains('tw-translate-x-[0%]')
      ) {
        sidebarToggle();
      }
    };
  };

  useEffect(() => {
    handleOutSideClick();
  });

  return (
    <React.Fragment>
      <div
        id='sidebarMenu'
        className='tw-w-[15rem] tw-h-screen tw-z-[100] tw-bg-white tw-fixed tw-top-0 tw-pt-[3.5rem] tw-duration-[200ms] tw-ease-in-out tw-translate-x-[-100%]'
      >
        <div data-simplebar className='h-100'>
          {type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
      <div
        id='blurScreen'
        className='tw-h-screen tw-w-screen tw-bg-[#00000050] tw-fixed tw-z-[99] tw-hidden'
      />
    </React.Fragment>
  );
};

export default Sidebar;
