import { useFormik } from 'formik';
import mime from 'mime';
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import formatBytes from 'helpers/format-bytes';
import { handleAxiosError } from 'helpers/handleError';
import timeSince from 'helpers/time-since';
import useTitle from 'hooks/useTitle';
import UnitTicketService from 'services/unit-ticket.service';
import { Attachment, Message, SupportTicketStatus, SupportTicketSubject, UnitTicket } from 'types';

type AttachedFileProps = {
  id: string | undefined;
  attachment: Attachment;
};

const AttachedFile = ({ id, attachment }: AttachedFileProps) => {
  const download = () => {
    if (attachment._id && id)
      UnitTicketService.downloadAttachment(id, attachment._id)
        .then((res) => {
          const { data } = res;

          const blob: Blob = new Blob([data]);
          const url: string = URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement('a');
          document.body.appendChild(link);

          link.href = url;
          link.download = `${attachment.filename}.${mime.getExtension(attachment.mimetype) || ''}`;
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          handleAxiosError(error, (message) => toast.error(message));
        });
  };

  return (
    <Card className='shadow-none border'>
      <CardBody className='p-3' style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className='me-3'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <i
            className='mdi mdi-image font-size-24 align-middle text-muted'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          />
        </div>
        <div className='overflow-hidden me-auto' style={{ display: 'flex', alignItems: 'center' }}>
          <div className='font-size-16 text-truncate '>
            {attachment.filename}.{mime.getExtension(attachment.mimetype)}
          </div>
        </div>
        <div className=' overflow-hidden me-auto' style={{ display: 'flex', alignItems: 'center' }}>
          <div className='text-muted text-truncate'>{formatBytes(attachment.size)}</div>
        </div>
        <div>
          <Button
            type='button'
            color='primary'
            className='btn ml-2'
            onClick={() => {
              if (attachment._id) download();
            }}
          >
            Download
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

type MessageBoxProps = {
  id: string | undefined;
  message: Message;
};

const MessageBox = ({ id, message }: MessageBoxProps) => {
  const handleUser = () => {
    if (message.createdBy) return message.createdBy.name;
    return 'Unknown';
  };

  return (
    <Card className='shadow-none border border-light'>
      <CardBody>
        <div className='d-flex mb-4'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className='text-muted'>by {handleUser()}</div>
            <div>
              {Date.now() - message.createdAt >= 846000000
                ? new Date(message.createdAt).toLocaleString('vi-VN')
                : timeSince(message.createdAt)}
            </div>
          </div>
        </div>

        <div style={{ whiteSpace: 'pre-line' }}>{message.content}</div>
        <hr />
        {message.attachments.map((file) => {
          return <AttachedFile key={file.filename + file.mimetype} attachment={file} id={id} />;
        })}
      </CardBody>
    </Card>
  );
};

type PreviewFile = File & {
  preview: string;
};

const initialMessage = {
  message: '',
};

const initialValue = {
  _id: '',

  supportTicketId: {
    _id: '',
    title: '',
    subject: SupportTicketSubject.COURSE_REGISTRATION,
    status: SupportTicketStatus.UNVERIFIED,
    messages: [],
    createdAt: 0,
    createdBy: {
      name: '',
      googleId: '',
      picture: '',
      email: '',
      permissions: [],
      isManager: false,
      units: [],
    },
    updatedAt: 0,
  },

  unitId: {
    _id: '',
    name: '',
  },

  title: '',
  messages: [],

  createdAt: 0,
  createdBy: {
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
    units: [],
  },
  updatedAt: 0,
};

const DetailUnitTicket = () => {
  useTitle('Unit ticket detail', {
    restoreOnUnmount: true,
  });

  const { id } = useParams();
  const [unitTicket, setUnitTicket] = useState<UnitTicket>(initialValue);
  const [toggleReply, setToggleReply] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<PreviewFile[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchUnitTicket = useCallback(async () => {
    try {
      const { data } = await UnitTicketService.getUnitTicketById(id || '');
      const { payload } = data;
      setUnitTicket(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  const sendMessage = async (content: string) => {
    try {
      setLoading(true);
      await UnitTicketService.addMessage(id || '', content, selectedFiles);

      toast.success('Message sent');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setToggleReply(false);
      setSelectedFiles([]);
      await fetchUnitTicket();
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUnitTicket();
  }, [fetchUnitTicket]);

  const handleAcceptedFiles = (files: File[]) => {
    const newFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setSelectedFiles(selectedFiles.concat(newFiles));
  };

  const validation = useFormik({
    initialValues: initialMessage,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      message: Yup.string().required('Please enter your reply'),
    }),
    onSubmit: (values, { resetForm }) => {
      sendMessage(values.message);
      resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='OContact' breadcrumbItem='Contact system' backTo='/contact-system' />
        </Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className='card-title mb-2'>{unitTicket.title}</CardTitle>
                <Row className='mb-4'>
                  <Row className='mb-4'>
                    <div className='mb-2'>to {unitTicket.unitId.name}</div>
                  </Row>
                  <Row>
                    <h4 className='mt-0 font-size-14'>Messages:</h4>
                  </Row>
                  {!toggleReply &&
                    unitTicket.supportTicketId.status === SupportTicketStatus.PROCESSING && (
                      <div className='text-end'>
                        <Button
                          type='button'
                          color='primary'
                          className=' ms-1 btn'
                          onClick={() => setToggleReply(true)}
                        >
                          Reply
                        </Button>
                      </div>
                    )}
                  {unitTicket.supportTicketId.status !== SupportTicketStatus.PROCESSING && (
                    <div className='text-end'>You can no longer reply this Unit ticket</div>
                  )}

                  {toggleReply && (
                    <Card>
                      <CardBody className='shadow-none border border-light'>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                          autoComplete='off'
                        >
                          <div className='mt-4'>
                            <h5 className='font-size-16 mb-3'>Reply</h5>

                            <FormGroup className='mb-4' row>
                              <div className='mb-3'>
                                <Label htmlFor='message'>Message</Label>
                                <Input
                                  tag='textarea'
                                  id='message'
                                  rows={5}
                                  className='form-control'
                                  name='message'
                                  placeholder='Your message...'
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.message || ''}
                                  invalid={
                                    validation.touched.message && validation.errors.message
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.message && validation.errors.message ? (
                                  <FormFeedback type='invalid'>
                                    {validation.errors.message}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </FormGroup>

                            <Label htmlFor='attachments'>Attachment</Label>
                            <div>
                              <Dropzone
                                onDrop={(acceptedFiles: File[]) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className='dropzone'>
                                    <div className='dz-message needsclick' {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <div className='dz-message needsclick'>
                                        <div className='mb-3'>
                                          <i className='display-6 text-muted bx bxs-cloud-upload' />
                                        </div>
                                        <div style={{ fontSize: '16px' }}>
                                          Drag and drop a file here or click to upload
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <ul className='list-unstyled mb-0' id='file-previews'>
                                {selectedFiles.map((file: PreviewFile) => {
                                  return (
                                    <li
                                      className='mt-2 dz-image-preview'
                                      key={
                                        file.name +
                                        file.type +
                                        selectedFiles.indexOf(file).toString()
                                      }
                                    >
                                      <div className='border rounded'>
                                        <div className='d-flex flex-wrap gap-2 p-2'>
                                          <div className='flex-shrink-0 me-3'>
                                            <div className='avatar-sm bg-light rounded p-2'>
                                              <img
                                                data-dz-thumbnail=''
                                                className='img-fluid rounded d-block'
                                                src={file.preview}
                                                alt={file.name}
                                              />
                                            </div>
                                          </div>
                                          <div className='flex-grow-1'>
                                            <div className='pt-1'>
                                              <h5 className='fs-md mb-1' data-dz-name>
                                                {file.webkitRelativePath}
                                              </h5>
                                              <strong
                                                className='error text-danger'
                                                data-dz-errormessage
                                              ></strong>
                                            </div>
                                          </div>
                                          <div className='flex-shrink-0 ms-3'>
                                            <Button
                                              variant='danger'
                                              size='sm'
                                              onClick={() => {
                                                const newFiles = [...selectedFiles];
                                                newFiles.splice(selectedFiles.indexOf(file), 1);
                                                setSelectedFiles(newFiles);
                                              }}
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>

                            <div className='text-end mt-4'>
                              <Button
                                type='button'
                                color='primary'
                                outline
                                className='btn mt-2 me-1'
                                onClick={() => setToggleReply(false)}
                                disabled={loading}
                              >
                                Cancel
                              </Button>
                              <Button
                                type='submit'
                                color='primary'
                                className='ms-1 btn mt-2'
                                disabled={loading}
                              >
                                Send
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  )}
                  <div className='mt-4'>
                    {unitTicket.messages
                      .slice()
                      .reverse()
                      .map((message: Message) => {
                        return <MessageBox key={message._id} message={message} id={id} />;
                      })}
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DetailUnitTicket;
