import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Col, Container, Table } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import { toStartCase } from 'helpers/translate';
import useTitle from 'hooks/useTitle';
import EventReportService from 'services/event-report.service';
import { Event, EventStatus } from 'types';

import EventBarChart from '../EventBarChart';
import EventComposition from '../EventComposition';

const limit = 10;

const EventStatistics = () => {
  useTitle('Event statistics', {
    restoreOnUnmount: true,
  });

  const [eventList, setEventList] = useState<Array<{ event: Event; participantCount: number }>>([]);
  const [eventCount, setEventCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  // Composition
  const [statusComposition, setStatusComposition] = useState<
    { status: EventStatus; count: number }[]
  >([]);

  // Created event statistics
  const [createdEventStat, setCreatedEventStat] = useState<
    { count: number; year: number; month: number }[]
  >([]);

  const generateRandomHex = () => {
    return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
  };

  const getParticipantCountOfEvents = useCallback(async () => {
    try {
      setLoading(true);
      const offset = limit * (currentPage - 1);
      const { data } = await EventReportService.getParticipantCountOfEvents(limit, offset, 'desc');
      const { payload } = data;
      setEventList(payload.eventList);
      setEventCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  const getCreatedEventStats = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventReportService.getEventCountOfTimeInterval('month');
      const { payload } = data;
      setCreatedEventStat(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, []);

  const getStatusComposition = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventReportService.getEventCountOfStatus();
      const { payload } = data;
      setStatusComposition(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getParticipantCountOfEvents();
  }, [getParticipantCountOfEvents]);

  useEffect(() => {
    getStatusComposition();
  }, [getStatusComposition]);

  useEffect(() => {
    getCreatedEventStats();
  }, [getCreatedEventStats]);

  if (loading)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Application' breadcrumbItem='Event statistics' />
          </Container>
          <Col>
            <Card className='tw-relative !tw-flex tw-w-full tw-gap-4'>
              <Loading height={320} width={320} />
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Event statistics' />
        </Container>
        <Col>
          <Card className='tw-relative !tw-flex tw-w-full tw-gap-4'>
            <EventComposition
              title='Composition'
              charts={[
                {
                  title: 'Completion',
                  dataValues: [
                    statusComposition
                      .filter((component) => component.status === EventStatus.CLOSED)
                      .reduce((acc, curr) => {
                        return acc + curr.count;
                      }, 0),
                    statusComposition
                      .filter(
                        (component) =>
                          component.status === EventStatus.ON_EVENT ||
                          component.status === EventStatus.OPEN_FOR_REGISTRATION ||
                          component.status === EventStatus.POST_EVENT
                      )
                      .reduce((acc, curr) => {
                        return acc + curr.count;
                      }, 0),
                    statusComposition
                      .filter((component) => component.status === EventStatus.PRE_EVENT)
                      .reduce((acc, curr) => {
                        return acc + curr.count;
                      }, 0),
                  ],
                  dataLabels: ['Closed', 'Ongoing', 'Upcoming'],
                  dataColors: ['#A3ACC2', '#0B2878', '#3D4863'],
                },
                {
                  title: 'Status',
                  dataValues: statusComposition.map((component) => {
                    return component.count;
                  }),
                  dataLabels: statusComposition.map((component) => {
                    return toStartCase(component.status);
                  }),
                  dataColors: ['#4285F4', '#EA4335', '#0F9D58', '#F1B44C', '#0B2878'],
                },
              ]}
            />

            <EventBarChart
              title='Created Events'
              charts={[
                {
                  title: new Date().getFullYear().toString(),
                  dataValues: createdEventStat
                    .filter((component) => {
                      return component.year.toString() === new Date().getFullYear().toString();
                    })
                    .map((component) => {
                      return component.count;
                    }),
                  dataLabels: createdEventStat
                    .filter((component) => {
                      return component.year.toString() === new Date().getFullYear().toString();
                    })
                    .map((component) => {
                      return `${component.month < 10 ? `0${component.month}` : component.month}/${
                        component.year
                      }`;
                    }),
                  dataColors: createdEventStat
                    .filter((component) => {
                      return component.year.toString() === new Date().getFullYear().toString();
                    })
                    .map(() => {
                      return generateRandomHex();
                    }),
                },
                {
                  title: 'All time',
                  dataValues: createdEventStat.map((component) => {
                    return component.count;
                  }),
                  dataLabels: createdEventStat.map((component) => {
                    return `${component.month < 10 ? `0${component.month}` : component.month}/${
                      component.year
                    }`;
                  }),
                  dataColors: createdEventStat.map(() => {
                    return generateRandomHex();
                  }),
                },
              ]}
            />
            <Card className='tw-relative tw-flex tw-flex-col tw-p-4 xl:tw-col-span-2'>
              <div className='tw-relative tw-font-bold'>Most Participated Events</div>
              <div className='table-responsive'>
                <Table className='project-list-table table-nowrap align-middle table-borderless tw-mt-4'>
                  <thead className='tw-font-bold tw-text-white'>
                    <tr>
                      <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                        #
                      </th>
                      <th className='!tw-bg-[#3D4863]' scope='col'>
                        Event
                      </th>
                      <th className='!tw-bg-[#3D4863]' scope='col'>
                        Phase
                      </th>
                      <th
                        className='!tw-bg-[#3D4863] tw-flex tw-flex-row tw-justify-end'
                        scope='col'
                      >
                        Participants
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventList.map((eventElement) => (
                      <tr key={eventElement.event._id}>
                        <td className='text-truncate font-size-14'>
                          {eventList.indexOf(eventElement) + 1}
                        </td>
                        <td className='text-truncate font-size-14 !tw-max-w-[20rem]'>
                          {eventElement.event.title}
                        </td>
                        <td>
                          <div className='text-truncate font-size-14 !tw-max-w-[10rem] tw-font-bold tw-items-center tw-flex tw-justify-center !tw-bg-primary tw-rounded-lg tw-text-white tw-px-2 tw-py-1'>
                            {toStartCase(eventElement.event.status)}
                          </div>
                        </td>
                        <td className='text-truncate font-size-14 tw-flex tw-flex-row tw-justify-end'>
                          {eventElement.participantCount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className='tw-relative tw-self-center tw-mt-4'>
                <Pagination
                  count={eventCount}
                  pageSize={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </Card>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventStatistics;
