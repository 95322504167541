import React, { useEffect, useState } from 'react';
import { Card, Col, Container } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Pagination from 'Components/Common/Pagination';
import { defaultEvent1 } from 'data/defaultEvent';
import useTitle from 'hooks/useTitle';

import EventCard from './EventCard';

const limit = 8;

const AllEvent = () => {
  useTitle('All events', {
    restoreOnUnmount: true,
  });

  const [eventCount, setEventCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setEventCount(16);
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='All events' />
        </Container>
        <Col>
          <Card className=' tw-overflow-hidden'>
            {/* Third section: EVENTS */}
            <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-gap-10 tw-p-4 xl:tw-p-[2.5rem]'>
              <div className='tw-flex tw-flex-col xs:tw-flex-row tw-justify-between xs:tw-items-end'>
                <div className='tw-flex tw-flex-col tw-relative tw-max-w-[30rem]'>
                  <div className='tw-relative tw-font-bold tw-text-[2rem]'>All events</div>
                  <div className='tw-relative'>
                    Dive into our rich collection of interesting events and explore!
                  </div>
                </div>
                <div className='tw-flex tw-w-[20rem] xl:tw-w-[35rem] 2xl:tw-w-[40rem] tw-h-[3rem] tw-border-t-4 tw-border-solid tw-border-[#3D4863] tw-self-end tw-justify-end tw-mt-4'>
                  <Pagination
                    count={eventCount}
                    pageSize={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
              <div className='tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-[1.5rem]'>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default AllEvent;
