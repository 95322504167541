import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

import contactSystem from '../../assets/animations/ContactSystem.json';
import event from '../../assets/animations/event.json';
import buttonAppStore from '../../assets/svg/AppStore.svg';
import cert from '../../assets/svg/cert.svg';
import buttonGooglePlay from '../../assets/svg/GooglePlay.svg';

type ContentSwipeHome = {
  title: string;
  description: string;
  button: string;
  link: string;
};

const Content: ContentSwipeHome[] = [
  {
    title: 'Event',
    description:
      "An interesting event is about to happen and you still have no idea where to search for it Don't worry! OISP Union App offers you an online platform that covers everything you need to prepare for the upcoming events.",
    button: 'Go to Event forum',
    link: '/user/event',
  },
  {
    title: 'Certificate',
    description:
      'At OISP, your progress matters! Just receive a great certificate OISP Union App stores every digital certificates that you receive from OISP in a collection that is convenient and easy to access.',
    button: 'Go to Certificate list',
    link: '/user/certificate',
  },
  {
    title: 'Contact System',
    description:
      "If you have any questions about Course Registration, Scholarship, Tuition Fee, Grade & Graduation, Civic Activities, etc. Let's contact us! We are always ready to support you.",
    button: 'Go to Event forum',
    link: '',
  },
];
const Event = {
  loop: true,
  autoplay: true,
  animationData: event,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const Contact = {
  loop: true,
  autoplay: true,
  animationData: contactSystem,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SwipeHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonEventClicked, setButtonEventClicked] = useState(false);
  const [buttonCertClicked, setButtonCertClicked] = useState(false);
  const [buttonContactSysClicked, setButtonContactSysClicked] = useState(false);

  useEffect(() => {
    if (currentIndex === 0) {
      setButtonEventClicked(true);
      setButtonCertClicked(false);
      setButtonContactSysClicked(false);
    } else if (currentIndex === 1) {
      setButtonCertClicked(true);
      setButtonEventClicked(false);
      setButtonContactSysClicked(false);
    } else {
      setButtonEventClicked(false);
      setButtonCertClicked(false);
      setButtonContactSysClicked(true);
    }
  }, [currentIndex, setButtonEventClicked]);

  return (
    <React.Fragment>
      <div className='tw-w-full tw-relative tw-overflow-hidden tw-mb-[50px] xl:tw-mb-0 tw-h-[600px] xl:tw-h-[940px]'>
        <h1
          style={{ color: 'rgba(11, 40, 120, 0.2)' }}
          className='tw-hidden xl:tw-flex tw-font-extrabold tw-truncate -tw-translate-x-6 tw-text-[200px] tw-text-left tw-z-0'
        >
          OISP UNION APP
        </h1>
        <div className='tw-flex tw-w-full tw-h-[600px] xl:tw-h-[800px] xl:tw-w-[560px] 2xl:tw-h-[880px] 2xl:tw-w-[800px] tw-z-10 tw-top-0 tw-right-0 tw-rounded-[8px] tw-absolute'>
          <div className='tw-flex-[3] xl:tw-block tw-hidden tw-relative'>
            <div className='xl:tw-block tw-hidden tw-rounded-full tw-border tw-border-solid tw-border-primary xl:tw-w-[440px] xl:tw-h-[440px] 2xl:tw-w-[560px] 2xl:tw-h-[560px] tw-absolute tw-flex-1 xl:tw-right-[-230px] 2xl:tw-right-[-320px] tw-top-[160px]' />
          </div>
          <div className='tw-flex-[5] tw-bg-primary tw-rounded-[8px] tw-shadow-2xl tw-relative tw-overflow-hidden tw-flex tw-justify-end tw-items-center'>
            <div className='xl:tw-block tw-hidden tw-rounded-full tw-border tw-border-solid tw-border-white xl:tw-w-[440px] xl:tw-h-[440px] 2xl:tw-w-[560px] 2xl:tw-h-[560px] tw-absolute tw-flex-1 2xl:tw-left-[-240px] xl:tw-left-[-210px] tw-top-[160px]' />
            <div className='tw-hidden xl:tw-flex tw-cursor-pointer tw-z-50 tw-w-[16%] tw-h-[25%] tw-absolute tw-right-4'>
              <ArrowForwardIosRoundedIcon
                className=' tw-cursor-pointer'
                sx={{ color: '#FFFFFF', width: '100%', height: '100%' }}
                onClick={() => {
                  setCurrentIndex((currentIndex + 1) % Content.length);
                }}
              />
            </div>
            <div
              style={{
                borderTop: '1px dashed white',
              }}
              className='xl:tw-w-[500px] tw-w-full tw-left-0 tw-h-[0px] tw-z-20 tw-mt-[550px] xl:tw-mt-[743px] 2xl:tw-mt-[823px] tw-absolute'
            ></div>
          </div>
          <div className=' tw-hidden xl:tw-block tw-rounded-full tw-border tw-bg-primary tw-border-solid tw-border-white 2xl:tw-w-[480px] 2xl:tw-h-[480px] tw-absolute tw-flex-1 xl:tw-left-[218px] xl:tw-top-[380px] 2xl:tw-left-[99px] 2xl:tw-top-[200px]'>
            <div className='tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-absolute tw-z-0'>
              <div className='tw-rounded-full tw-border tw-border-solid tw-border-white tw-w-[260px] tw-h-[260px] xs:tw-w-full xs:tw-h-full tw-absolute tw-overflow-hidden' />
              <div className='tw-bg-white tw-border tw-border-solid tw-border-primary tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-z-10 tw-h-[220px] tw-w-[220px] lg:tw-h-[320px] lg:tw-w-[320px] xl:tw-h-[380px] xl:tw-w-[380px] 2xl:tw-w-[480px] 2xl:tw-h-[480px] tw-absolute'>
                <div
                  className='tw-w-full tw-h-[600px] xl:tw-h-[800px] 2xl:tw-h-[880px] tw-flex tw-flex-row tw-items-center tw-justify-between tw-transition-transform tw-duration-500'
                  style={{ transform: `translateX(${currentIndex * -100}%)` }}
                >
                  <div className='tw-w-full tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'>
                    <div className='tw-w-[140px] tw-h-[140px] lg:tw-w-[200px] lg:tw-h-[200px] xl:tw-w-[400px] xl:tw-h-[260px] 2xl:tw-w-[500px] 2xl:tw-h-[300px] '>
                      <Lottie
                        options={Event}
                        height='100%'
                        width='100%'
                        isPaused={false}
                        style={{
                          pointerEvents: 'none',
                          zIndex: 20,
                        }}
                      />
                    </div>
                  </div>
                  <div className='tw-w-full tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'>
                    <img
                      src={cert}
                      alt='describe for Event forum'
                      className='tw-z-20 tw-w-[160px] tw-h-[160px] lg:tw-w-[220px] lg:tw-h-[220px] 2xl:tw-w-[340px] 2xl:tw-h-[340px] '
                    />
                  </div>

                  <div className='tw-w-full tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'>
                    <div className='tw-w-[140px] tw-h-[140px] lg:tw-w-[200px] lg:tw-h-[200px] xl:tw-w-[400px] xl:tw-h-[260px] 2xl:tw-w-[500px] 2xl:tw-h-[300px] '>
                      <Lottie
                        options={Contact}
                        height='100%'
                        width='100%'
                        isPaused={false}
                        style={{
                          pointerEvents: 'none',
                          zIndex: 20,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tw-hidden xl:tw-flex tw-flex-row tw-items-center tw-justify-center tw-z-20 tw-w-[100px] tw-h-[100px] xl:tw-w-[150px] xl:tw-h-[150px] tw-absolute tw-right-0 tw-bottom-10 xl:tw-right-[180px] xl:tw-bottom-20 2xl:tw-right-[240px] 2xl:tw-bottom-10 '>
            <div className='tw-hidden xl:tw-flex tw-w-[40px] tw-h-[40px] lg:tw-w-[60px] lg:tw-h-[60px] tw-items-center tw-justify-center tw-absolute tw-left-0 xl:tw-top-8'>
              <button
                onClick={() => {
                  if (currentIndex !== 0) {
                    setCurrentIndex(0);
                  }
                  setButtonEventClicked(true);
                  setButtonCertClicked(false);
                  setButtonContactSysClicked(false);
                }}
                className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform ${
                  buttonEventClicked
                    ? 'tw-w-[30px] tw-h-[30px] tw-duration-700'
                    : 'tw-w-[18px] tw-h-[18px] tw-duration-700'
                }`}
              >
                <div className='tw-bg-white tw-rounded-full tw-w-[18px] tw-h-[18px]'></div>
              </button>
            </div>
            <div className='tw-hidden xl:tw-flex tw-w-[40px] tw-h-[40px] lg:tw-w-[60px] lg:tw-h-[60px] tw-items-center tw-justify-center tw-absolute xl:tw-top-5 xl:tw-right-12   tw-z-50'>
              <button
                onClick={() => {
                  if (currentIndex !== 1) {
                    setCurrentIndex(1);
                  }
                  setButtonEventClicked(false);
                  setButtonCertClicked(true);
                  setButtonContactSysClicked(false);
                }}
                className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform ${
                  buttonCertClicked
                    ? 'tw-w-[30px] tw-h-[30px] tw-duration-700'
                    : 'tw-w-[18px] tw-h-[18px] tw-duration-700'
                }`}
              >
                <div className='tw-bg-white tw-rounded-full tw-w-[18px] tw-h-[18px]'></div>
              </button>
            </div>
            <div className='tw-hidden xl:tw-flex tw-w-[40px] tw-h-[40px] lg:tw-w-[60px] lg:tw-h-[60px] tw-items-center tw-justify-center tw-absolute xl:tw-top-0 xl:tw-right-2   tw-z-50'>
              <button
                onClick={() => {
                  if (currentIndex !== 2) {
                    setCurrentIndex(2);
                  }
                  setButtonEventClicked(false);
                  setButtonCertClicked(false);
                  setButtonContactSysClicked(true);
                }}
                className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform ${
                  buttonContactSysClicked
                    ? 'tw-w-[30px] tw-h-[30px] tw-duration-700'
                    : 'tw-w-[18px] tw-h-[18px] tw-duration-700'
                }`}
              >
                <div className='tw-bg-white tw-rounded-full tw-w-[18px] tw-h-[18px]'></div>
              </button>
            </div>
          </div>
          <div
            style={{
              borderTop: '1px dashed white',
            }}
            className='tw-w-full tw-h-[0px] tw-z-20 tw-mt-[20px] tw-absolute'
          ></div>
        </div>
        <div className='tw-flex tw-flex-col md:tw-w-full md:tw-justify-between md:tw-items-center xl:tw-items-start tw-mt-[60px] md:tw-mt-[170px] lg:tw-mt-[80px] tw-gap-[14px] md:tw-gap-0'>
          <div className='md:tw-mt-0 lg:tw-mt-[70px] md:tw-h-[400px] md:tw-absolute md:tw-right-16 md:tw-flex md:tw-flex-col'>
            <div className=' tw-z-40 xl:tw-hidden'>
              <div className='tw-flex tw-justify-center tw-items-center tw-z-0 '>
                <div className='tw-absolute md:tw-hidden tw-left-0 2xs:tw-left-1 xs:tw-left-10 sm:tw-left-14 tw-z-40 tw-w-[40px] tw-h-[40px] xs:tw-w-[60px] xs:tw-h-[60px]  '>
                  <ArrowBackIosRoundedIcon
                    sx={{ color: '#FFFFFF', width: '100%', height: '100%' }}
                    onClick={() => {
                      if (currentIndex === 0) {
                        setCurrentIndex(2);
                      } else {
                        setCurrentIndex((currentIndex - 1) % Content.length);
                      }
                    }}
                  />
                </div>
                <div className='tw-rounded-full tw-border tw-border-solid tw-z-30 tw-border-white tw-w-[230px] tw-h-[230px] md:tw-w-[260px] md:tw-h-[260px] lg:tw-w-[280px] lg:tw-h-[280px] tw-overflow-hidden' />
                <div className='tw-bg-white tw-border tw-border-solid tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-z-10 tw-h-[200px] tw-w-[200px] md:tw-w-[220px] md:tw-h-[220px] lg:tw-w-[240px] lg:tw-h-[240px] tw-absolute'>
                  <div
                    className='tw-w-full tw-h-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-transition-transform tw-duration-500'
                    style={{ transform: `translateX(${currentIndex * -100}%)` }}
                  >
                    <div className='tw-w-full tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'>
                      <div className='tw-w-[140px] tw-h-[140px] lg:tw-w-[200px] lg:tw-h-[200px] xl:tw-w-[400px] xl:tw-h-[260px] 2xl:tw-w-[500px] 2xl:tw-h-[300px] '>
                        <Lottie
                          options={Event}
                          height='100%'
                          width='100%'
                          isPaused={false}
                          style={{
                            pointerEvents: 'none',
                            zIndex: 20,
                          }}
                        />
                      </div>
                    </div>
                    <div className='tw-w-full tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'>
                      <img
                        src={cert}
                        alt='describe for Event forum'
                        className='tw-z-20 tw-w-[160px] tw-h-[160px] lg:tw-w-[220px] lg:tw-h-[220px] 2xl:tw-w-[340px] 2xl:tw-h-[340px] '
                      />
                    </div>

                    <div className='tw-w-full tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'>
                      <div className='tw-w-[140px] tw-h-[140px] lg:tw-w-[200px] lg:tw-h-[200px] xl:tw-w-[400px] xl:tw-h-[260px] 2xl:tw-w-[500px] 2xl:tw-h-[300px] '>
                        <Lottie
                          options={Contact}
                          height='100%'
                          width='100%'
                          isPaused={false}
                          style={{
                            pointerEvents: 'none',
                            zIndex: 20,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='tw-absolute tw-right-0 2xs:tw-right-1 xs:tw-right-10 sm:tw-right-14 md:tw-right-[-60px] tw-z-50 tw-w-[40px] tw-h-[40px] xs:tw-w-[60px] xs:tw-h-[60px] '>
                  <ArrowForwardIosRoundedIcon
                    sx={{ color: '#FFFFFF', width: '100%', height: '100%' }}
                    onClick={() => {
                      setCurrentIndex((currentIndex + 1) % Content.length);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='tw-hidden md:tw-flex xl:tw-hidden tw-flex-row tw-items-center tw-justify-center tw-z-50 tw-w-[100px] tw-h-[100px] tw-absolute md:tw-right-[-10px] md:tw-bottom-16 lg:tw-bottom-10'>
              <div className='xl:tw-hidden tw-w-[40px] tw-h-[40px] lg:tw-w-[60px] lg:tw-h-[60px] tw-flex tw-items-center tw-justify-center tw-absolute tw-left-[-10px] tw-top-8 lg:tw-top-8 lg:tw-left-[-20px] '>
                <button
                  onClick={() => {
                    if (currentIndex !== 0) {
                      setCurrentIndex(0);
                    }
                    setButtonEventClicked(true);
                    setButtonCertClicked(false);
                    setButtonContactSysClicked(false);
                  }}
                  className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full  tw-transition-transform tw-duration-500  ${
                    buttonEventClicked ? 'tw-w-[30px] tw-h-[30px]' : 'tw-w-[18px] tw-h-[18px]'
                  }`}
                >
                  <div className='tw-bg-white tw-rounded-full tw-w-[18px] tw-h-[18px] '></div>
                </button>
              </div>
              <div className='xl:tw-hidden tw-w-[40px] tw-h-[40px] lg:tw-w-[60px] lg:tw-h-[60px] tw-flex tw-items-center tw-justify-center tw-absolute tw-top-4 tw-right-8 lg:tw-top-5 lg:tw-right-6 tw-z-50 '>
                <button
                  onClick={() => {
                    if (currentIndex !== 1) {
                      setCurrentIndex(1);
                    }
                    setButtonEventClicked(false);
                    setButtonCertClicked(true);
                    setButtonContactSysClicked(false);
                  }}
                  className={`tw-items-center  tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform tw-duration-500  ${
                    buttonCertClicked ? 'tw-w-[30px] tw-h-[30px]' : 'tw-w-[18px] tw-h-[18px]'
                  }`}
                >
                  <div className='tw-bg-white tw-rounded-full tw-w-[18px] tw-h-[18px] '></div>
                </button>
              </div>
              <div className='xl:tw-hidden tw-w-[40px] tw-h-[40px] lg:tw-w-[60px] lg:tw-h-[60px] tw-flex tw-items-center tw-justify-center tw-absolute tw-top-[-10px] tw-right-0 lg:tw-right-[-10px] lg:tw-top-0 tw-z-50 '>
                <button
                  onClick={() => {
                    if (currentIndex !== 2) {
                      setCurrentIndex(2);
                    }
                    setButtonEventClicked(false);
                    setButtonCertClicked(false);
                    setButtonContactSysClicked(true);
                  }}
                  className={`tw-items-center  tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform tw-duration-500  ${
                    buttonContactSysClicked ? 'tw-w-[30px] tw-h-[30px]' : 'tw-w-[18px] tw-h-[18px]'
                  }`}
                >
                  <div className='tw-bg-white tw-rounded-full tw-w-[18px] tw-h-[18px] '></div>
                </button>
              </div>
            </div>
          </div>
          <div className='tw-flex tw-z-20 xl:tw-z-0 lg:tw-mt-[50px] xl:tw-mt-[-80px] 2xl:tw-mt-[-50px]'>
            <div className=' md:tw-pl-[40px] xl:tw-pl-[30px] 2xl:tw-pl-[40px] md:tw-w-[75%] xl:tw-w-1/2 lg:tw-mt-[30px] xl:tw-mt-0 2xl:tw-mt-[50px] tw-gap-[12px] md:tw-gap-[20px] xl:tw-gap-[20px] 2xl:tw-gap-[30px] tw-flex tw-flex-col tw-z-50 tw-items-center tw-justify-center md:tw-items-start md:tw-justify-start '>
              <h1 className='tw-hidden md:tw-flex tw-mb-0 tw-text-[44px] xl:tw-text-[54px] 2xl:tw-text-[64px] tw-text-left tw-font-extrabold md:tw-text-white xl:tw-text-[#3D4863] tw-z-20'>
                {Content[currentIndex].title}
              </h1>
              <p className='tw-flex tw-text-[14px] md:tw-text-[16px] xl:tw-text-[18px] 2xl:tw-text-[20px] 2xs:tw-w-[80%] xs:tw-w-[70%] sm:tw-w-[60%] tw-h-[120px] md:tw-h-[120px] xl:tw-h-[135px] 2xl:tw-h-[120px] md:tw-w-[70%] lg:tw-w-[68%] xl:tw-w-[82%] tw-mb-0 tw-text-white xl:tw-text-[#3D4863] tw-text-center md:tw-text-left tw-z-50'>
                {Content[currentIndex].description}
              </p>
              {(currentIndex === 0 || currentIndex === 1) && (
                <Link to={Content[currentIndex].link}>
                  <button className='2xl:tw-w-[240px] tw-h-[60px] md:tw-h-[70px] tw-w-[180px] xl:tw-w-[220px] tw-border tw-border-solid tw-rounded-[8px] tw-bg-white xl:tw-bg-primary tw-text-primary xl:tw-text-white tw-text-center tw-font-bold tw-text-[18px] xl:tw-text-[20px] 2xl:tw-text-[24px] tw-z-50 tw-flex tw-items-center tw-justify-center  '>
                    {Content[currentIndex].button}
                  </button>
                </Link>
              )}
              {currentIndex === 2 && (
                <div className='tw-flex tw-flex-row tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-6 tw-justify-center tw-items-center tw-h-[60px] md:tw-h-[70px]'>
                  <Link to='https://apps.apple.com/us/app/oisp/id6468775101'>
                    <button className='tw-flex tw-items-center tw-justify-center'>
                      <div className='tw-flex tw-absolute xl:tw-hidden tw-rounded-[4px] md:tw-rounded-[4px] tw-w-[119px] tw-h-[34px] md:tw-w-[139px] md:tw-h-[40px] tw-bg-white tw-z-20'></div>
                      <img
                        className=' tw-z-30 tw-w-[120px] tw-h-[60px]  md:tw-w-[140px] md:tw-h-[140px] 2xl:tw-w-[160px] 2xl:tw-h-[160px]'
                        src={buttonAppStore}
                        alt='button App Store'
                      />
                    </button>
                  </Link>
                  <Link to='https://play.google.com/store/apps/details?id=com.fessior.oisp&fbclid=IwY2xjawEYHwlleHRuA2FlbQIxMAABHcSjOS2aK2BCDeRD54V4IpSZaJb4Gqi_2FkvNGTnU1dCP6qjd5-R6a_o4w_aem_ztiF0W_AmfE8BY-DOlRWSw&pli=1'>
                    <button className='tw-flex tw-items-center tw-justify-center'>
                      <div className='tw-flex tw-absolute xl:tw-hidden tw-rounded-[4px] tw-w-[119px] tw-h-[34px] md:tw-w-[139px] md:tw-h-[40px] tw-bg-white tw-z-20'></div>
                      <img
                        className=' tw-z-30 tw-w-[120px] tw-h-[60px]  md:tw-w-[140px] md:tw-h-[140px] 2xl:tw-w-[160px] 2xl:tw-h-[160px]'
                        src={buttonGooglePlay}
                        alt='button App Store'
                      />
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className='md:tw-hidden tw-flex tw-flex-row tw-items-center tw-justify-center tw-z-50'>
            <div className='xl:tw-hidden tw-w-[40px] tw-h-[40px] tw-flex tw-items-center tw-justify-center '>
              <button
                onClick={() => {
                  if (currentIndex !== 0) {
                    setCurrentIndex(0);
                  }
                  setButtonEventClicked(true);
                  setButtonCertClicked(false);
                  setButtonContactSysClicked(false);
                }}
                className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full  tw-transition-transform tw-duration-500 ${
                  buttonEventClicked ? 'tw-w-[24px] tw-h-[24px]' : 'tw-w-[14px] tw-h-[14px]'
                }`}
              >
                <div className='tw-bg-white tw-rounded-full tw-w-[14px] tw-h-[14px] '></div>
              </button>
            </div>
            <div className='xl:tw-hidden tw-w-[40px] tw-h-[40px] tw-flex tw-items-center tw-justify-center'>
              <button
                onClick={() => {
                  if (currentIndex !== 1) {
                    setCurrentIndex(1);
                  }
                  setButtonEventClicked(false);
                  setButtonCertClicked(true);
                  setButtonContactSysClicked(false);
                }}
                className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform tw-duration-500 ${
                  buttonCertClicked ? 'tw-w-[24px] tw-h-[24px]' : 'tw-w-[14px] tw-h-[14px]'
                }`}
              >
                <div className='tw-bg-white tw-rounded-full tw-w-[14px] tw-h-[14px] '></div>
              </button>
            </div>
            <div className='xl:tw-hidden tw-w-[40px] tw-h-[40px] tw-flex tw-items-center tw-justify-center'>
              <button
                onClick={() => {
                  if (currentIndex !== 2) {
                    setCurrentIndex(2);
                  }
                  setButtonEventClicked(false);
                  setButtonCertClicked(false);
                  setButtonContactSysClicked(true);
                }}
                className={`tw-items-center tw-flex tw-justify-center tw-border tw-border-solid tw-border-white tw-rounded-full tw-transition-transform tw-duration-500 ${
                  buttonContactSysClicked ? 'tw-w-[24px] tw-h-[24px]' : 'tw-w-[14px] tw-h-[14px]'
                }`}
              >
                <div className='tw-bg-white tw-rounded-full tw-w-[14px] tw-h-[14px] '></div>
              </button>
            </div>
          </div>
        </div>
        <div className='tw-hidden xl:tw-flex xl:tw-flex-col tw-z-20 tw-w-full tw-pl-[40px] xl:tw-mt-[186px] 2xl:tw-mt-[169px]   '>
          <h1 className='tw-text-[16px] tw-text-primary tw-mb-[16px] '>OISP UNION APP</h1>
          <div className='tw-w-full tw-h-[0px] tw-border-t tw-border-primary'></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SwipeHome;
