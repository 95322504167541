import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import ProfileMenu from 'Components/CommonForBoth/TopBarDropDown/ProfileMenu';
import sidebarToggle from 'helpers/sidebar-toggle';
import useAppSelector from 'hooks/useAppSelector';
import { RootState } from 'slices';

import { ReactComponent as Logo } from '../../assets/svg/logobk.svg';

const Header = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { user } = useAppSelector((state: RootState) => state.Login);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <header className='tw-h-[3.5rem] tw-w-screen tw-relative tw-flex tw-flex-row tw-justify-between tw-items-center tw-z-[1000] tw-px-6 sm:tw-px-10'>
        <div className='tw-text-primary tw-font-bold tw-flex tw-flex-row tw-gap-[0.75rem]'>
          <Logo height={24} width={24} /> OISP UNION APP
        </div>
        {user && (
          <>
            <div className='tw-bg-primary tw-h-[0.5px] tw-w-[25%] lg:tw-w-[37.5%] tw-hidden md:tw-flex' />
            <button
              type='button'
              onClick={() => {
                sidebarToggle();
              }}
              className='font-size-16 tw-flex md:tw-hidden'
              id='vertical-menu-btn'
            >
              <i className='fa fa-fw fa-bars' />
            </button>
            <Link to='/dashboard' className='tw-hidden md:tw-flex'>
              <div>Home</div>
            </Link>

            <Link to='/contact-system' className='tw-hidden md:tw-flex'>
              <div>OContact</div>
            </Link>
            <Link to='/user/certificate' className='tw-hidden md:tw-flex'>
              <div>My Certificates</div>
            </Link>
            <Link to='/user/event' className='tw-hidden md:tw-flex'>
              <div>Event forum</div>
            </Link>

            <div className='tw-hidden md:tw-flex'>
              <ProfileMenu />
            </div>
          </>
        )}
        {!user && (
          <>
            <div className='tw-bg-primary tw-h-[0.5px] md:tw-w-[60%] xl:tw-w-[75%] tw-hidden md:tw-flex' />
            <Button onClick={() => navigate('/login')} color='primary'>
              Login
            </Button>
          </>
        )}
      </header>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
