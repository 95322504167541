const ignoredParams = [
  'signee',
  'signeePosition',
  'signature',
  'date',
  'email',
  'decisionDate',
  'decisionId',
  'decisionDocument',
  'decisionQr',
];

export default ignoredParams;
