import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, Student, StudentUpdateLog } from 'types';

const StudentService = {
  getStudentList: async (limit: number, offset = 0, filterQuery = '', sortQuery = '') => {
    return axios.get<
      ResponseData<{
        count: number;
        studentList: Student[];
      }>
    >(`${API_URL}admin/students?limit=${limit}&offset=${offset}&${filterQuery}&${sortQuery}`);
  },

  addStudent: async (data: Partial<Student>, managerKey: string) =>
    axios.post(`${API_URL}admin/students`, {
      data,
      managerKey,
    }),

  editStudent: async (id: string, editData: Partial<Student>, managerKey?: string) =>
    axios.patch(`${API_URL}admin/students/${id}`, {
      editData,
      managerKey,
    }),

  getStudent: async (id: string) =>
    axios.get<ResponseData<Student>>(`${API_URL}admin/students/${id}`),

  getUpdateLogs: async (limit: number, offset = 0) =>
    axios.get<ResponseData<StudentUpdateLog[]>>(
      `${API_URL}admin/students/logs?limit=${limit}&offset=${offset}`
    ),

  addStudentList: async <T>(data: T, managerKey: string) =>
    axios.post<ResponseData<StudentUpdateLog>>(`${API_URL}admin/students/bulk`, {
      data,
      managerKey,
    }),

  decrypt: async (data: string, managerKey: string) =>
    axios.post<ResponseData<string>>(`${API_URL}admin/students/decrypt`, {
      data,
      managerKey,
    }),
};

export default StudentService;
