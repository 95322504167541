import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import UnitService from 'services/unit.service';
import { Unit } from 'types';

type UnitCardProps = {
  unit: Unit;
  chooseUnit: (chosenUnit: Unit) => void;
};

const limit = 3;

const UnitCard = ({ unit, chooseUnit }: UnitCardProps) => {
  return (
    <Card onClick={() => chooseUnit(unit)}>
      <CardBody className='shadow-none border border-light choose-unit'>
        <div className='flex-grow-1 overflow-hidden'>
          <h5 className='text-truncate font-size-15 text-dark text-start choose-unit-text'>
            {unit.name}
          </h5>
          <p className='text-muted mb-4 border-top text-start choose-unit-text'>
            {unit.description}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

type ChooseUnitModalProps = {
  show: boolean;
  onCloseClick: React.MouseEventHandler | undefined;
  onChosen: (chosenUnit: Unit) => void;
};

const ChooseUnitModal = ({ show, onCloseClick, onChosen }: ChooseUnitModalProps) => {
  useTitle('Unit management', {
    restoreOnUnmount: true,
  });

  const [unitList, setUnitList] = useState<Unit[]>([]);
  const [unitCount, setUnitCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  //   const fetchUnitList = async () => {
  //     try {
  //       const { data } = await UnitService.getUnitList(limit, limit * (currentPage - 1));
  //       const { payload } = data;
  //       setUnitList(payload.unitList);
  //       setUnitCount(payload.count);
  //     } catch (error: unknown) {
  //       handleAxiosError(error, (message) => toast.error(message));
  //     }
  //   };

  useEffect(() => {
    UnitService.getUnitList(limit, limit * (currentPage - 1))
      .then((res) => {
        const { data } = res;
        const { payload } = data;
        setUnitList(payload.unitList);
        setUnitCount(payload.count);
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      });
  }, [currentPage]);

  return (
    <Modal isOpen={show} toggle={onCloseClick}>
      <div className='modal-content'>
        <ModalHeader className='text-muted font-size-16 mb-4'>
          Choose receive unit
          <button
            type='button'
            onClick={onCloseClick}
            className='btn-close position-absolute end-0 top-0 m-3'
          ></button>
        </ModalHeader>
        <ModalBody className='px-4 py-2 text-center'>
          <Row>
            {unitList.map((unit: Unit) => {
              return <UnitCard key={unit._id} unit={unit} chooseUnit={onChosen} />;
            })}
          </Row>
          <Pagination
            count={unitCount}
            pageSize={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ChooseUnitModal;
