import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import dateFormatter from 'helpers/dateFormatter';
import { Event, EventStatus } from 'types';

import defaultEventThumbnail from '../../../assets/images/eventDefaultThumnail.png';
import { ReactComponent as ArrowForward } from '../../../assets/svg/arrow_forward.svg';

type EventCardProps = {
  event: Event;
  type: 'registered' | 'unregistered' | 'invitation';
  detailTag: boolean;
  banner: boolean;
};

const EventCard = ({ event, type, detailTag, banner }: EventCardProps) => {
  const navigate = useNavigate();

  return (
    <div className='tw-relative tw-w-[15rem] tw-h-[20rem] tw-overflow-hidden tw-shadow-xl'>
      <img
        onClick={() => navigate('/user/event/' + event._id)}
        alt={`thumbnail of ${event.title}`}
        src={event.poster?.preview || defaultEventThumbnail}
        className='tw-w-full tw-h-full tw-absolute tw-object-cover tw-cursor-pointer'
      />
      {banner && (
        <div
          className={`${
            type === 'invitation' ? 'tw-bg-primary tw-text-white' : 'tw-bg-white tw-text-[#3D4863]'
          } tw-absolute tw-bottom-0 tw-w-full tw-h-[3.5rem]  tw-font-bold  tw-text-center tw-flex tw-justify-center tw-items-center`}
        >
          {event.status === EventStatus.ON_EVENT
            ? 'ONGOING'
            : type === 'invitation'
            ? 'NEW INVITATION'
            : dateFormatter(event.startAt)}
        </div>
      )}
      {detailTag && (
        <div
          className={`${
            type === 'invitation' ? 'tw-bg-primary tw-w-[5rem]' : 'tw-bg-white tw-w-[1.5rem]'
          } tw-absolute tw-h-[3.5rem] tw-top-[2rem]  tw-flex tw-flex-row tw-items-center tw-justify-center tw-peer tw-shadow-xl`}
        >
          {type === 'invitation' && (
            <div className='tw-font-bold tw-text-white tw-relative tw-mr-4'>NEW</div>
          )}
          <ArrowForward
            className={`${
              type === 'invitation' ? 'tw-fill-white' : 'tw-fill-[#3D4863]'
            } tw-relative tw-w-[1rem] tw-aspect-square`}
          />
        </div>
      )}
      <div className='tw-w-full tw-h-full tw-absolute tw-object-cover tw-flex tw-flex-col tw-justify-between tw-px-[1rem] tw-py-[1.5rem] tw-translate-x-[-100%] tw-duration-200 tw-ease-in-out hover:tw-translate-x-0 peer-hover:tw-translate-x-0 tw-bg-white tw-border-solid tw-border-[#3D4863] tw-border-[4px]'>
        <div className='tw-flex tw-flex-col'>
          <div className='tw-relative'>{dateFormatter(event.startAt)}</div>
          <div className='tw-relative tw-font-bold tw-text-[1.25rem] tw-truncate'>
            {event.title}
          </div>
          <div className='tw-relative tw-h-[7.5rem] tw-overflow-scroll no-scrollbar tw-mt-[0.5rem]'>
            {event.description}
          </div>
        </div>
        <div className='tw-flex tw-flex-row tw-self-end'>
          {type === 'unregistered' && (
            <Button
              type='button'
              color='subsecondary'
              disabled={false}
              onClick={() => navigate('/user/event/' + event._id)}
            >
              Register
            </Button>
          )}
          {type === 'invitation' && (
            <>
              <Button
                type='button'
                color='danger'
                disabled={false}
                onClick={() => {
                  console.log('reject');
                }}
              >
                Reject
              </Button>
              <Button
                type='button'
                color='success'
                className='tw-ml-2'
                disabled={false}
                onClick={() => {
                  console.log('accept');
                }}
              >
                Accept
              </Button>
            </>
          )}
        </div>
        <div className='tw-absolute tw-w-[1.5rem] tw-h-[3.5rem] tw-top-[2rem] tw-right-0 tw-bg-[#3D4863] tw-flex tw-items-center tw-justify-center'>
          <ArrowForward className='tw-relative tw-rotate-180 tw-w-[1rem] tw-aspect-square tw-fill-white' />
        </div>
      </div>
    </div>
  );
};

export default EventCard;
