export const fonts: Array<string> = [
  'Baskerville',
  'Myriad Pro',
  'Noto Serif',
  'Roboto',
  'UTM Times',
  'UTM Zirkon',
  'DFVN ED Drayton',
];

export const styles = ['normal', 'italic'];

export const alignments = [
  'left',
  'center',
  'right',
  'justify',
  'justify-left',
  'justify-center',
  'justify-right',
];

export const weights = ['normal', 'bold'];
