import { Button, Modal, ModalBody } from 'reactstrap';

type RegisterModalProps = {
  show: boolean;
  onRegisterClick: () => void;
  onCloseClick: () => void;
};

const RegisterModal = ({ show, onCloseClick, onRegisterClick }: RegisterModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='md'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Register for this event</h5>
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-gap-4 '>
          <div className='tw-whitespace-pre-wrap'>
            Once your registration for this event has been sent, you cannot undo it! After a
            confirmed registration, your absence from the event for any reason may leave a bad
            impression about you on the owner of this event afterwards. <br />
            <br />
            Proceed to do this?
          </div>
          <div className='tw-relative tw-flex tw-items-center tw-gap-4 tw-self-end'>
            <Button
              type='button'
              color='primary'
              outline
              className='tw-mt-[1rem]'
              disabled={false}
              onClick={onCloseClick}
            >
              Cancel
            </Button>
            <Button
              type='button'
              color='primary'
              className='tw-mt-[1rem]'
              disabled={false}
              onClick={onRegisterClick}
            >
              Register
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default RegisterModal;
