import { useState } from 'react';
import { Input } from 'reactstrap';

import Pagination from 'Components/Common/Pagination';
import { EventRole } from 'types';

import RoleCard from '../RoleCard';

type VerificationRequestParticipantsProps = {
  roles: Array<EventRole>;
  setOpenRole: (role: EventRole) => void;
  searchRoles: () => void;
};

const VerificationRequestRoles = ({
  roles,
  setOpenRole,
  searchRoles,
}: VerificationRequestParticipantsProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className='tw-relative tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-6'>
      <div className='tw-font-bold'>Roles</div>
      <div className='tw-flex tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
        <i className='bx bx-search-alt' />
        <Input
          className='!tw-border-0'
          onKeyUp={searchRoles}
          id='searchbarRole'
          type='text'
          placeholder='Search...'
        />
      </div>
      <div className='tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-[1.5rem]'>
        {roles.map((role: EventRole) => {
          return <RoleCard setOpenRole={setOpenRole} key={role._id} role={role} />;
        })}
      </div>
      <div className='tw-relative tw-self-center tw-mt-4'>
        <Pagination
          count={roles.length}
          pageSize={8}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default VerificationRequestRoles;
