import { useFormik } from 'formik';
// import mime from 'mime';
import { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';

import { defaultEvent1 } from 'data/defaultEvent';
// import formatBytes from 'helpers/format-bytes';
import { secondsToTimeDate } from 'helpers/timeConverter';
import { toStartCase } from 'helpers/translate';
import { Event } from 'types';

import defaultEventThumbnail from '../../../assets/images/eventDefaultThumnail.png';

// type AttachedFileProps = {
//   id: string | undefined;
//   attachment: Attachment;
// };

// const AttachedFile = ({ id, attachment }: AttachedFileProps) => {
//   const download = () => {
//     if (attachment._id && id) console.log(`download ${attachment.filename} now!`);
//   };

//   return (
//     <Card className='shadow-none border'>
//       <CardBody className='p-3' style={{ display: 'flex', flexDirection: 'row' }}>
//         <div
//           className='me-3'
//           style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//         >
//           <i
//             className='mdi mdi-image font-size-24 align-middle text-muted'
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           />
//         </div>
//         <div className='overflow-hidden me-auto' style={{ display: 'flex', alignItems: 'center' }}>
//           <div className='font-size-16 text-truncate '>
//             {attachment.filename}.{mime.getExtension(attachment.mimetype)}
//           </div>
//         </div>
//         <div className=' overflow-hidden me-auto' style={{ display: 'flex', alignItems: 'center' }}>
//           <div className='text-muted text-truncate'>{formatBytes(attachment.size)}</div>
//         </div>
//         <div>
//           <Button
//             type='button'
//             color='primary'
//             className='btn ml-2'
//             onClick={() => {
//               if (attachment._id) download();
//             }}
//           >
//             Download
//           </Button>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

type InfoRowProps = {
  name: string;
  content: string;
};

const InfoRow = ({ name, content }: InfoRowProps) => {
  return (
    <div className='tw-relative tw-w-full tw-flex tw-flex-row'>
      <Col xs={3} className='fw-semibold !tw-px-0 tw-self-start'>
        {name}:
      </Col>
      <Col>
        <p className='text-muted tw-mb-1'>{content}</p>
      </Col>
    </div>
  );
};

type PreviewFile = File & {
  preview: string;
};

type VerificationRequestInfoProps = {
  event: Event;
  openApproveModal: () => void;
  openRequestState: boolean;
  setOpenRequestState: (open: boolean) => void;
  onSendRequest: (message: string, files: Array<PreviewFile>) => void;
};

const VerificationRequestInfo = ({
  event,
  openApproveModal,
  openRequestState,
  setOpenRequestState,
  onSendRequest,
}: VerificationRequestInfoProps) => {
  const [selectedFiles, setSelectedFiles] = useState<PreviewFile[]>([]);

  // const timeToCoord = (width: number, time: number) => {
  //   const duration = event.endAt - event.startAt;

  //   const coord = ((time - event.startAt) / duration) * width;

  //   return coord;
  // };

  const startTime = useMemo(() => secondsToTimeDate(event.startAt), [event]);
  const endTime = useMemo(() => secondsToTimeDate(event.startAt), [event]);

  const handleAcceptedFiles = (files: File[]) => {
    const newFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setSelectedFiles(selectedFiles.concat(newFiles));
  };

  const validation = useFormik({
    initialValues: { message: '' },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      message: Yup.string().required('Please enter your reply'),
    }),
    onSubmit: (values, { resetForm }) => {
      onSendRequest(values.message, selectedFiles);
      resetForm();
    },
  });

  return (
    <div className='tw-relative tw-w-full tw-flex tw-flex-col md:!tw-flex-row md:tw-justify-start tw-gap-10 tw-p-6'>
      <img
        alt={`thumbnail of ${event.title}`}
        src={event.poster?.preview || defaultEventThumbnail}
        className='tw-w-[22.5rem] tw-h-[30rem] lg:tw-w-[15rem] lg:tw-h-[20rem] xl:tw-w-[22.5rem] xl:tw-h-[30rem] tw-relative tw-object-cover tw-self-center md:tw-self-start'
      />
      <div className='tw-relative tw-w-full tw-flex tw-flex-col tw-mt-10 md:tw-mt-0 tw-gap-8'>
        <div className='tw-font-bold tw-text-[20px]'>Detail</div>
        <div className='tw-flex tw-flex-col tw-w-full tw-gap-2 tw-text-[12px] xs:tw-text-[16px]'>
          <InfoRow name={`Event's name`} content={defaultEvent1.title} />
          <InfoRow name='Description' content={defaultEvent1.description} />
          <div className='tw-relative tw-w-full tw-flex tw-flex-row tw-items-center'>
            <Col xs={3} className='fw-semibold !tw-px-0'>
              Phase:
            </Col>
            <Col>
              <div className='text-truncate tw-w-max font-size-14 !tw-bg-primary tw-rounded-lg tw-text-white tw-px-2 tw-py-1'>
                {toStartCase(event.status)}
              </div>
            </Col>
          </div>
          <div className='tw-relative tw-w-full tw-flex tw-flex-row'>
            <Col xs={3} className='fw-semibold !tw-px-0'>
              Launch date:
            </Col>
            <Col>
              <div className='tw-flex tw-relative tw-flex-row tw-gap-2'>
                <div className='tw-font-bold'>From:</div>
                <div>{startTime}</div>
                <div className='tw-font-bold'>to:</div>
                <div>{endTime}</div>
              </div>
            </Col>
          </div>
          <div className='tw-relative tw-w-full tw-flex tw-flex-row tw-items-center'>
            <Col xs={3} className='fw-semibold !tw-px-0'>
              Timeline:
            </Col>
            <Col>
              <div className='tw-w-full tw-flex tw-flex-row tw-items-center tw-h-[2rem] tw-justify-between tw-mt-2'>
                {/* <div className='tw-flex 2xl:tw-hidden tw-h-[1rem] tw-relative tw-items-center tw-justify-center  tw-bg-primary tw-outline tw-outline-1 tw-outline-[#3D4863] tw-outline-offset-1 tw-w-[15rem]'>
                  {event.attendancePeriods.map((period) => {
                    return (
                      <div
                        key={period._id}
                        style={{
                          left: timeToCoord(240, period.startAt),
                          width: timeToCoord(240, period.endAt) - timeToCoord(240, period.startAt),
                        }}
                        className={`${
                          period.title === 'Check-in' ? 'tw-bg-[#0F9D58]' : 'tw-bg-[#4295f4]'
                        } tw-absolute tw-h-[16px] `}
                      />
                    );
                  })}
                </div>
                <div className='tw-hidden 2xl:tw-flex tw-h-[1rem] tw-relative tw-items-center tw-justify-center  tw-bg-primary tw-outline tw-outline-1 tw-outline-[#3D4863] tw-outline-offset-1 tw-w-[35rem]'>
                  {event.attendancePeriods.map((period) => {
                    return (
                      <div
                        key={period._id}
                        style={{
                          left: timeToCoord(560, period.startAt),
                          width: timeToCoord(560, period.endAt) - timeToCoord(560, period.startAt),
                        }}
                        className={`${
                          period.title === 'Check-in' ? 'tw-bg-[#0F9D58]' : 'tw-bg-[#4295f4]'
                        } tw-absolute tw-h-[16px] `}
                      />
                    );
                  })}
                </div>
              </div>
              <div className='tw-hidden 2xl:tw-flex tw-flex-row tw-items-center tw-h-[2rem] tw-w-[35rem] tw-relative tw-text-[16px]'>
                {event.attendancePeriods.map((period) => {
                  return (
                    <>
                      <div
                        style={{ left: timeToCoord(560, period.startAt) }}
                        className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                      >{`${new Date(period.startAt).getHours()}:${new Date(
                        period.startAt
                      ).getMinutes()}`}</div>
                      <div
                        style={{
                          left:
                            (timeToCoord(560, period.startAt) + timeToCoord(560, period.endAt)) / 2,
                        }}
                        className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                      >
                        {period.title}
                      </div>
                      <div
                        style={{ left: timeToCoord(560, period.endAt) }}
                        className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                      >{`${new Date(period.endAt).getHours()}:${new Date(
                        period.endAt
                      ).getMinutes()}`}</div>
                    </>
                  );
                })}
              </div>
              <div className='tw-flex 2xl:tw-hidden tw-flex-row tw-items-center tw-h-[2rem] tw-w-[15rem] tw-relative tw-text-[10px]'>
                {event.attendancePeriods.map((period) => {
                  return (
                    <>
                      <div
                        style={{ left: timeToCoord(200, period.startAt) }}
                        className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                      >{`${new Date(period.startAt).getHours()}:${new Date(
                        period.startAt
                      ).getMinutes()}`}</div>
                      <div
                        style={{
                          left:
                            (timeToCoord(240, period.startAt) + timeToCoord(240, period.endAt)) / 2,
                        }}
                        className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                      >
                        {period.title}
                      </div>
                      <div
                        style={{ left: timeToCoord(240, period.endAt) }}
                        className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                      >{`${new Date(period.endAt).getHours()}:${new Date(
                        period.endAt
                      ).getMinutes()}`}</div>
                    </>
                  );
                })} */}
              </div>
            </Col>
          </div>

          <InfoRow name='Location' content={event.location} />
        </div>
        {openRequestState && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            autoComplete='off'
          >
            <div className='mt-4 tw-flex tw-flex-col tw-gap-2'>
              <h5 className='font-size-16 mb-3'>Reply</h5>

              <FormGroup row>
                <div>
                  <Label htmlFor='message'>Message</Label>
                  <Input
                    tag='textarea'
                    id='message'
                    rows={5}
                    className='form-control'
                    name='message'
                    placeholder='Your message...'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.message || ''}
                    invalid={validation.touched.message && validation.errors.message ? true : false}
                  />
                  {validation.touched.message && validation.errors.message ? (
                    <FormFeedback type='invalid'>{validation.errors.message}</FormFeedback>
                  ) : null}
                </div>
              </FormGroup>

              <Label htmlFor='attachments'>Attachment</Label>
              <div>
                <Dropzone
                  onDrop={(acceptedFiles: File[]) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className='dropzone'>
                      <div className='dz-message needsclick' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='dz-message needsclick'>
                          <div className='mb-3'>
                            <i className='display-6 text-muted bx bxs-cloud-upload' />
                          </div>
                          <div style={{ fontSize: '16px' }}>
                            Drag and drop a file here or click to upload
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <ul className='list-unstyled mb-0' id='file-previews'>
                  {selectedFiles.map((file: PreviewFile) => {
                    return (
                      <li
                        className='mt-2 dz-image-preview'
                        key={file.name + file.type + selectedFiles.indexOf(file).toString()}
                      >
                        <div className='border rounded'>
                          <div className='d-flex flex-wrap gap-2 p-2'>
                            <div className='flex-shrink-0 me-3'>
                              <div className='avatar-sm bg-light rounded p-2'>
                                <img
                                  data-dz-thumbnail=''
                                  className='img-fluid rounded d-block'
                                  src={file.preview}
                                  alt={file.name}
                                />
                              </div>
                            </div>
                            <div className='flex-grow-1'>
                              <div className='pt-1'>
                                <h5 className='fs-md mb-1' data-dz-name>
                                  {file.webkitRelativePath}
                                </h5>
                                <strong className='error text-danger' data-dz-errormessage></strong>
                              </div>
                            </div>
                            <div className='flex-shrink-0 ms-3'>
                              <Button
                                variant='danger'
                                size='sm'
                                onClick={() => {
                                  const newFiles = [...selectedFiles];
                                  newFiles.splice(selectedFiles.indexOf(file), 1);
                                  setSelectedFiles(newFiles);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className='tw-flex tw-relative tw-flex-row tw-self-end tw-gap-4'>
                <Button
                  type='button'
                  color='danger'
                  outline
                  disabled={false}
                  onClick={() => setOpenRequestState(false)}
                >
                  Cancel
                </Button>
                <Button type='submit' color='danger' disabled={false}>
                  Send request
                </Button>
              </div>
            </div>
          </Form>
        )}
        {!openRequestState && (
          <div className='tw-flex tw-relative tw-flex-row tw-self-end tw-gap-4'>
            <Button
              type='button'
              color='danger'
              disabled={false}
              onClick={() => setOpenRequestState(true)}
            >
              Request modification
            </Button>
            <Button type='button' color='success' disabled={false} onClick={openApproveModal}>
              Approve
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerificationRequestInfo;
