import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { Event, EventPermissions, EventRole, ResponseData, Participant } from 'types';

const EventRoleService = {
  getEventRolesList: async (eventId: string) => {
    return axios.get<ResponseData<EventRole[]>>(`${API_URL}events/${eventId}/roles`);
  },

  getEventRole: async (eventId: string, roleId: string) => {
    return axios.get<ResponseData<EventRole>>(`${API_URL}events/${eventId}/roles/${roleId}`);
  },

  getParticipantsByRole: async (eventId: string, roleId: string) => {
    return axios.get<ResponseData<Array<Participant>>>(
      `${API_URL}events/${eventId}/participants/roles/${roleId}`
    );
  },

  createRole: async (
    eventId: string,
    role: {
      title: string;
      description: string;
      maxRegistration: number;
      permissions: Array<EventPermissions>;
    }
  ) => {
    return axios.post<ResponseData<Event>>(`${API_URL}events/${eventId}/roles/`, role);
  },

  updateRole: async (
    eventId: string,
    role: {
      _id: string;
      title: string;
      description: string;
      maxRegistration: number;
      permissions: Array<EventPermissions>;
    }
  ) => {
    return axios.put<ResponseData<Event>>(`${API_URL}events/${eventId}/roles/`, role);
  },

  deleteRole: async (eventId: string, roleId: string) => {
    return axios.delete<ResponseData<Event>>(`${API_URL}events/${eventId}/roles/${roleId}`);
  },
};

export default EventRoleService;
