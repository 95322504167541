import { useFormik } from 'formik';
import { get } from 'lodash';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
} from 'reactstrap';
import * as yup from 'yup';

import ManagerKeyModal from 'Components/Common/ManagerKeyModal';
import StudentService from 'services/student.service';
import { Gender, ResponseError, Student, CountryCode } from 'types';

import Breadcrumbs from '../../Components/Common/Breadcrumb';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_blue.css';

const initialValue: Partial<Student> = {
  // Thông tin chung
  familyName: '', // Họ
  firstName: '', // Tên
  phone: '', // Số điện thoại
  email: '', // Email HCMUT
  studentId: '', // Mã sinh viên
  gender: Gender.MALE, // Giới tính
  hometown: '', // Quê quán
  ethnic: '', // Dân tộc
  address: '', // Địa chỉ thường trú
  religion: '', // Tôn giáo
  job: '', // Nghề nghiệp
  citizenShip: {
    citizenId: '', // Số CMND
    citizenIdIssueDate: 0, // Ngày cấp CMND
    citizenIdIssuePlace: '', // Nơi cấp CMND
    nation: CountryCode.Vietnam, // Quốc tịch
  },
  union: {
    memberId: '', // Mã định danh đoàn viên
    issuedDate: 0, // Thời gian vào đoàn
    resolutionNumber: '', // Số Nghị quyết chuẩn y kết nạp đoàn viên
    issuedPlace: '', // Nơi ký
    cardNumber: '', // Số thẻ đoàn viên
    membership: '', // Đối tượng đoàn viên
    practice: '', // Rèn luyện đoàn viên
    qualification: '', // Đánh giá, xếp loại đoàn viên
    status: '', // (Chức vụ) Tình trạng đoàn viên
  },
};

const AddStudent = () => {
  const [managerKeyModal, setManagerKeyModal] = React.useState<boolean>(false);
  const [managerKey, setManagerKey] = React.useState<string>('');

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const createStudent = async (data: Partial<Student>, key: string) => {
    setLoading(true);
    try {
      await StudentService.addStudent(data, key);
      toast.success('Thêm sinh viên thành công');
      navigate('/student-info');
    } catch (error: unknown) {
      if (error instanceof ResponseError) toast.error(error?.response?.data?.message);
      else console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<Partial<Student>>({
    initialValues: initialValue,
    validationSchema: yup.object().shape({
      familyName: yup.string().required('Họ không được để trống'),
      firstName: yup.string().required('Tên không được để trống'),
      email: yup.string().email('Email không hợp lệ').required('Email không được để trống'),
      studentId: yup.string().required('Mã sinh viên không được để trống'),
      // .matches(/^[0-9]{7}$/, "Mã sinh viên không hợp lệ"),
      phone: yup
        .string()
        .required('Số điện thoại không được để trống')
        .matches(/^[0-9]{10}$/, 'Số điện thoại không hợp lệ'),
      gender: yup.mixed<Gender>().oneOf(Object.values(Gender)).required(),
      citizenShip: yup.object().shape({
        citizenId: yup.string(),
        citizenIdIssueDate: yup.number(),
        citizenIdIssuePlace: yup.string(),
        nation: yup.string(),
      }),
      union: yup.object().shape({
        memberId: yup.string(),
        issuedDate: yup.number(),
        resolutionNumber: yup.string(),
        issuedPlace: yup.string(),
        cardNumber: yup.string(),
      }),
    }),
    onSubmit: (values) => {
      setManagerKeyModal(false);
      createStudent(values, managerKey);
      setManagerKey('');
    },
  });

  return (
    <React.Fragment>
      <ManagerKeyModal
        value={managerKey}
        onChange={(e) => setManagerKey(e.target.value)}
        show={managerKeyModal}
        onCloseClick={() => {
          setManagerKeyModal(false);
          setManagerKey('');
        }}
        onSubmit={formik.handleSubmit}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Thông tin sinh viên'
            breadcrumbItem='Tạo sinh viên mới'
            backTo='/student-info'
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Thông tin cơ bản</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Họ
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='familyName'
                        className='form-control'
                        placeholder=' Nguyễn'
                        type='text'
                        autoComplete='on'
                        value={formik.values.familyName}
                        onChange={formik.handleChange}
                        invalid={formik.touched.familyName && !!formik.errors.familyName}
                      />
                      {formik.touched.familyName && formik.errors.familyName ? (
                        <FormFeedback type='invalid'>{formik.errors.familyName}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Tên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='firstName'
                        className='form-control'
                        placeholder=' Văn A'
                        type='text'
                        autoComplete='on'
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        invalid={formik.touched.firstName && !!formik.errors.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <FormFeedback type='invalid'>{formik.errors.firstName}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Email
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='email'
                        className='form-control'
                        placeholder=' ten.ho@hcmut.edu.vn'
                        type='email'
                        autoComplete='on'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        invalid={formik.touched.email && !!formik.errors.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <FormFeedback type='invalid'>{formik.errors.email}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Mã số sinh viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='studentId'
                        className='form-control'
                        placeholder=' 2350001'
                        type='text'
                        autoComplete='on'
                        value={formik.values.studentId}
                        onChange={formik.handleChange}
                        invalid={formik.touched.studentId && !!formik.errors.studentId}
                      />
                      {formik.touched.studentId && formik.errors.studentId ? (
                        <FormFeedback type='invalid'>{formik.errors.studentId}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số điện thoại
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='phone'
                        className='form-control'
                        placeholder=' 0987654321'
                        type='text'
                        autoComplete='on'
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        invalid={formik.touched.phone && !!formik.errors.phone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <FormFeedback type='invalid'>{formik.errors.phone}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Ngày sinh
                    </label>
                    <div className='col-md-10'>
                      <Flatpickr
                        name='dob'
                        className='form-control'
                        value={formik.values.dob || ''}
                        onChange={([date]) => {
                          formik.setFieldValue('dob', date);
                          return;
                        }}
                        options={{
                          mode: 'single',
                          altFormat: 'd/m/Y',
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      {formik.touched.dob && formik.errors.dob ? (
                        <FormFeedback type='invalid'>{formik.errors.dob}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Giới tính
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='gender'
                        type='select'
                        className='form-select'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.gender || ''}
                        invalid={formik.touched.gender && formik.errors.gender ? true : false}
                      >
                        <option>{Gender.FEMALE}</option>
                        <option>{Gender.MALE}</option>
                        <option>{Gender.OTHER}</option>
                      </Input>
                      {formik.touched.gender && formik.errors.gender ? (
                        <FormFeedback type='invalid'>{formik.errors.gender}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Quê quán
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='hometown'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.hometown}
                        onChange={formik.handleChange}
                        invalid={formik.touched.hometown && !!formik.errors.hometown}
                      />
                      {formik.touched.hometown && formik.errors.hometown ? (
                        <FormFeedback type='invalid'>{formik.errors.hometown}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Dân tộc
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='ethnic'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.ethnic}
                        onChange={formik.handleChange}
                        invalid={formik.touched.ethnic && !!formik.errors.ethnic}
                      />
                      {formik.touched.ethnic && formik.errors.ethnic ? (
                        <FormFeedback type='invalid'>{formik.errors.ethnic}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Địa chỉ thường trú
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='address'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        invalid={formik.touched.address && !!formik.errors.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <FormFeedback type='invalid'>{formik.errors.address}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Tôn giáo
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='religion'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.religion}
                        onChange={formik.handleChange}
                        invalid={formik.touched.religion && !!formik.errors.religion}
                      />
                      {formik.touched.religion && formik.errors.religion ? (
                        <FormFeedback type='invalid'>{formik.errors.religion}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Nghề nghiệp
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='job'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.job}
                        onChange={formik.handleChange}
                        invalid={formik.touched.job && !!formik.errors.job}
                      />
                      {formik.touched.job && formik.errors.job ? (
                        <FormFeedback type='invalid'>{formik.errors.job}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Thông tin công dân</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số CMND
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='citizenShip.citizenId'
                        className='form-control'
                        placeholder='XXXXXXXXXXXX'
                        type='text'
                        autoComplete='on'
                        value={formik.values.citizenShip?.citizenId}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'citizenShip.citizenId') &&
                          !!get(formik.errors, 'citizenShip.citizenId')
                        }
                      />
                      {get(formik.touched, 'citizenShip.citizenId') &&
                      !!get(formik.errors, 'citizenShip.citizenId') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'citizenShip.citizenId')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Ngày cấp CMND
                    </label>
                    <div className='col-md-10'>
                      <Flatpickr
                        name='citizenShip.citizenIdIssueDate'
                        className='form-control'
                        value={formik.values.citizenShip?.citizenIdIssueDate || ''}
                        onChange={([date]) => {
                          formik.setFieldValue('citizenShip.citizenIdIssueDate', date.getTime());
                        }}
                        options={{
                          mode: 'single',
                          altFormat: 'd/m/Y',
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      {get(formik.touched, 'citizenShip.citizenIdIssueDate') &&
                      !!get(formik.errors, 'citizenShip.citizenIdIssueDate') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'citizenShip.citizenIdIssueDate')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Nơi cấp CMND
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='citizenShip.citizenIdIssuePlace'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.citizenShip?.citizenIdIssuePlace}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'citizenShip.citizenIdIssuePlace') &&
                          !!get(formik.errors, 'citizenShip.citizenIdIssuePlace')
                        }
                      />
                      {get(formik.touched, 'citizenShip.citizenIdIssuePlace') &&
                      !!get(formik.errors, 'citizenShip.citizenIdIssuePlace') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'citizenShip.citizenIdIssuePlace')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Quốc tịch
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='citizenShip.nation'
                        type='select'
                        className='form-select'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.citizenShip?.nation || ''}
                        invalid={
                          get(formik.touched, 'citizenShip.nation') &&
                          !!get(formik.errors, 'citizenShip.nation')
                        }
                      >
                        {Object.keys(CountryCode).map((key) => (
                          <option key={key} value={CountryCode[key] as string}>
                            {key}
                          </option>
                        ))}
                      </Input>
                      {get(formik.touched, 'citizenShip.nation') &&
                      !!get(formik.errors, 'citizenShip.nation') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'citizenShip.nation')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Trình độ học vấn</CardTitle>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ văn hoá
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.academic'
                        className='form-control'
                        placeholder=' Hệ 12 / 12'
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.academic}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'standards.academic') &&
                          !!get(formik.errors, 'standards.academic')
                        }
                      />
                      {get(formik.touched, 'standards.academic') &&
                      !!get(formik.errors, 'standards.academic') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'standards.academic')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ chuyên môn
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.professional'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.professional}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'standards.professional') &&
                          !!get(formik.errors, 'standards.professional')
                        }
                      />
                      {get(formik.touched, 'standards.professional') &&
                      !!get(formik.errors, 'standards.professional') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'standards.professional')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ lý luận chính trị
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.politicalTheory'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.politicalTheory}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'standards.politicalTheory') &&
                          !!get(formik.errors, 'standards.politicalTheory')
                        }
                      />
                      {get(formik.touched, 'standards.politicalTheory') &&
                      !!get(formik.errors, 'standards.politicalTheory') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'standards.politicalTheory')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ tin học
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.computer'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.computer}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'standards.computer') &&
                          !!get(formik.errors, 'standards.computer')
                        }
                      />
                      {get(formik.touched, 'standards.computer') &&
                      !!get(formik.errors, 'standards.computer') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'standards.computer')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ ngoại ngữ
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.foreignLanguage'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.foreignLanguage}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'standards.foreignLanguage') &&
                          !!get(formik.errors, 'standards.foreignLanguage')
                        }
                      />
                      {get(formik.touched, 'standards.foreignLanguage') &&
                      !!get(formik.errors, 'standards.foreignLanguage') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'standards.foreignLanguage')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Thông tin Đoàn hội</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Mã định danh
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.memberId'
                        className='form-control'
                        placeholder=' 613000'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.memberId}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.memberId') &&
                          !!get(formik.errors, 'union.memberId')
                        }
                      />
                      {get(formik.touched, 'union.memberId') &&
                      !!get(formik.errors, 'union.memberId') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.memberId')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Ngày vào đoàn
                    </label>
                    <div className='col-md-10'>
                      <Flatpickr
                        name='union.issuedDate'
                        className='form-control'
                        value={formik.values.union?.issuedDate || ''}
                        onChange={([date]) => {
                          formik.setFieldValue('union.issuedDate', date.getTime());
                        }}
                        options={{
                          mode: 'single',
                          altFormat: 'd/m/Y',
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      {get(formik.touched, 'union.issuedDate') &&
                      !!get(formik.errors, 'union.issuedDate') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.issuedDate')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số Nghị quyết chuẩn y kết nạp
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.resolutionNumber'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.resolutionNumber}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.resolutionNumber') &&
                          !!get(formik.errors, 'union.resolutionNumber')
                        }
                      />
                      {get(formik.touched, 'union.resolutionNumber') &&
                      !!get(formik.errors, 'union.resolutionNumber') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.resolutionNumber')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Nơi ký
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.issuedPlace'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.issuedPlace}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.issuedPlace') &&
                          !!get(formik.errors, 'union.issuedPlace')
                        }
                      />
                      {get(formik.touched, 'union.issuedPlace') &&
                      !!get(formik.errors, 'union.issuedPlace') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.issuedPlace')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số thẻ đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.cardNumber'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.cardNumber}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.cardNumber') &&
                          !!get(formik.errors, 'union.cardNumber')
                        }
                      />
                      {get(formik.touched, 'union.cardNumber') &&
                      !!get(formik.errors, 'union.cardNumber') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.cardNumber')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Đối tượng đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.membership'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.membership}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.membership') &&
                          !!get(formik.errors, 'union.membership')
                        }
                      />
                      {get(formik.touched, 'union.membership') &&
                      !!get(formik.errors, 'union.membership') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.membership')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Rèn luyện đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.practice'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.practice}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.practice') &&
                          !!get(formik.errors, 'union.practice')
                        }
                      />
                      {get(formik.touched, 'union.practice') &&
                      !!get(formik.errors, 'union.practice') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.practice')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Đánh giá, xếp loại đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.qualification'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.qualification}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.qualification') &&
                          !!get(formik.errors, 'union.qualification')
                        }
                      />
                      {get(formik.touched, 'union.qualification') &&
                      !!get(formik.errors, 'union.qualification') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.qualification')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Tình trạng đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.status'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.status}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.status') &&
                          !!get(formik.errors, 'union.status')
                        }
                      />
                      {get(formik.touched, 'union.status') &&
                      !!get(formik.errors, 'union.status') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.status')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Hội
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.group'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.group}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.group') && !!get(formik.errors, 'union.group')
                        }
                      />
                      {get(formik.touched, 'union.group') && !!get(formik.errors, 'union.group') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.group')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Khen thưởng
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.rewards'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.rewards}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.rewards') &&
                          !!get(formik.errors, 'union.rewards')
                        }
                      />
                      {get(formik.touched, 'union.rewards') &&
                      !!get(formik.errors, 'union.rewards') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.rewards')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Khen thưởng
                    </label>
                    <div className='col-md-10'>Chưa cập nhật</div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Kỷ luật
                    </label>
                    <div className='col-md-10'>Chưa cập nhật</div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Đơn vị
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.unit'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.unit}
                        onChange={formik.handleChange}
                        invalid={
                          get(formik.touched, 'union.unit') && !!get(formik.errors, 'union.unit')
                        }
                      />
                      {get(formik.touched, 'union.unit') && !!get(formik.errors, 'union.unit') ? (
                        <FormFeedback type='invalid'>
                          {get(formik.errors, 'union.unit')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Button
            color='primary'
            className='btn btn-primary waves-effect waves-light mb-4'
            disabled={loading}
            onClick={() => setManagerKeyModal(true)}
          >
            Lưu
          </Button>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddStudent;
