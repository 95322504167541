import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { AccessLevel, ResponseData, User } from 'types';

const AccessLevelService = {
  getAllAccessLevels: async () =>
    axios.get<ResponseData<AccessLevel[]>>(`${API_URL}admin/access-levels`),

  getAccessLevelById: async (id: string) =>
    axios.get<ResponseData<AccessLevel>>(`${API_URL}admin/access-levels/${id}`),

  addAccessLevel: async (data: Partial<AccessLevel>) =>
    axios.post<ResponseData<AccessLevel>>(`${API_URL}admin/access-levels`, data),

  deleteById: async (id: string) =>
    axios.delete<ResponseData<AccessLevel>>(`${API_URL}admin/access-levels/${id}`),

  editAccessLevel: async (id: string, data: Partial<AccessLevel>) =>
    axios.patch<ResponseData<AccessLevel>>(`${API_URL}admin/access-levels/${id}`, data),

  getAccessLevelUsers: async (id: string, limit: number, offset = 0) =>
    axios.get<ResponseData<User[]>>(
      `${API_URL}admin/access-levels/${id}/users?limit=${limit}&offset=${offset}`
    ),

  setUserAccessLevelByEmail: async (id: string, email: string) =>
    axios.patch<ResponseData<AccessLevel>>(`${API_URL}admin/access-levels/${id}/users`, { email }),

  removeUserAccessLevel: async (id: string, userId: string) =>
    axios.delete<ResponseData<AccessLevel>>(`${API_URL}admin/access-levels/${id}/users/${userId}`),
};

export default AccessLevelService;
