import mime from 'mime';
import { ChangeEvent, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { Button, FormFeedback, Input, Modal, ModalBody } from 'reactstrap';

import { getCroppedImg } from 'helpers/getCroppedImage';

import defaultThumbnail from '../../../assets/images/eventDefaultThumnail.png';

type ThumbnailModalProps = {
  defaultImage?: string;
  showThumbnailModal: boolean;
  onCloseClick: () => void;
  setCroppedImageBlob: (imageBlob: Blob | null) => void;
};

const ThumbnailModal = ({
  defaultImage,
  showThumbnailModal,
  onCloseClick,
  setCroppedImageBlob,
}: ThumbnailModalProps) => {
  const [image, setImage] = useState<string>(defaultImage || defaultThumbnail);
  const [uploadError, setUploadError] = useState<string>('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation] = useState(0);

  const imageTypes = ['png', 'jpg', 'jpeg', 'gif'];

  const onCropComplete = (_, croppedArea: Area) => {
    setCroppedAreaPixels(croppedArea);
  };

  const setCroppedImage = () => {
    if (!croppedAreaPixels) return;
    getCroppedImg(image, croppedAreaPixels, rotation)
      .then((croppedImage) => {
        setCroppedImageBlob(croppedImage);
        onCloseClick();
      })
      .catch((error: unknown) => {
        console.log(error);
      });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile: File | undefined = event.target.files?.[0];
    console.log(targetFile);
    if (targetFile) {
      if (
        imageTypes
          .map((type) => {
            return mime.getType(type);
          })
          .includes(targetFile.type)
      ) {
        setUploadError('');
        setImage(URL.createObjectURL(targetFile));
      } else {
        setUploadError('File format is incorrect');
      }
    } else {
      setUploadError('Please select a file');
    }
  };
  return (
    <Modal
      isOpen={showThumbnailModal}
      toggle={onCloseClick}
      centered
      size='md'
      className='tw-rounded-[0.5rem] tw-p-4'
    >
      <ModalBody>
        <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4'>
          <span className='tw-text-[#3D4863] tw-font-bold tw-text-base sm:tw-text-lg tw-text-center'>
            Choose a thumbnail for your event
          </span>
          <div className='tw-w-[15rem] sm:tw-w-[18rem] tw-aspect-[3/4] tw-relative tw-rounded-lg'>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={3 / 4}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>

          <div className='tw-w-[15rem] sm:tw-w-[18rem] tw-flex tw-flex-col'>
            <Input
              className='form-control'
              id='formFileLg'
              type='file'
              onChange={handleFileUpload}
              invalid={uploadError !== ''}
            />
            {uploadError ? (
              <FormFeedback type='invalid' className='font-size-12'>
                {uploadError}
              </FormFeedback>
            ) : null}
          </div>

          <span className='tw-text-[#3D4863] tw-font-bold tw-text-xs lg:tw-text-base tw-text-center'>
            Please note that we only support thumbnail with ratio 3:4
          </span>
          <div className='tw-flex tw-flex-row tw-gap-4'>
            <Button outline type='button' color='primary' onClick={onCloseClick}>
              Cancel
            </Button>
            <Button type='button' color='primary' onClick={setCroppedImage}>
              Upload
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ThumbnailModal;
