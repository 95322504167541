import {
  CheckRequest,
  Event,
  EventPermissions,
  EventStatus,
  Participant,
  EventRole,
  EventPeriod,
  EventAction,
  EventActionModule,
} from 'types';

import defaultEventThumbnail from '../assets/images/eventDefaultThumnail.png';

export const defaultReviewAction1: EventAction = {
  _id: 'defaultReviewAction1Id',
  module: EventActionModule.VERIFICATION,
  title: 'Lorem ipsum',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices, orci sit amet bibendum hendrerit, nunc nunc ultricies neque',
  attachments: [],
  createdAt: 1720867194247,
  updatedAt: 1720867194247,
};

export const defaultCheckRequest: CheckRequest = {
  _id: 'defaultCheckRequestId',
  participantID: 'participantID',
  isSuccess: true,
  createdAt: 0,
  updatedAt: 0,
};

export const checkinPeriod: EventPeriod = {
  _id: 'defaultCheckinPeriodId',
  event: '',
  periodType: 'checkin',
  title: 'Check-in',
  startAt: 1720867194247,
  endAt: 1720871194247,
  createdAt: 1720871194247,
  updatedAt: 1720871194247,
};

export const attendancePeriod: EventPeriod = {
  _id: 'defaultCheckinPeriodId',
  event: '',
  periodType: 'checkin',
  title: 'Check-in',
  startAt: 1720867194247,
  endAt: 1720871194247,
  createdAt: 1720871194247,
  updatedAt: 1720871194247,
};

export const manager: EventRole = {
  _id: 'eventCreator',
  title: 'Event Creator',
  description: 'The creator of an event. Have all permissions',
  maxRegistration: 1,
  permissions: [EventPermissions.LEADER],
};

export const participant: EventRole = {
  _id: 'participant',
  title: 'Participant',
  description: 'The participant of an event. Have no permission',
  maxRegistration: 1000,
  permissions: [],
};

export const admin: EventRole = {
  _id: 'adminId',
  title: 'Admin',
  description: 'The admin of an event',
  maxRegistration: 3,
  permissions: [
    EventPermissions.CLOSE_EVENT,
    EventPermissions.GENERATE_REPORT,
    EventPermissions.MODIFY_DATA,
    EventPermissions.MODIFY_PARTICIPANT,
    EventPermissions.MODIFY_TIMELINE,
  ],
};

export const technician: EventRole = {
  _id: 'technicianId',
  title: 'Technician',
  description: 'The technician of an event',
  maxRegistration: 3,
  permissions: [EventPermissions.CLOSE_EVENT, EventPermissions.MODIFY_PARTICIPANT],
};

export const defaultParticipant: Participant = {
  _id: '123',
  user: {
    _id: '',
    name: 'John Doe',
    email: 'johndoe123@hcmut.edu.vn',
  },
  role: '',
  checkedIn: false,
  attendedPeriods: [
    {
      _id: 'attendedPeriod',
      period: 'attendance',
      attendedAt: 1721571899044,
    },
  ],
  createdAt: 1721571899044,
  updatedAt: 1721571899044,
};

export const defaultEvent1: Event = {
  _id: 'defaultId1',

  // Metadata
  status: EventStatus.PRE_EVENT,
  title: 'Lorem ipsum',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices, orci sit amet bibendum hendrerit, nunc nunc ultricies neque, et congue massa velit eu nunc. Donec a sem quis quam accumsan fermentum quis quis nunc...',
  location: '268 Ly Thuong Kiet',
  poster: {
    preview: defaultEventThumbnail,
    original: defaultEventThumbnail,
  },

  // Roles
  roles: [manager],

  // Period
  type: 'checkin',
  startAt: 1721571899044,
  endAt: 1721572899044,
  createdAt: 0,
  createdBy: {
    _id: '',
    name: 'John Doe',
    email: 'johndoe123@hcmut.edu.vn',
  },
  updatedAt: 1721571899044,
};

export const defaultEvent2: Event = {
  _id: 'defaultId1',

  // Metadata
  status: EventStatus.PRE_EVENT,
  title: 'Lorem ipsum',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices, orci sit amet bibendum hendrerit, nunc nunc ultricies neque, et congue massa velit eu nunc. Donec a sem quis quam accumsan fermentum quis quis nunc...',
  location: '268 Ly Thuong Kiet',

  // Roles
  roles: [manager],

  // Period
  type: 'checkin',
  startAt: 1720861194247,
  endAt: 1720871194247,
  createdAt: 0,
  createdBy: {
    _id: '',
    name: 'John Doe',
    email: 'johndoe123@hcmut.edu.vn',
  },
  updatedAt: 1721571899044,
};

export const defaultEvent3: Event = {
  _id: 'defaultId1',

  // Metadata
  status: EventStatus.PRE_EVENT,
  title: 'Lorem ipsum',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices, orci sit amet bibendum hendrerit, nunc nunc ultricies neque, et congue massa velit eu nunc. Donec a sem quis quam accumsan fermentum quis quis nunc...',
  location: '268 Ly Thuong Kiet',

  // Roles
  roles: [manager],

  // Period
  type: 'checkin',
  startAt: 1720861194247,
  endAt: 1720871194247,
  createdAt: 0,
  createdBy: {
    _id: '',
    name: 'John Doe',
    email: 'johndoe123@hcmut.edu.vn',
  },
  updatedAt: 1721571899044,
};
