const sidebarToggle = () => {
  const sidebar: HTMLElement | null = document.getElementById('sidebarMenu');
  if (sidebar) {
    //open sidebar
    if (sidebar.classList.contains('tw-translate-x-[-100%]')) {
      sidebar.classList.remove('tw-translate-x-[-100%]');
      sidebar.classList.toggle('tw-translate-x-[0%]');
      //close sidebar
    } else {
      sidebar.classList.remove('tw-translate-x-[0%]');
      sidebar.classList.toggle('tw-translate-x-[-100%]');
    }
  }

  const blurScreen: HTMLElement | null = document.getElementById('blurScreen');

  if (blurScreen) {
    if (blurScreen.classList.contains('tw-hidden')) {
      blurScreen.classList.remove('tw-hidden');
      blurScreen.classList.toggle('tw-flex');
      //close sidebar
    } else {
      blurScreen.classList.remove('tw-flex');
      blurScreen.classList.toggle('tw-hidden');
    }
  }
};

export default sidebarToggle;
