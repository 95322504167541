import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import logoDark from '../../assets/images/logo-dark.png';
import logo from '../../assets/images/logo-light.png';

import SidebarContent from './SidebarContent';

const logoLightPng: string = logo;
const logoLightSvg: string = logo;

type SidebarProps = {
  type?: string;
};

const Sidebar = ({ type }: SidebarProps) => {
  const sidebarToggle = () => {
    const body: HTMLElement = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  };

  const handleOutSideClick = () => {
    const sidebar = document.getElementById('sidebarMenu');
    const sidebarButton = document.getElementById('vertical-menu-btn');
    document.onclick = (event) => {
      if (
        sidebar &&
        sidebarButton &&
        !sidebar.contains(event.target as Node) &&
        !sidebarButton.contains(event.target as Node) &&
        document.body.classList.contains('sidebar-enable')
      ) {
        console.log(document.body.classList.contains('sidebar-enable'));
        sidebarToggle();
      }
    };
  };

  useEffect(() => {
    handleOutSideClick();
  });

  return (
    <React.Fragment>
      <div id='sidebarMenu' className='vertical-menu'>
        <div className='navbar-brand-box tw-flex tw-justify-center tw-items-center tw-h-[70px]'>
          <Link
            to='/'
            className='logo logo-dark tw-flex tw-justify-center tw-items-center tw-h-[70px]'
          >
            <span className='logo-sm'>
              <img src={logo} alt='logo' width='55' height='55' />
            </span>
            <span className='logo-lg'>
              <img src={logoDark} alt='logo' className='tw-h-[55px]' height='55' />
            </span>
          </Link>

          <Link to='/' className='logo logo-light'>
            <span className='logo-sm'>
              <img
                src={logoLightSvg}
                style={{ objectFit: 'cover' }}
                alt='logo'
                width='55'
                height='55'
              />
            </span>
            <span className='logo-lg'>
              <img
                src={logoLightPng}
                style={{ objectFit: 'cover' }}
                alt='logo'
                width='55'
                height='55'
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className='h-100'>
          {type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className='sidebar-background'></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
