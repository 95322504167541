import { Button, Modal, ModalBody } from 'reactstrap';

type ApproveModalProps = {
  show: boolean;
  onApproveClick: () => void;
  onCloseClick: () => void;
};

const ApproveModal = ({ show, onCloseClick, onApproveClick }: ApproveModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='md'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Approve this event</h5>
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-gap-4 '>
          <div className='tw-whitespace-pre-wrap'>
            Once your approval for this event has been sent, you cannot undo it! After it has been
            approved, it will be put into the Registration phase and will be opened publicly for
            registration. <br />
            <br />
            Proceed to do this?
          </div>
          <div className='tw-relative tw-flex tw-items-center tw-gap-4 tw-self-end'>
            <Button
              type='button'
              color='primary'
              outline
              className='tw-mt-[1rem]'
              disabled={false}
              onClick={onCloseClick}
            >
              Cancel
            </Button>
            <Button
              type='button'
              color='success'
              className='tw-mt-[1rem]'
              disabled={false}
              onClick={onApproveClick}
            >
              Approve
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ApproveModal;
