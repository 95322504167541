import axios, {
  AxiosError,
  AxiosInstance,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from 'axios';
import _ from 'lodash';

import { ResponseData } from 'types';

let callback401: (error: unknown) => void = () => {};

export function set401Callback(cb: (error: unknown) => void) {
  callback401 = cb;
}

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ResponseData>) => {
    const { response } = error;
    if (
      !_.isEmpty(response) &&
      response.status === HttpStatusCode.Unauthorized &&
      !_.isNull(callback401)
    ) {
      callback401(response.data);
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token: string | null = localStorage.getItem('token');
    config.headers.authorization = `Bearer ${JSON.parse(token || 'null') as string}`;
    return config;
  },
  (error) => {
    console.log('Error in axios');
    Promise.reject(error);
  }
);

export { axiosInstance as axios };
