import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { Directory } from 'types';

type FileRightBarProps = {
  directory: Directory;
  onNewDirectoryClick: () => void;
  onNewAttachmentClick: () => void;
};

const FileLeftBar = ({
  directory,
  onNewDirectoryClick,
  onNewAttachmentClick,
}: FileRightBarProps) => {
  return (
    <React.Fragment>
      <Card className='filemanager-sidebar col-md-3'>
        <CardBody>
          <div className='d-flex flex-column h-100'>
            <div className='mb-4'>
              <div className='mb-3'>
                <UncontrolledDropdown>
                  <DropdownToggle className='btn btn-light w-100' type='button'>
                    <i className='mdi mdi-plus me-1'></i> Add
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={onNewDirectoryClick}>
                      <i className='bx bx-folder me-1'></i> New Folder
                    </DropdownItem>
                    <DropdownItem onClick={onNewAttachmentClick}>
                      <i className='bx bx-file me-1'></i> New File
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <ul className='list-unstyled categories-list'>
                {directory.parentId && (
                  <li className='mb-2'>
                    <Link
                      to={`/storage${directory.parentId ? `?folder=${directory.parentId}` : ''}`}
                      className='text-body d-flex'
                    >
                      <i className='dripicons-arrow-left font-size-16 me-2'></i>{' '}
                      <span className='me-auto'>Back</span>
                    </Link>
                  </li>
                )}

                {directory.directoryList.map((childDirectory) => (
                  <li key={childDirectory._id}>
                    <div className='custom-accordion'>
                      <Link
                        className='text-body fw-medium py-1 d-flex align-items-center'
                        to={`/storage?folder=${childDirectory._id}`}
                      >
                        <i className='mdi mdi-folder font-size-16 text-warning me-2'></i>{' '}
                        <span className='me-auto'>{childDirectory.name}</span>{' '}
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default FileLeftBar;
