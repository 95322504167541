import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import TableContainer from 'Components/Common/TableContainer';
import { PermissionDescription } from 'config';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import AccessLevelService from 'services/access-levels.service';
import { Permission, User, AccessLevel } from 'types';

import Breadcrumbs from '../../Components/Common/Breadcrumb';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_green.css';

const addOrRemove = <T,>(_array: Array<T>, value: T) => {
  const array: T[] = _.cloneDeep(_array);
  const index: number = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }

  return array;
};

const AccessLevelDetail = () => {
  const { accessLevelId } = useParams();
  const [accessLevel, setAccessLevel] = useState<AccessLevel>();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addPersonModal, setAddPersonModal] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const [addPersonEmail, setAddPersonEmail] = useState('');

  useTitle(accessLevel?.name || '', {
    restoreOnUnmount: true,
  });

  const fetchAccessLevel = useCallback(async (alId: string) => {
    try {
      const { data } = await AccessLevelService.getAccessLevelById(alId || '');
      const { payload } = data;
      return payload;
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, []);

  const fetchUserList = useCallback(async (alId: string) => {
    try {
      const { data } = await AccessLevelService.getAccessLevelUsers(alId || '', 20);
      const { payload } = data;
      return payload;
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, []);

  const handleAddPerson = () => {
    setLoading(true);
    AccessLevelService.setUserAccessLevelByEmail(accessLevelId || '', addPersonEmail)
      .then(() => {
        toast.success('Add user successfully');
        setLoading(false);
        setAddPersonModal(false);
        return fetchUserList(accessLevelId || '');
      })
      .then((newUserList) => {
        if (newUserList) setUserList(newUserList);
      })
      .catch((error) => {
        handleAxiosError(error, (message) => toast.error(message));
      });
  };

  const handleDeletePerson = useCallback(
    async (userId: string) => {
      try {
        setLoading(true);
        await AccessLevelService.removeUserAccessLevel(accessLevelId || '', userId);
        toast.success('Delete user successfully');
        setLoading(false);
        const newUserList: User[] | undefined = await fetchUserList(accessLevelId || '');
        if (newUserList) setUserList(newUserList);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      }
    },
    [accessLevelId, fetchUserList]
  );

  const handleSubmit = () => {
    setLoading(true);
    AccessLevelService.editAccessLevel(accessLevelId || '', accessLevel as AccessLevel)
      .then(() => {
        toast.success('Update access level successfully');
        setLoading(false);
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      });

    return;
  };

  useEffect(() => {
    Promise.all([fetchAccessLevel(accessLevelId || ''), fetchUserList(accessLevelId || '')])
      .then(([newAccessLevel, newUserList]) => {
        setAccessLevel(newAccessLevel);
        setUserList(newUserList || []);
      })
      .catch(() => {});
  }, [accessLevelId, fetchAccessLevel, fetchUserList]);

  const columns = useMemo<ColumnDef<User & { index: number }, keyof (User & { index: number })>[]>(
    () => [
      {
        header: 'Number',
        accessorKey: 'index',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Detail',
        accessorKey: '_id',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return (
            <Link
              to='#'
              onClick={() => {
                handleDeletePerson(cellProps.getValue());
              }}
              className='text-danger'
            >
              <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
              <UncontrolledTooltip placement='top' target='deletetooltip'>
                Delete
              </UncontrolledTooltip>
            </Link>
          );
        },
      },
    ],
    [handleDeletePerson]
  );

  return (
    <>
      <Modal isOpen={addPersonModal} toggle={() => setAddPersonModal(!addPersonModal)} centered>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>New User</h5>
          <button
            type='button'
            onClick={() => setAddPersonModal(!addPersonModal)}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <div className='mb-4'>
            <input
              className='form-control'
              type='text'
              placeholder='abc@hcmut.edu.vn'
              value={addPersonEmail}
              onChange={(e) => setAddPersonEmail(e.target.value)}
            />
          </div>
          <Button
            color='primary'
            className='btn btn-primary waves-effect waves-light'
            onClick={handleAddPerson}
          >
            Save
          </Button>
        </div>
      </Modal>

      <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)} centered>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Update Access Level</h5>
          <button
            type='button'
            onClick={() => setOpenModal(!openModal)}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <div className='control-group my-4'>
            {Object.values(Permission)?.map((permission) => {
              const value: boolean = (accessLevel?.permissions as string[])?.includes(permission);

              return (
                <div className='form-check mb-2' key={permission}>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id={permission}
                    checked={value}
                    onChange={() => {
                      if (typeof accessLevel !== 'string')
                        setAccessLevel({
                          ...(accessLevel as AccessLevel),
                          permissions: addOrRemove(accessLevel?.permissions || [], permission),
                        });
                    }}
                  />
                  <Label className='form-check-label' htmlFor={permission}>
                    {PermissionDescription[permission]}
                  </Label>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>

      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Access Level'
            breadcrumbItem={accessLevel?.name || ''}
            backTo='/access-levels'
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Primary Information</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Name
                    </label>
                    <div className='col-md-10'>
                      <input
                        className='form-control'
                        type='text'
                        value={accessLevel?.name || ''}
                        onChange={(e) =>
                          setAccessLevel({
                            ...(accessLevel as AccessLevel),
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Description
                    </label>
                    <div className='col-md-10'>
                      <Input
                        type='textarea'
                        id='textarea'
                        value={accessLevel?.description || ''}
                        onChange={(e) =>
                          setAccessLevel({
                            ...(accessLevel as AccessLevel),
                            description: e.target.value,
                          })
                        }
                        maxLength={225}
                        rows='3'
                        placeholder='Enter your description'
                      />
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Created At
                    </label>
                    <div className='col-md-10'>
                      <InputGroup>
                        <input
                          className='form-control'
                          type='text'
                          value={
                            new Date(accessLevel?.createdAt as number).toLocaleString('vi-VN') || ''
                          }
                          onChange={() => {}}
                        />
                      </InputGroup>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Created By
                    </label>
                    <div className='col-md-10'>
                      <InputGroup>
                        <input
                          className='form-control'
                          type='text'
                          value={(accessLevel?.createdBy as User)?.name || ''}
                          onChange={() => {}}
                        />
                      </InputGroup>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Permissions
                    </label>
                    <div className='col-md-10'>
                      <InputGroup>
                        <Button
                          color='primary'
                          className='btn btn-primary waves-effect waves-light'
                          onClick={() => setOpenModal(true)}
                        >
                          Change
                        </Button>
                      </InputGroup>
                    </div>
                  </Row>
                  <Button
                    color='primary'
                    className='btn btn-primary waves-effect waves-light'
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Save changes
                  </Button>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row className='mb-2'>
                    <Col>
                      <CardTitle className='h4'>Recipients</CardTitle>
                      <div className='text-sm-end'>
                        <Button
                          type='button'
                          color='success'
                          className='btn-rounded mb-2 me-2'
                          onClick={() => setAddPersonModal(true)}
                        >
                          <i className='mdi mdi-plus me-1' />
                          New User
                        </Button>
                      </div>
                    </Col>

                    <TableContainer
                      columns={columns}
                      tableClass='table align-middle table-nowrap'
                      theadClass=''
                      data={userList.map((user, index) => ({
                        ...user,
                        index: index + 1,
                      }))}
                      pageSize={userList.length}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AccessLevelDetail;
