import Lottie from 'react-lottie';

import loadingAnimation from '../../assets/animations/newLoading.json';

type LoadingProps = {
  width?: number;
  height?: number;
};

const Loading = ({ width: _width = 100, height: _height = 100 }: LoadingProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  };

  return (
    <div className='tw-w-full tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center'>
      <div className='tw-w-[90%] sm:tw-max-w-md'>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height='100%'
          width='100%'
          isStopped={false}
          isPaused={false}
          speed={1.5}
        />
      </div>
    </div>
  );
};

export default Loading;
