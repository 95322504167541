/**
 * Returns the time since a given date in a human readable format
 * Source: https://stackoverflow.com/a/3177838
 * @param date ISO 8601 date string
 * @returns {string} HH:MM Month Day, Year
 */
export default function dateFormatter(date: number): string {
  return `${new Date(date).getHours()}:${new Date(date).getMinutes()} ${new Date(
    date
  ).toLocaleString('default', { month: 'long' })} ${new Date(date).getDate()}, ${new Date(
    date
  ).getFullYear()}`;
}
