import { fabric } from 'fabric';

const imageFetcher = (url: string, imageOptions: fabric.IImageOptions): Promise<fabric.Image> => {
  return new Promise<fabric.Image>((resolve) => {
    fabric.Image.fromURL(
      url,
      (image) => {
        resolve(image);
      },
      imageOptions
    );
  });
};

export default imageFetcher;
