import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import DeleteModal from 'Components/Common/DeleteModal';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import timeSince from 'helpers/time-since';
import useTitle from 'hooks/useTitle';
import UnitService from 'services/unit.service';
import { Unit } from 'types';

type UnitCardProps = {
  unit: Unit;
  setDeleteUnitId: (id: string) => void;
};

const limit = 20;

const UnitCard = (unitCard: UnitCardProps) => {
  const [isMenu, setisMenu] = useState<boolean>(false);
  const toggleMenu = () => setisMenu(!isMenu);

  const navigate = useNavigate();

  return (
    <Col xl={4} sm={6}>
      <Card>
        <CardBody>
          <Dropdown isOpen={isMenu} toggle={toggleMenu} className='float-end ms-2'>
            <DropdownToggle tag='a' className='text-muted'>
              <i className='mdi mdi-dots-horizontal font-size-18' />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => navigate('/unit-management/' + unitCard.unit._id)}>
                <i className='bx bx-edit me-1' />
                Edit
              </DropdownItem>
              <DropdownItem
                className='text-danger'
                onClick={() => unitCard.setDeleteUnitId(unitCard.unit._id)}
              >
                <i className='bx bx-trash me-1' />
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <div className='d-flex'>
            <div className='flex-grow-1 overflow-hidden'>
              <h5 className='text-truncate font-size-15 text-dark'>{unitCard.unit.name}</h5>
              <p className='text-muted mb-4'>{unitCard.unit.description}</p>
            </div>
          </div>
        </CardBody>
        <div className='px-4 py-3 border-top'>
          <ul className='list-inline mb-0 justify-content-between'>
            <li className='list-inline-item me-3' id='dueDate'>
              <i className='bx bx-calendar me-1' />{' '}
              {Date.now() - unitCard.unit.createdAt >= 846000000
                ? new Date(unitCard.unit.createdAt).toLocaleString('vi-VN')
                : timeSince(unitCard.unit.createdAt)}
            </li>
            <li className='list-inline-item me-3' id='member'>
              <i className='bx bx-group me-1' />
              {unitCard.unit.users.length}
            </li>
          </ul>
        </div>
      </Card>
    </Col>
  );
};

const UnitManagement = () => {
  useTitle('Unit management', {
    restoreOnUnmount: true,
  });

  const [unitList, setUnitList] = useState<Unit[]>([]);
  const [unitCount, setUnitCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  //state to handle fetch process
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await UnitService.deleteUnit(deleteId || '');
      await fetchUnitList();
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
      setDeleteId(undefined);
    }
  };

  const fetchUnitList = async () => {
    try {
      const { data } = await UnitService.getUnitList(limit, limit * (currentPage - 1));
      const { payload } = data;
      setUnitList(payload.unitList);
      setUnitCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  useEffect(() => {
    UnitService.getUnitList(limit, limit * (currentPage - 1))
      .then((res) => {
        const { data } = res;
        const { payload } = data;
        setUnitList(payload.unitList);
        setUnitCount(payload.count);
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage]);

  if (isLoading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteId !== undefined}
        title='Delete unit'
        content='Are you sure you want to delete this unit?'
        disabled={loading}
        onDeleteClick={() => {
          handleDelete();
          return;
        }}
        onCloseClick={() => setDeleteId(undefined)}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Unit Management' />
        </Container>
        <Row className='mb-4'>
          <div className='text-end'>
            <Button
              type='button'
              color='primary'
              className='ms-1 btn mt-2'
              onClick={() => navigate('/unit-management/create')}
            >
              Create new Unit
            </Button>
          </div>
        </Row>
        <Row>
          {unitList.map((unit: Unit) => {
            return <UnitCard key={unit._id} unit={unit} setDeleteUnitId={setDeleteId} />;
          })}
        </Row>
        <Pagination
          count={unitCount}
          pageSize={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </React.Fragment>
  );
};

export default UnitManagement;
