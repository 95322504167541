import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, Unit, UnitType } from 'types';

const UnitService = {
  getUnitList: async (limit: number, offset = 0) => {
    return axios.get<
      ResponseData<{
        count: number;
        unitList: Unit[];
      }>
    >(`${API_URL}admin/unit?limit=${limit}&offset=${offset}`);
  },
  getUnitById: async (id: string) => {
    return axios.get<ResponseData<Unit>>(`${API_URL}admin/unit/${id}`);
  },
  createUnit: async (
    name: string,
    description: string,
    type: UnitType,
    contactEmail: string,
    contactPhone: string
  ) => {
    return axios.post<ResponseData<Unit>>(`${API_URL}admin/unit`, {
      name,
      description,
      type,
      contactEmail,
      contactPhone,
    });
  },
  editUnit: async (
    id: string,
    name: string,
    description: string,
    type: UnitType,
    users: (string | undefined)[],
    contactEmail: string,
    contactPhone: string
  ) => {
    return axios.patch<ResponseData<Unit>>(`${API_URL}admin/unit/${id}`, {
      name,
      description,
      type,
      users,
      contactEmail,
      contactPhone,
    });
  },
  deleteUnit: async (id: string) => {
    return axios.delete<ResponseData<Unit>>(`${API_URL}admin/unit/${id}`);
  },
};

export default UnitService;
