import { GoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap';
import * as Yup from 'yup';

import withRouter from 'Components/Common/withRouter';
import { handleAxiosError } from 'helpers/handleError';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useTitle from 'hooks/useTitle';
import AuthService from 'services/auth.service';
import { RootState } from 'slices';
import { getProfile } from 'slices/auth/login/thunk';

import groupDiscussionAnimation from '../../assets/animations/group-discussion.json';
import { ReactComponent as LoginDecorations } from '../../assets/svg/login-decos.svg';
import { ReactComponent as LogoBK } from '../../assets/svg/logobk.svg';

const initialForm = {
  username: '',
  password: '',
};

const Login = () => {
  useTitle('Đăng nhập', {
    restoreOnUnmount: true,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: groupDiscussionAnimation,
  };

  const [show, setShow] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({ token: '' });

  const { token: queryToken } = Object.fromEntries(searchParams);

  const navigate = useNavigate();

  const { isAuthenticated } = useAppSelector((state: RootState) => state.Login);

  const dispatch = useAppDispatch();

  const loginGoogle = (idToken: string) => {
    AuthService.loginWithGoogle(idToken)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res.data.payload));
        dispatch(getProfile());
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      });
  };

  const login = async (username: string, password: string) => {
    try {
      const res = await AuthService.login(username, password);
      localStorage.setItem('token', JSON.stringify(res.data.payload));
      dispatch(getProfile());
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const validation = useFormik({
    initialValues: initialForm,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      username: Yup.string().required('Please enter your username'),
      password: Yup.string().required('Please enter your password'),
    }),
    onSubmit: (values) => {
      login(values.username, values.password);
    },
  });

  useEffect(() => {
    if (
      queryToken &&
      queryToken !== '' &&
      window.location.pathname.split('/')[1] === 'login' &&
      window.location.pathname.split('/').length === 2
    ) {
      localStorage.setItem('token', JSON.stringify(queryToken));
      setSearchParams({ token: '' });
    }
  }, [queryToken, setSearchParams]);

  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard');
  }, [isAuthenticated, navigate]);

  return (
    <React.Fragment>
      <div className='tw-h-full tw-w-screen tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-flex-row lg:tw-gap-[7.5rem] xl:tw-gap-[15rem] 2xl:tw-gap-[20rem]'>
        <div className='tw-hidden tw-max-w-[20rem] lg:tw-flex tw-justify-center tw-items-center tw-max-h-[17.5rem] 2xl:tw-max-w-[25rem] 2xl:tw-h--[22.5rem]'>
          <LoginDecorations className='tw-absolute tw-w-[30rem] tw-h-[25rem]' />
          <Lottie
            options={defaultOptions}
            isStopped={false}
            isPaused={false}
            isClickToPauseDisabled
          />
        </div>
        <Row className='tw-w-[20rem] tw-text-[0.75rem] tw-flex tw-flex-col tw-items-center xs:tw-w-[25rem] xl:tw-w-[30rem] '>
          <Col>
            <div className='tw-overflow-hidden !tw-bg-transparent tw-px-0 xs:tw-px-5 tw-mb-0'>
              <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[1.25rem]'>
                <LogoBK height={80} />
                <h1 className='tw-mt-[1rem] tw-content-center tw-mb-0 xs:tw-mt-[1.5rem] tw-text-[1.5rem] tw-font-bold tw-text-primary'>
                  OISP UNION APP
                </h1>
                <p className='tw-text-[0.75rem] tw-text-primary tw-my-2'>
                  Student information management app
                </p>
                <div className=''></div>
              </div>
              <div className=' '>
                <div className='auth-logo'></div>
                <div className='tw-p-2'>
                  <Form
                    className='form-horizontal '
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className='mb-3'>
                      {/* {error ? <Alert color='danger'>{error}</Alert> : null} */}
                      <Input
                        name='username'
                        className='form-control register'
                        placeholder='Enter username'
                        type='text'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ''}
                        invalid={
                          validation.touched.username && validation.errors.username ? true : false
                        }
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type='invalid'>{validation.errors.username}</FormFeedback>
                      ) : null}
                    </div>

                    <div className='mb-3'>
                      <div className='input-group auth-pass-inputgroup'>
                        <Input
                          name='password'
                          className='form-control register'
                          placeholder='Enter password'
                          type={show ? 'text' : 'password'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />

                        <button
                          onClick={() => setShow(!show)}
                          className='btn btn-light '
                          type='button'
                          id='password-addon'
                        >
                          <i className='mdi mdi-eye-outline'></i>
                        </button>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type='invalid'>{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className='mt-3 d-grid'>
                      <button className='btn btn-primary btn-block ' type='submit'>
                        Log In
                      </button>
                    </div>

                    <div className='tw-mt-2 tw-text-center'>
                      <Link to='/restore-password' className='tw-text-primary tw-text-[0.75rem]'>
                        <i className='mdi mdi-lock me-1 ' /> Forgot your password?
                      </Link>
                    </div>

                    <div className='tw-mt-[1rem] tw-text-center tw-flex tw-flex-col tw-items-center'>
                      <div className='tw-text-[0.75rem]'>or</div>
                      <div className='tw-mt-[1rem] tw-flex tw-w-[15rem] tw-flex-col tw-items-center md:tw-w-[20rem]'>
                        <GoogleLogin
                          size='large'
                          onSuccess={(response) => {
                            if (response.credential) {
                              loginGoogle(response.credential);
                            }
                          }}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className='mt-3 text-center tw-text-[0.75rem]'>
              <p>
                Don&apos;t have an account ?{' '}
                <Link to='/register' className='tw-font-bold tw-text-primary'>
                  {' '}
                  Sign up now{' '}
                </Link>{' '}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
