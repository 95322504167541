import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { CertParameter, Certificate, CertificateTemplate } from 'types';

import { CertificateContainer } from './CertificateContainer';

type EditParamModalProps = {
  certificate: Certificate | null;
  certificateTemlate: CertificateTemplate;
  show: boolean;
  onSubmit: (certId: string, params: CertParameter[], email: string) => Promise<void>;
  onCloseClick: () => void;
};

const EditParamModal = ({
  certificate,
  certificateTemlate,
  show,
  onSubmit,
  onCloseClick,
}: EditParamModalProps) => {
  if (!certificate) {
    return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true} size='lg'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Edit information</h5>
          <button
            type='button'
            onClick={onCloseClick}
            className='btn-close position-absolute end-0 top-0 m-3'
          />
        </div>
        <div className='modal-content '>
          <ModalBody className='px-4 py-4'>
            <div className='tw-m-auto'>No info</div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='xl'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Edit information</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 tw-flex tw-flex-col lg:tw-flex-row tw-items-start'>
          <CertificateContainer
            canvasId='editCertificateCanvas'
            templateBackground={certificateTemlate.backgroundUrl}
            certificateTemplate={certificateTemlate}
            displayCertificate={certificate}
            editMode={true}
            buttonOptions={{
              copyButton: false,
              viewButton: false,
              downloadButton: false,
            }}
            updateParam={onSubmit}
          />
        </ModalBody>
      </div>
    </Modal>
  );
};

export default EditParamModal;
