import SystemPicture from 'assets/images/gearshape.fill.png';
import { User } from 'types';

const systemUser: User = {
  _id: 'system_user',
  name: 'System',
  googleId: 'system_user',
  picture: SystemPicture,
  permissions: [],
  isManager: true,
  email: 'admin@fessior.com',
};

export default systemUser;
