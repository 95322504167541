import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import DeleteModal from 'Components/Common/DeleteModal';
import Loading from 'Components/Common/LoadingIndicator';
import TableContainer from 'Components/Common/TableContainer';
import { PermissionDescription } from 'config';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import AccessLevelService from 'services/access-levels.service';
import { AccessLevel, Permission } from 'types';

type AccessLevelType = {
  name?: string;
  description?: string;
  permissions?: Permission[];
};

const addOrRemove = <T,>(_array: Array<T>, value: T) => {
  const array: T[] = _.cloneDeep(_array);
  const index: number = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }

  return array;
};

const AccessLevelPage = () => {
  useTitle('Access Level', {
    restoreOnUnmount: true,
  });

  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [accessLevels, setAccessLevels] = useState<AccessLevel[]>([]);
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addInfo, setAddInfo] = useState<AccessLevelType>({});
  //state for manage fetching process
  const [isLoading, setIsLoading] = useState(true);
  const handleDelete = async () => {
    try {
      setLoading(true);
      await AccessLevelService.deleteById(deleteId || '');
      await fetchAccessLevels();
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
      setDeleteId(undefined);
    }
  };

  const handleAddAccessLevels = async () => {
    try {
      setLoading(true);
      await AccessLevelService.addAccessLevel(addInfo);
      setAddModal(false);
      await fetchAccessLevels();
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const fetchAccessLevels = async () => {
    try {
      const { data } = await AccessLevelService.getAllAccessLevels();
      const { payload } = data;
      setAccessLevels(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAccessLevels();
  }, []);

  const columns = useMemo<ColumnDef<AccessLevel, keyof AccessLevel>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          const createdAt = new Date(cellProps.getValue()).toLocaleString('vi-VN');
          return <div>{createdAt}</div>;
        },
      },
      {
        header: 'Created By',
        accessorKey: 'predefinedId',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue() ? 'System' : 'User'}</div>;
        },
      },
      {
        header: 'Action',
        accessorKey: '_id',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link to={`/access-levels/${cellProps.getValue() || ''}`} className='text-success'>
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to='#'
                onClick={() => setDeleteId(cellProps.getValue())}
                className='text-danger'
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  if (isLoading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteId !== undefined}
        title='Delete Access Level'
        content='Are you sure you want to delete this access level?'
        disabled={loading}
        onDeleteClick={() => {
          handleDelete();
          return;
        }}
        onCloseClick={() => setDeleteId(undefined)}
      />
      <Modal isOpen={addModal} toggle={() => setAddModal(!addModal)} centered>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>New Access Level</h5>
          <button
            type='button'
            onClick={() => setAddModal(!addModal)}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <Row>
            <Col xs={12}>
              <div className='mb-3'>
                <Label>Name</Label>
                <Input
                  name='name'
                  type='text'
                  placeholder='Admin, Academic Department, ...'
                  value={addInfo?.name || ''}
                  onChange={(e) => setAddInfo({ ...addInfo, name: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <Label>Description</Label>
                <Input
                  tag='textarea'
                  rows={3}
                  placeholder='Enter your description'
                  name='description'
                  value={addInfo?.description || ''}
                  onChange={(e) => setAddInfo({ ...addInfo, description: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <Label>Permission List</Label>
                <div className='control-group'>
                  {Object.keys(Permission)?.map((permission) => {
                    const value = addInfo?.permissions?.includes(permission as Permission);

                    return (
                      <div className='form-check mb-2' key={permission}>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id={permission}
                          checked={value || false}
                          onChange={() =>
                            setAddInfo({
                              ...addInfo,
                              permissions: addOrRemove(
                                addInfo?.permissions || [],
                                permission as Permission
                              ),
                            })
                          }
                        />
                        <Label className='form-check-label' htmlFor={permission}>
                          {PermissionDescription[permission]}
                        </Label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setAddModal(false);
              setAddInfo({});
            }}
          >
            Close
          </button>
          <button
            onClick={() => {
              handleAddAccessLevels();
              return;
            }}
            type='button'
            className='btn btn-primary'
          >
            Save
          </button>
        </div>
      </Modal>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Access Level' />
        </Container>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Row className='mb-2'>
                  <Col>
                    <div className='text-sm'>
                      <Button
                        type='button'
                        color='success'
                        className='btn-rounded  mb-2 me-2'
                        disabled={loading}
                        onClick={() => setAddModal(true)}
                      >
                        <i className='mdi mdi-plus me-1' />
                        New Access Level
                      </Button>
                    </div>
                  </Col>

                  <TableContainer
                    columns={columns}
                    tableClass='table align-middle table-nowrap'
                    theadClass=''
                    data={accessLevels}
                    pageSize={accessLevels.length}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AccessLevelPage;
