import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
} from 'reactstrap';
import * as yup from 'yup';

import ManagerKeyModal from 'Components/Common/ManagerKeyModal';
import { STUDENT_ENCRYPTED_FIELDS } from 'config';
import useTitle from 'hooks/useTitle';
import StudentService from 'services/student.service';
import { CountryCode, Gender, Student } from 'types';

import Breadcrumbs from '../../Components/Common/Breadcrumb';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_blue.css';
// eslint-disable-next-line import/order
import { handleAxiosError } from 'helpers/handleError';

const initialValue: Student = {
  // Thông tin chung
  familyName: '', // Họ
  firstName: '', // Tên
  phone: '', // Số điện thoại
  email: '', // Email HCMUT
  studentId: '', // Mã sinh viên
  gender: Gender.MALE, // Giới tính
  hometown: '', // Quê quán
  ethnic: '', // Dân tộc
  address: '', // Địa chỉ thường trú
  religion: '', // Tôn giáo
  job: '', // Nghề nghiệp
  citizenShip: {
    citizenId: '', // Số CMND
    citizenIdIssueDate: 0, // Ngày cấp CMND
    citizenIdIssuePlace: '', // Nơi cấp CMND
    nation: CountryCode.Vietnam, // Quốc tịch
  },
  union: {
    memberId: '', // Mã định danh đoàn viên
    issuedDate: 0, // Thời gian vào đoàn
    resolutionNumber: '', // Số Nghị quyết chuẩn y kết nạp đoàn viên
    issuedPlace: '', // Nơi ký
    cardNumber: '', // Số thẻ đoàn viên
    membership: '', // Đối tượng đoàn viên
    practice: '', // Rèn luyện đoàn viên
    qualification: '', // Đánh giá, xếp loại đoàn viên
    status: '', // (Chức vụ) Tình trạng đoàn viên
  },
  createdAt: 0,
};

const EditStudent = () => {
  const { id } = useParams();
  const [student, setStudent] = React.useState<Student>(initialValue);
  const [encrypted, setEncrypted] = React.useState<string[]>(STUDENT_ENCRYPTED_FIELDS);

  // Manager key and modal for decryption
  const [decryptKeyModal, setDecryptKeyModal] = React.useState<boolean>(false);
  const [decryptKey, setDecryptKey] = React.useState<string | undefined>();
  // Manager key and modal for update
  const [managerKeyModal, setManagerKeyModal] = React.useState<boolean>(false);
  const [managerKey, setManagerKey] = React.useState<string | undefined>();
  const [decryptField, setDecryptField] = React.useState<keyof Student>();

  useTitle((student.familyName || '') + ' ' + (student.firstName || ''), {
    restoreOnUnmount: true,
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchStudent = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await StudentService.getStudent(id || '');
      const { payload } = data;
      setStudent(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id]);

  const updateStudent = async (data: Partial<Student>) => {
    try {
      setLoading(true);
      await StudentService.editStudent(student?._id || '', data, managerKey);
      await fetchStudent();
      setEncrypted(STUDENT_ENCRYPTED_FIELDS);
      toast.success('Chỉnh sửa sinh viên thành công');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const decrypt = async (field: keyof Student, key: string) => {
    try {
      if (student) {
        const { data } = await StudentService.decrypt(_.toString(student[field]), key);
        const { payload } = data;
        if (student) setStudent({ ...student, [field]: payload });
        setEncrypted(encrypted.filter((item) => item !== field));
      } else throw new Error('Không tìm thấy sinh viên');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const handleDecryptClick = (field: keyof Student) => {
    setDecryptKeyModal(true);
    setDecryptField(field);
  };

  const handleDecrypt = () => {
    if (decryptField && decryptKey) {
      setDecryptKeyModal(false);
      decrypt(decryptField, decryptKey).catch(() => {});
      setDecryptKey('');
    } else {
      toast.error('Không thể giải mã. Kiểm tra lại đoạn mã của bạn.');
    }
  };

  useEffect(() => {
    fetchStudent().catch(() => {});
  }, [fetchStudent]);

  const formik = useFormik<Student>({
    initialValues: student,
    enableReinitialize: true,
    isInitialValid: true,
    validationSchema: yup.object().shape({
      familyName: yup.string(),
      firstName: yup.string(),
      email: yup.string().email('Email không hợp lệ'),
      studentId: yup.string(),
      phone: yup.string(), // Encrypted
      gender: yup.mixed<Gender>().oneOf(Object.values(Gender)),
      citizenShip: yup.object().shape({
        citizenId: yup.string(),
        citizenIdIssueDate: yup.number(),
        citizenIdIssuePlace: yup.string(),
        nation: yup.string(),
      }),
      union: yup.object().shape({
        memberId: yup.string(),
        issuedDate: yup.number(),
        resolutionNumber: yup.string(),
        issuedPlace: yup.string(),
        cardNumber: yup.string(),
      }),
    }),
    onSubmit: async (values) => {
      const editedValues = _.omitBy(
        values,
        (value, key) => student[key as keyof Student]?.valueOf() === value?.valueOf()
      );

      if (encrypted.length !== STUDENT_ENCRYPTED_FIELDS.length && !managerKey) {
        setManagerKeyModal(true);
      } else {
        await updateStudent(editedValues);
        setManagerKeyModal(false);
      }
    },
  });

  return (
    <React.Fragment>
      {/* Modal for decryption */}
      <ManagerKeyModal
        value={decryptKey || ''}
        onChange={(e) => setDecryptKey(e.target.value)}
        show={decryptKeyModal}
        onCloseClick={() => {
          setDecryptKeyModal(false);
          setDecryptKey('');
        }}
        onSubmit={handleDecrypt}
      />
      {/* Modal for updating sensitive student information */}
      <ManagerKeyModal
        value={managerKey || ''}
        onChange={(e) => setManagerKey(e.target.value)}
        show={managerKeyModal}
        onCloseClick={() => {
          setManagerKeyModal(false);
          setManagerKey('');
        }}
        onSubmit={formik.handleSubmit}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Thông tin sinh viên'
            breadcrumbItem={document.title}
            backTo='/student-info'
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Thông tin cơ bản</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Họ
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='familyName'
                        className='form-control'
                        placeholder=' Nguyễn'
                        type='text'
                        autoComplete='on'
                        value={formik.values.familyName}
                        onChange={formik.handleChange}
                        invalid={formik.touched.familyName && !!formik.errors.familyName}
                      />
                      {formik.touched.familyName && formik.errors.familyName ? (
                        <FormFeedback type='invalid'>{formik.errors.familyName}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Tên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='firstName'
                        className='form-control'
                        placeholder=' Văn A'
                        type='text'
                        autoComplete='on'
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        invalid={formik.touched.firstName && !!formik.errors.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <FormFeedback type='invalid'>{formik.errors.firstName}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Email
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='email'
                        className='form-control'
                        placeholder=' ten.ho@hcmut.edu.vn'
                        type='email'
                        autoComplete='on'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        invalid={formik.touched.email && !!formik.errors.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <FormFeedback type='invalid'>{formik.errors.email}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Mã số sinh viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='studentId'
                        className='form-control'
                        placeholder=' 2350001'
                        type='text'
                        autoComplete='on'
                        value={formik.values.studentId}
                        onChange={formik.handleChange}
                        invalid={formik.touched.studentId && !!formik.errors.studentId}
                      />
                      {formik.touched.studentId && formik.errors.studentId ? (
                        <FormFeedback type='invalid'>{formik.errors.studentId}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số điện thoại
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='phone'
                        className='form-control'
                        placeholder=' 0987654321'
                        type={encrypted.includes('phone') ? 'password' : 'text'}
                        autoComplete='on'
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        invalid={formik.touched.phone && !!formik.errors.phone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <FormFeedback type='invalid'>{formik.errors.phone}</FormFeedback>
                      ) : null}
                      {encrypted.includes('phone') && (
                        <div
                          role='button'
                          onClick={() => handleDecryptClick('phone')}
                          className='absolute position-absolute top-0'
                          style={{ right: 24 }}
                        >
                          <i className='mdi mdi-eye font-size-24'></i>
                        </div>
                      )}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Ngày sinh
                    </label>
                    <div className='col-md-10'>
                      <Flatpickr
                        name='dob'
                        className='form-control'
                        value={formik.values.dob || ''}
                        onChange={([date]) => {
                          formik.setFieldValue('dob', date);
                        }}
                        options={{
                          mode: 'single',
                          altFormat: 'd/m/Y',
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      {formik.touched.dob && formik.errors.dob ? (
                        <FormFeedback type='invalid'>{formik.errors.dob}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Giới tính
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='gender'
                        type='select'
                        className='form-select'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.gender || ''}
                        invalid={formik.touched.gender && formik.errors.gender ? true : false}
                      >
                        <option>{Gender.FEMALE}</option>
                        <option>{Gender.MALE}</option>
                        <option>{Gender.OTHER}</option>
                      </Input>
                      {formik.touched.gender && formik.errors.gender ? (
                        <FormFeedback type='invalid'>{formik.errors.gender}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Quê quán
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='hometown'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.hometown}
                        onChange={formik.handleChange}
                        invalid={formik.touched.hometown && !!formik.errors.hometown}
                      />
                      {formik.touched.hometown && formik.errors.hometown ? (
                        <FormFeedback type='invalid'>{formik.errors.hometown}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Dân tộc
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='ethnic'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.ethnic}
                        onChange={formik.handleChange}
                        invalid={formik.touched.ethnic && !!formik.errors.ethnic}
                      />
                      {formik.touched.ethnic && formik.errors.ethnic ? (
                        <FormFeedback type='invalid'>{formik.errors.ethnic}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Địa chỉ thường trú
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='address'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        invalid={formik.touched.address && !!formik.errors.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <FormFeedback type='invalid'>{formik.errors.address}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Tôn giáo
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='religion'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.religion}
                        onChange={formik.handleChange}
                        invalid={formik.touched.religion && !!formik.errors.religion}
                      />
                      {formik.touched.religion && formik.errors.religion ? (
                        <FormFeedback type='invalid'>{formik.errors.religion}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Nghề nghiệp
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='job'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.job}
                        onChange={formik.handleChange}
                        invalid={formik.touched.job && !!formik.errors.job}
                      />
                      {formik.touched.job && formik.errors.job ? (
                        <FormFeedback type='invalid'>{formik.errors.job}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Thông tin công dân</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số CMND
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='citizenShip.citizenId'
                        className='form-control'
                        placeholder='XXXXXXXXXXXX'
                        type='text'
                        autoComplete='on'
                        value={formik.values.citizenShip?.citizenId}
                        onChange={formik.handleChange}
                        invalid={
                          formik?.touched.citizenShip?.citizenId &&
                          !!formik?.errors.citizenShip?.citizenId
                        }
                      />
                      {formik?.touched.citizenShip?.citizenId &&
                      !!formik?.errors.citizenShip?.citizenId ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.citizenShip?.citizenId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Ngày cấp CMND
                    </label>
                    <div className='col-md-10'>
                      <Flatpickr
                        name='citizenShip.citizenIdIssueDate'
                        className='form-control'
                        value={formik.values.citizenShip?.citizenIdIssueDate || ''}
                        onChange={([date]) => {
                          formik.setFieldValue('citizenShip.citizenIdIssueDate', date.getTime());
                          return;
                        }}
                        options={{
                          mode: 'single',
                          altFormat: 'd/m/Y',
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      {formik.touched.citizenShip?.citizenIdIssueDate &&
                      !!formik.errors.citizenShip?.citizenIdIssueDate ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.citizenShip?.citizenIdIssueDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Nơi cấp CMND
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='citizenShip.citizenIdIssuePlace'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.citizenShip?.citizenIdIssuePlace}
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched.citizenShip?.citizenIdIssuePlace &&
                          !!formik.errors.citizenShip?.citizenIdIssuePlace
                        }
                      />
                      {formik.touched.citizenShip?.citizenIdIssuePlace &&
                      !!formik.errors.citizenShip?.citizenIdIssuePlace ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.citizenShip?.citizenIdIssuePlace}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Quốc tịch
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='citizenShip.nation'
                        type='select'
                        className='form-select'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.citizenShip?.nation || ''}
                        invalid={
                          formik.touched.citizenShip?.nation && !!formik.errors.citizenShip?.nation
                        }
                      >
                        {_.keys(CountryCode).map((key) => (
                          <option key={key} value={_.toString(CountryCode[key])}>
                            {key}
                          </option>
                        ))}
                      </Input>
                      {formik.touched.citizenShip?.nation && !!formik.errors.citizenShip?.nation ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.citizenShip?.nation}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Trình độ học vấn</CardTitle>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ văn hoá
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.academic'
                        className='form-control'
                        placeholder=' Hệ 12 / 12'
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.academic}
                        onChange={formik.handleChange}
                        invalid={
                          _.get(formik.touched, 'standards.academic') &&
                          !!_.get(formik.errors, 'standards.academic')
                        }
                      />
                      {_.get(formik.touched, 'standards.academic') &&
                      !!_.get(formik.errors, 'standards.academic') ? (
                        <FormFeedback type='invalid'>
                          {_.get(formik.touched, 'standards.academic')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ chuyên môn
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.professional'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.professional}
                        onChange={formik.handleChange}
                        invalid={
                          _.get(formik.touched, 'standards.professional') &&
                          _.get(formik.errors, 'standards.professional')
                        }
                      />
                      {formik.touched['standards.professional'] &&
                      !!formik.errors['standards.professional'] ? (
                        <FormFeedback type='invalid'>
                          {formik.errors['standards.professional']}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ lý luận chính trị
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.politicalTheory'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.politicalTheory}
                        onChange={formik.handleChange}
                        invalid={
                          _.get(formik.touched, 'standards.politicalTheory') &&
                          !!_.get(formik.errors, 'standards.politicalTheory')
                        }
                      />
                      {_.get(formik.touched, 'standards.politicalTheory') &&
                      !!_.get(formik.errors, 'standards.politicalTheory') ? (
                        <FormFeedback type='invalid'>
                          {_.get(formik.touched, 'standards.politicalTheory')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ tin học
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.computer'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.computer}
                        onChange={formik.handleChange}
                        invalid={
                          _.get(formik.touched, 'standards.computer') &&
                          !!_.get(formik.errors, 'standards.computer')
                        }
                      />
                      {_.get(formik.touched, 'standards.computer') &&
                      !!_.get(formik.errors, 'standards.computer') ? (
                        <FormFeedback type='invalid'>
                          {_.get(formik.touched, 'standards.computer')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Trình độ ngoại ngữ
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='standards.foreignLanguage'
                        className='form-control'
                        placeholder=''
                        type='text'
                        autoComplete='on'
                        value={formik.values.standards?.foreignLanguage}
                        onChange={formik.handleChange}
                        invalid={
                          _.get(formik.touched, 'standards.foreignLanguage') &&
                          !!_.get(formik.errors, 'standards.foreignLanguage')
                        }
                      />
                      {_.get(formik.touched, 'standards.foreignLanguage') &&
                      !!_.get(formik.errors, 'standards.foreignLanguage') ? (
                        <FormFeedback type='invalid'>
                          {_.get(formik.touched, 'standards.foreignLanguage')}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4'>Thông tin Đoàn hội</CardTitle>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Mã định danh
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.memberId'
                        className='form-control'
                        placeholder=' 613000'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.memberId}
                        onChange={formik.handleChange}
                        invalid={formik.touched.union?.memberId && !!formik.errors.union?.memberId}
                      />
                      {formik.touched.union?.memberId && !!formik.errors.union?.memberId ? (
                        <FormFeedback type='invalid'>{formik.errors.union?.memberId}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Ngày vào đoàn
                    </label>
                    <div className='col-md-10'>
                      <Flatpickr
                        name='union.issuedDate'
                        className='form-control'
                        value={formik.values.union?.issuedDate || ''}
                        onChange={([date]) => {
                          formik.setFieldValue('union.issuedDate', date.getTime());
                        }}
                        options={{
                          mode: 'single',
                          altFormat: 'd/m/Y',
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      {formik.touched.union?.issuedDate && !!formik.errors.union?.issuedDate ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.union?.issuedDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số Nghị quyết chuẩn y kết nạp
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.resolutionNumber'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.resolutionNumber}
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched.union?.resolutionNumber &&
                          !!formik.errors.union?.resolutionNumber
                        }
                      />
                      {formik.touched.union?.resolutionNumber &&
                      !!formik.errors.union?.resolutionNumber ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.union?.resolutionNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Nơi ký
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.issuedPlace'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.issuedPlace}
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched.union?.issuedPlace && !!formik.errors.union?.issuedPlace
                        }
                      />
                      {formik.touched.union?.issuedPlace && !!formik.errors.union?.issuedPlace ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.union?.issuedPlace}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Số thẻ đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.cardNumber'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.cardNumber}
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched.union?.cardNumber && !!formik.errors.union?.cardNumber
                        }
                      />
                      {formik.touched.union?.cardNumber && !!formik.errors.union?.cardNumber ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.union?.cardNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Đối tượng đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.membership'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.membership}
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched.union?.membership && !!formik.errors.union?.membership
                        }
                      />
                      {formik.touched.union?.membership && !!formik.errors.union?.membership ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.union?.membership}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Rèn luyện đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.practice'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.practice}
                        onChange={formik.handleChange}
                        invalid={formik.touched.union?.practice && !!formik.errors.union?.practice}
                      />
                      {formik.touched.union?.practice && !!formik.errors.union?.practice ? (
                        <FormFeedback type='invalid'>{formik.errors.union?.practice}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Đánh giá, xếp loại đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.qualification'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.qualification}
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched.union?.qualification &&
                          !!formik.errors.union?.qualification
                        }
                      />
                      {formik.touched.union?.qualification &&
                      !!formik.errors.union?.qualification ? (
                        <FormFeedback type='invalid'>
                          {formik.errors.union?.qualification}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Tình trạng đoàn viên
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.status'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.status}
                        onChange={formik.handleChange}
                        invalid={formik.touched.union?.status && !!formik.errors.union?.status}
                      />
                      {formik.touched.union?.status && !!formik.errors.union?.status ? (
                        <FormFeedback type='invalid'>{formik.errors.union?.status}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Hội
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.group'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.group}
                        onChange={formik.handleChange}
                        invalid={formik.touched.union?.group && !!formik.errors.union?.group}
                      />
                      {formik.touched.union?.group && !!formik.errors.union?.group ? (
                        <FormFeedback type='invalid'>{formik.errors.union?.group}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Khen thưởng
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.rewards'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.rewards}
                        onChange={formik.handleChange}
                        invalid={formik.touched.union?.rewards && !!formik.errors.union?.rewards}
                      />
                      {formik.touched.union?.rewards && !!formik.errors.union?.rewards ? (
                        <FormFeedback type='invalid'>{formik.errors.union?.rewards}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Khen thưởng
                    </label>
                    <div className='col-md-10'>Chưa cập nhật</div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Kỷ luật
                    </label>
                    <div className='col-md-10'>Chưa cập nhật</div>
                  </Row>

                  <Row className='mb-3'>
                    <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                      Đơn vị
                    </label>
                    <div className='col-md-10'>
                      <Input
                        name='union.unit'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        value={formik.values.union?.unit}
                        onChange={formik.handleChange}
                        invalid={formik.touched.union?.unit && !!formik.errors.union?.unit}
                      />
                      {formik.touched.union?.unit && !!formik.errors.union?.unit ? (
                        <FormFeedback type='invalid'>{formik.errors.union?.unit}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Button
            color='primary'
            className='btn btn-primary waves-effect waves-light mb-4'
            disabled={loading}
            onClick={() => formik.handleSubmit()}
          >
            Lưu
          </Button>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditStudent;
