import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  CardText,
  Button,
  Form,
  FormFeedback,
} from 'reactstrap';
import * as yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import ContinueModal from 'Components/Common/ContinueModal';
import Loading from 'Components/Common/LoadingIndicator';
import withRouter from 'Components/Common/withRouter';
import { handleAxiosError } from 'helpers/handleError';
import { toStartCase } from 'helpers/translate';
import useAppSelector from 'hooks/useAppSelector';
import useTitle from 'hooks/useTitle';
import AuthService from 'services/auth.service';
import { RootState } from 'slices';
import { ResponseData } from 'types';

type ChangePasswordType = {
  oldSystemKey: string;
  newSystemKey: string;
  retypeNewSystemKey: string;
};

const UserProfile = () => {
  useTitle('My Account', {
    restoreOnUnmount: true,
  });

  const [showModal, setShowModal] = React.useState(false);

  const { user, loading } = useAppSelector((state: RootState) => state.Login);

  //state to manage sending email process
  const [isSending, setIsSending] = React.useState(false);
  const navigate = useNavigate();

  const handleChangePassword = async (values: ChangePasswordType) => {
    try {
      await AuthService.changeSystemKey(values.oldSystemKey, values.newSystemKey);
      toast.success('Change password successfully!');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const formik = useFormik<ChangePasswordType>({
    initialValues: {
      oldSystemKey: '',
      newSystemKey: '',
      retypeNewSystemKey: '',
    },
    validationSchema: yup.object().shape({
      oldSystemKey: yup.string().required('Old password is required'),
      newSystemKey: yup.string().required('New password is required'),
      retypeNewSystemKey: yup
        .string()
        .required('Retype password is required')
        .oneOf([yup.ref('newSystemKey'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      handleChangePassword(values);
      formik.resetForm();
    },
  });

  const forgotPassword = (email: string) => {
    setIsSending(true);
    AuthService.forgotPassword(email)
      .then(() => {
        toast.success('email sent successfully! Please follows instruction.');
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          const resData = error?.response?.data as ResponseData;
          toast.error(resData.message);
        } else console.log(error);
      })
      .finally(() => {
        setIsSending(false);
        setShowModal(!showModal);
        navigate('/logout');
      });
  };

  console.log('user', user);
  console.log('loading: ', loading);
  if (loading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <ContinueModal
        isOpen={showModal}
        isSending={isSending}
        onCloseClick={() => {
          setShowModal(!showModal);
        }}
        onContinueClick={() => {
          forgotPassword(user?.email || '');
        }}
        title={'Change password'}
        content={'Confirm that you want to change your password'}
      />
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title='Application' breadcrumbItem='My Account' />

          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <div className='d-flex justify-content-between'>
                    <div className='ms-3'>
                      <img
                        src={user?.picture}
                        alt=''
                        className='avatar-md rounded-circle img-thumbnail'
                      />
                    </div>
                    <div className='ms-4 flex-grow-1 align-self-center'>
                      <div className='text-muted'>
                        <h5>{user?.name}</h5>
                        <p className='mb-1'>{user?.email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className='card-title mb-4'>Personal Information</h4>

          <Card>
            <CardBody>
              <div className='form-group mb-3'>
                <Label className='form-label'>Name</Label>
                {/* <Input
                  className="form-control"
                  placeholder=" Trần Văn A"
                  type="text"
                  value={user?.name}
                  onChange={() => {}}
                /> */}
                <p className='form-control'>{user?.name}</p>
              </div>
              <div className='form-group mb-3'>
                <Label className='form-label'>Email</Label>
                {/* <Input
                  name='username'
                  className='form-control'
                  placeholder=' a@hcmut.edu.vn'
                  type='text'
                  value={user?.email}
                  onChange={() => {}}
                  disabled
                /> */}
                <p className='form-control'>{user?.email}</p>
              </div>
              <div className='form-group'>
                <Label className='form-label'>Permissions</Label>
                {/* <Input
                  name="username"
                  className="form-control"
                  placeholder=" a@hcmut.edu.vn"
                  type="text"
                  value={
                    user?.isManager
                      ? "Quản lý"
                      : (user?.accessLevels as AccessLevel[])
                          ?.map((accessLevel) => accessLevel.name)
                          .join(", ")
                  }
                  onChange={() => {}}
                /> */}
                {!user?.permissions?.length ? (
                  <div id='noresult'>
                    <div className='text-center py-4'>
                      <div className='avatar-md mx-auto mb-4'>
                        <div className='avatar-title bg-light text-primary rounded-circle fs-4xl'>
                          <i
                            className='mdi mdi-book-search text-white'
                            style={{ fontSize: '24px' }}
                          ></i>
                        </div>
                      </div>
                      <h5 className='mt-2'>Empty</h5>
                    </div>
                  </div>
                ) : (
                  <p className='form-control'>
                    {user?.isManager
                      ? 'Manager'
                      : user?.permissions?.map((permission) => toStartCase(permission)).join(', ')}
                  </p>
                )}
              </div>
            </CardBody>
          </Card>

          <Card outline color='primary' className='border'>
            <CardHeader className='bg-transparent'>
              <h5 className='my-0 text-primary'>Change password</h5>
            </CardHeader>
            <CardBody>
              <CardText>
                An email will be send to your email address to reset your password. Please check and
                follows the instruction.
              </CardText>

              <Button
                color='primary'
                className='btn btn-primary waves-effect waves-light mt-3'
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                Send email
              </Button>
            </CardBody>
          </Card>

          {user?.isManager && (
            <Card outline color='primary' className='border'>
              <CardHeader className='bg-transparent'>
                <h5 className='my-0 text-primary'>Change Manager Key</h5>
              </CardHeader>
              <CardBody>
                <CardText>
                  Manager Key (or System Key) is used to encrypt student information. When changing
                  the Manager Key, the system will re-encrypt ALL sensitive student information. We
                  encourage you NOT to change the Manager Key often.
                </CardText>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}
                >
                  <div className='form-group'>
                    <Label className='form-label'>Old Manager Key</Label>
                    <Input
                      name='oldSystemKey'
                      className='form-control'
                      placeholder='Enter your old manager key'
                      type='password'
                      autoComplete='on'
                      value={formik.values.oldSystemKey}
                      onChange={formik.handleChange}
                      invalid={formik.touched.oldSystemKey && !!formik.errors.oldSystemKey}
                    />
                    {formik.touched.oldSystemKey && formik.errors.oldSystemKey ? (
                      <FormFeedback type='invalid'>{formik.errors.oldSystemKey}</FormFeedback>
                    ) : null}
                  </div>
                  <div className='form-group mt-3'>
                    <Label className='form-label'>New Manager Key</Label>
                    <Input
                      name='newSystemKey'
                      className='form-control'
                      placeholder='Enter your new manager key'
                      type='password'
                      autoComplete='on'
                      value={formik.values.newSystemKey}
                      onChange={formik.handleChange}
                      invalid={formik.touched.newSystemKey && !!formik.errors.newSystemKey}
                    />
                    {formik.touched.newSystemKey && formik.errors.newSystemKey ? (
                      <FormFeedback type='invalid'>{formik.errors.newSystemKey}</FormFeedback>
                    ) : null}
                  </div>
                  <div className='form-group mt-3'>
                    <Label className='form-label'>Retype New Manager Key</Label>
                    <Input
                      name='retypeNewSystemKey'
                      className='form-control'
                      placeholder=' a$3f21@8asj'
                      type='password'
                      autoComplete='on'
                      value={formik.values.retypeNewSystemKey}
                      onChange={formik.handleChange}
                      invalid={
                        formik.touched.retypeNewSystemKey && !!formik.errors.retypeNewSystemKey
                      }
                    />
                    {formik.touched.retypeNewSystemKey && formik.errors.retypeNewSystemKey ? (
                      <FormFeedback type='invalid'>{formik.errors.retypeNewSystemKey}</FormFeedback>
                    ) : null}
                  </div>
                  <Button
                    color='primary'
                    className='btn btn-primary waves-effect waves-light mt-3'
                    disabled={formik.isSubmitting}
                  >
                    Save changes
                  </Button>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
