import React from 'react';
import { Card } from 'reactstrap';

import useTitle from 'hooks/useTitle';

import AppVersion from './AppVersion';
import NewFeature from './NewFeature';
import OISP from './OISP';
import SwipeHome from './SwipeHome';

const Dashboard = () => {
  useTitle('Home', {
    restoreOnUnmount: true,
  });

  return (
    <React.Fragment>
      <div className='page-content'>
        <Card>
          <div style={{ backgroundColor: 'white' }}>
            <SwipeHome />
            <AppVersion />
            <OISP />
            <NewFeature />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
