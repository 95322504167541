import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  // Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  // FormGroup,
  // Label,
  Row,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import TableContainer from 'Components/Common/TableContainer';
import StatusBadge from 'Components/CommonForBoth/StatusBadge';
import { handleAxiosError } from 'helpers/handleError';
import { subjectTranslate } from 'helpers/translate';
import useTitle from 'hooks/useTitle';
import ContactSystemService from 'services/contact-system.service';
import { SupportTicket } from 'types';

const limit = 20;

const ContactSystem = () => {
  useTitle('Contact system', {
    restoreOnUnmount: true,
  });

  // const [filter, setFilter] = useState<Partial<SupportTicket>>({});
  // const [filterInput, setFilterInput] = useState({
  //   creator: '',
  //   title: '',
  //   subject: '',
  //   status: '',
  // });

  const [supportTicketList, setSupportTicketList] = useState<SupportTicket[]>([]);

  const [sortBy, setSortBy] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [supportTicketCount, setSupportTicketCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onToggleSort = (id: string) => {
    if (sortBy !== id) {
      setSortBy(id);
      setOrder('asc');
    } else if (order === 'asc') {
      setOrder('desc');
    } else {
      setSortBy('');
      setOrder('asc');
    }
  };

  const columnHelper = createColumnHelper<SupportTicket>();

  const navigate = useNavigate();

  // const fetchSupportTicketList = async () => {
  //   try {
  //     const { data } = await ContactSystemService.getSupportTicketList(limit, limit * (currentPage - 1));
  //     const { payload } = data;
  //     setSupportTicketList(payload.supportTicketList);
  //     setSupportTicketCount(payload.count);
  //   } catch (error: unknown) {
  //     handleAxiosError(error, (message) => toast.error(message));
  //   }
  // }

  const columns = [
    columnHelper.accessor('title', {
      header: 'Title',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div>{cellProps.getValue()}</div>;
      },
      footer: (props) => props.column.id,
    }),

    columnHelper.accessor('subject', {
      header: 'Subject',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return (
          <Badge color='primary' className='font-size-16 mb-2'>
            {subjectTranslate(cellProps.getValue())}
          </Badge>
        );
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <StatusBadge value={cellProps.getValue()} />;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('updatedAt', {
      header: 'Last update',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return (
          <div>
            {cellProps.getValue()
              ? new Date(cellProps.getValue()).toLocaleDateString('vi-VN')
              : 'Chưa cập nhật'}
          </div>
        );
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('createdAt', {
      header: 'Create date',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return (
          <div>
            {cellProps.getValue()
              ? new Date(cellProps.getValue()).toLocaleDateString('vi-VN')
              : 'Chưa cập nhật'}
          </div>
        );
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('createdBy', {
      header: 'Created by',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div>{cellProps.getValue().name}</div>;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('_id', {
      header: 'Detail',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return (
          <Button
            type='button'
            color='primary'
            className='btn-rounded'
            onClick={() => {
              console.log(cellProps.getValue());
              navigate('/contact-system/' + cellProps.getValue());
            }}
          >
            Detail
          </Button>
        );
      },
      footer: (props) => props.column.id,
    }),
  ];

  useEffect(() => {
    ContactSystemService.getSupportTicketList(limit, limit * (currentPage - 1))
      .then((res) => {
        const { data } = res;
        const { payload } = data;
        setSupportTicketList(payload.supportTicketList);
        setSupportTicketCount(payload.count);
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage]);

  if (isLoading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='OContact' breadcrumbItem='Contact system' />
        </Container>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardTitle className='mt-4 ml-4'>Support ticket</CardTitle>
                <Row className='mb-2'>
                  {/*
                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Title</Label>
                      <input
                        type='text'
                        placeholder='eg: Contact A'
                        className='input-mini form-control'
                        value={filterInput.title}
                        onChange={(e) => setFilterInput({ ...filterInput, title: e.target.value })}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Subject</Label>
                      <input
                        type='text'
                        placeholder='eg: COURSE_REGISTRATION'
                        className='input-mini form-control'
                        value={filterInput.subject}
                        onChange={(e) =>
                          setFilterInput({ ...filterInput, subject: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Status</Label>
                      <input
                        type='text'
                        placeholder='eg: FINISHED'
                        className='input-mini form-control'
                        value={filterInput.status}
                        onChange={(e) => setFilterInput({ ...filterInput, status: e.target.value })}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Creator</Label>
                      <input
                        type='text'
                        placeholder='eg: Nguyen Van A'
                        className='input-mini form-control'
                        value={filterInput.creator}
                        onChange={(e) =>
                          setFilterInput({ ...filterInput, creator: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl align-self-end'>
                    <div className='mb-3'>
                      <Button
                        type='button'
                        color='primary'
                        className='w-md'
                        onClick={() => {
                          setFilter(filterInput)
                          console.log('search');
                        }}
                      >
                        {' '}
                        Search
                      </Button>
                    </div>
                  </Col> */}

                  <TableContainer
                    columns={columns}
                    tableClass='table align-middle table-nowrap'
                    theadClass=''
                    sortBy={sortBy}
                    order={order}
                    onToggleSort={onToggleSort}
                    data={supportTicketList}
                    pageSize={supportTicketList?.length}
                  />
                </Row>
                <Pagination
                  count={supportTicketCount}
                  pageSize={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ContactSystem;
