import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

type FooterProps = {
  className?: string;
};

const Footer = ({ className }: FooterProps) => {
  return (
    <React.Fragment>
      <footer className={classNames('footer', className)}>
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} © OISP. <Link to='/support'>Need support ?</Link>
            </Col>
            <Col md={6}>
              <div className='text-sm-end d-none d-sm-block'>Developed by Fessior Community.</div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
