import { Permission } from 'types';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

export const API_URL = process.env.REACT_APP_API_URL as string;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;

export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET as string;

export const ROUTER_BASENAME = process.env.REACT_APP_ROUTER_BASENAME as string;

export const PermissionDescription = {
  [Permission.STUDENT]: 'Be a student (System generated)',
  [Permission.UPLOAD_STUDENT_INFO]: 'Upload student information',
  [Permission.UPDATE_STUDENT_INFO]: 'Update student information',
  [Permission.READ_STUDENT_INFO]: 'Read student information',
  [Permission.CREATE_STUDENT_REPORT]: 'Creat student report',
  [Permission.UPLOAD_FILE]: 'Upload to file manager',
  [Permission.UPDATE_FILE]: 'Change content of a file in file manager',
  [Permission.DOWNLOAD_FILE]: 'Download file in file manager',
  [Permission.DELETE_FILE]: 'Delete file in file manager',
  [Permission.READ_USER_INFO]: 'Read User Information',
  [Permission.CREATE_UNIT]: 'Create unit',
  [Permission.UPDATE_UNIT]: 'Change content of a unit',
  [Permission.DELETE_UNIT]: 'Delete unit',
  [Permission.READ_UNIT]: 'Read unit',
  [Permission.UPDATE_SUPPORT_TICKET]: 'Change content of a support ticket',
  [Permission.READ_SUPPORT_TICKET]: 'Read support ticket',
  [Permission.CREATE_CERTIFICATE_BATCH]: 'Create certificate batch',
  [Permission.READ_CERTIFICATE_BATCH]: 'Read certificate batch',
  [Permission.UPDATE_CERTIFICATE_BATCH]: 'Update certificate batch',
  [Permission.DELETE_CERTIFICATE_BATCH]: 'Delete certificate batch',
  [Permission.CREATE_CERTIFICATE_TEMPLATE]: 'Create certificate template',
  [Permission.READ_CERTIFICATE_TEMPLATE]: 'Read certificate template',
  [Permission.UPDATE_CERTIFICATE_TEMPLATE]: 'Update certificate template',
  [Permission.READ_EVENT_STATISTIC]: 'Read event statistics',
  [Permission.UPDATE_CERTIFICATE]: 'Update certificate',
  [Permission.CREATE_EVENT]: 'Create event',
  [Permission.VERIFY_EVENT]: 'Verify event',
};

export const STUDENT_ENCRYPTED_FIELDS: Array<string> = ['phone'];
