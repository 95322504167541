import React from 'react';
import { Navigate } from 'react-router-dom';

import useAppSelector from 'hooks/useAppSelector';
import { RootState } from 'slices';
import { Permission } from 'types';

type AuthProtectedProps = {
  children: React.ReactNode;
  permissions?: Permission[];
  isManager?: boolean;
};

/**
 * Protected route
 * @props children - Page to be rendered
 * @props permissions - Permissions required to access the page
 * @props isManager - If the page is only for manager
 * @returns
 */
const AuthProtected = (
  { children, permissions, isManager }: AuthProtectedProps = {
    children: null,
    permissions: [],
  }
) => {
  const { user, isAuthenticated, loading } = useAppSelector((state: RootState) => state.Login);

  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login' }} />;
  }

  /* Check permissions */
  if (
    permissions &&
    permissions.length > 0 &&
    !user?.isManager &&
    !permissions.some((permision) => user?.permissions?.includes(permision))
  ) {
    return <Navigate to={{ pathname: '/nopermission' }} />;
  }

  if (isManager && !user?.isManager) {
    return <Navigate to={{ pathname: '/nopermission' }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
