import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { Attachment, CertParameter, Certificate, CertificateBatch, ResponseData } from 'types';

const CertificateService = {
  getCertificateList: async (
    limit: number,
    offset = 0,
    sortBy?: string,
    order?: 'asc' | 'desc',
    from?: number,
    to?: number
  ) => {
    return axios.get<
      ResponseData<{
        count: number;
        total: number;
        certificateList: Certificate[];
      }>
    >(
      `${API_URL}certificates?sortBy=createdAt&order=desc&limit=${limit}&offset=${offset}${
        sortBy ? `?sortBy=${sortBy}` : ''
      }${order ? `?order=${order}` : ''}${from ? `?from=${from}` : ''}${to ? `?to=${to}` : ''}`
    );
  },
  getCertificateById: async (id: string) => {
    return axios.get<ResponseData<Certificate>>(`${API_URL}admin/certificates/${id}`);
  },
  getUserCertificateById: async (id: string) => {
    return axios.get<ResponseData<Certificate>>(`${API_URL}certificates/${id}`);
  },
  addCertificate: async (
    certificate: {
      recipientEmail: string;
      parameters: CertParameter[];
    },
    batchId: string
  ) => {
    return axios.post<ResponseData<{ addCertificate: Certificate; batch: CertificateBatch[] }>>(
      `${API_URL}admin/certificates/`,
      {
        certificate,
        batchId,
      }
    );
  },
  updateCertificateParams: async (
    id: string,
    parameters: CertParameter[],
    recipientEmail: string
  ) => {
    return axios.patch<ResponseData<Certificate>>(`${API_URL}admin/certificates/${id}`, {
      parameters,
      recipientEmail,
    });
  },
  deleteCertificate: async (id: string) => {
    return axios.delete<ResponseData<Certificate>>(`${API_URL}admin/certificates/${id}`);
  },
  generateThumbnail: async (id: string, file: File) => {
    const formData = new FormData();
    formData.append('thumbnail', file);

    return axios.post<ResponseData<Attachment>>(
      `${API_URL}certificates/thumbnail/${id}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};

export default CertificateService;
