import { GoogleLogin } from '@react-oauth/google';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap';
import * as Yup from 'yup';

import withRouter from 'Components/Common/withRouter';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useTitle from 'hooks/useTitle';
import AuthService from 'services/auth.service';
import { RootState } from 'slices';
import { getProfile } from 'slices/auth/login/thunk';
import { ResponseData } from 'types';

const initialForm = {
  password: '',
  confirmPassword: '',
};

enum SignUpStage {
  TOKEN_STAGE = 'TOKEN_STAGE',
  PASSWORD_STAGE = 'PASSWORD_STAGE',
}

const Register = () => {
  useTitle('Đăng nhập', {
    restoreOnUnmount: true,
  });

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [credentialToken, setCredentialToken] = useState('');
  const [signUpStage, setSignUpStage] = useState<SignUpStage>(SignUpStage.TOKEN_STAGE);

  const [searchParams, setSearchParams] = useSearchParams({ token: '' });

  const { token: queryToken } = Object.fromEntries(searchParams);

  const navigate = useNavigate();

  const { isAuthenticated } = useAppSelector((state: RootState) => state.Login);

  const dispatch = useAppDispatch();

  const signup = (password: string, confirmPassword: string) => {
    AuthService.signup(credentialToken, password, confirmPassword)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res.data.payload));
        dispatch(getProfile());
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          const resData = error?.response?.data as ResponseData;
          toast.error(resData.message);
        } else console.log(error);
      });
  };

  const validation = useFormik({
    initialValues: initialForm,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter your password'),
      confirmPassword: Yup.string().required('Please confirm your password'),
    }),
    onSubmit: (values) => {
      signup(values.password, values.confirmPassword);
    },
  });

  useEffect(() => {
    if (
      queryToken &&
      queryToken !== '' &&
      window.location.pathname.split('/')[1] === 'login' &&
      window.location.pathname.split('/').length === 2
    ) {
      localStorage.setItem('token', JSON.stringify(queryToken));
      setSearchParams({ token: '' });
    }
  }, [queryToken, setSearchParams]);

  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard');
  }, [isAuthenticated, navigate]);

  return (
    <React.Fragment>
      <div className='tw-h-full tw-w-screen tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-flex-row lg:tw-gap-[7.5rem] xl:tw-gap-[15rem] 2xl:tw-gap-[20rem] tw-mt-[4rem]'>
        <Row className='tw-w-[20rem] tw-text-[0.75rem] tw-flex tw-flex-col tw-items-center xs:tw-w-[25rem] xl:tw-w-[30rem] '>
          <Col>
            <div className='tw-overflow-hidden tw-p-[1.25rem] tw-rounded-lg tw-mb-0'>
              <div className='tw-bg-primary tw-text-white tw-flex tw-flex-col tw-justify-start tw-items-start tw-p-[1.25rem] tw-rounded-t-lg'>
                <h1 className='tw-content-center tw-mb-0 tw-text-[1.5rem] tw-font-bold'>
                  Register
                </h1>
                <p className='tw-mt-2  tw-text-start'>
                  Register to OISP Union App using your google account
                </p>
                <div className=''></div>
              </div>
              <div className='tw-pb-5 tw-border-[1px] tw-border-primary tw-border-solid tw-rounded-b-lg'>
                <div className='auth-logo'></div>

                <div className='tw-p-2'>
                  {signUpStage === SignUpStage.PASSWORD_STAGE && (
                    <Form
                      className='form-horizontal '
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className='mb-3'>
                        <div className='input-group auth-pass-inputgroup'>
                          <Input
                            name='password'
                            className='form-control register'
                            placeholder='Enter password'
                            type={showPass ? 'text' : 'password'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShowPass(!showPass)}
                            className='btn btn-light '
                            type='button'
                            id='password-addon'
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type='invalid'>{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className='tw-mb-3'>
                        <div className='input-group auth-pass-inputgroup'>
                          <Input
                            name='confirmPassword'
                            className='form-control register'
                            placeholder='Confirm password'
                            type={showConfirmPass ? 'text' : 'password'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ''}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                            className='btn btn-light '
                            type='button'
                            id='password-addon'
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className='mt-3 d-grid'>
                        <button className='btn btn-primary btn-block ' type='submit'>
                          Sign up
                        </button>
                      </div>
                    </Form>
                  )}

                  {/* <div className='mt-2 text-center'>
                      <Link to='/' className='text-primary register'>
                        <i className='mdi mdi-lock me-1 ' /> Forgot your password?
                      </Link>
                    </div> */}

                  {signUpStage === SignUpStage.TOKEN_STAGE && (
                    <div
                      className='tw-mt-3 tw-text-center'
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <div>
                        <GoogleLogin
                          size='large'
                          text='signup_with'
                          onSuccess={(response) => {
                            if (response.credential) {
                              setCredentialToken(response.credential);
                              setSignUpStage(SignUpStage.PASSWORD_STAGE);
                            }
                          }}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='tw-mt-3 tw-text-center'>
              <p>
                Already have an account ?{' '}
                <Link to='/login' className='fw-medium text-primary' style={{ fontWeight: '700' }}>
                  {' '}
                  Login{' '}
                </Link>{' '}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);
