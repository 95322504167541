import { toast } from 'react-toastify';
import { Button, Modal, ModalBody } from 'reactstrap';

type CheckInQrModalProps = {
  event: string;
  qrUrl: string;
  participantName: string;
  show: boolean;
  onCloseClick: () => void;
};

const CheckInQrModal = ({
  event,
  qrUrl,
  participantName,
  show,
  onCloseClick,
}: CheckInQrModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='sm'>
      <div className='modal-header !tw-p-2 tw-h-10'>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-1'
        />
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-items-center tw-gap-4'>
          <div className='tw-font-bold tw-text-[1.5rem]'>{event}</div>
          <img
            alt='Check-in QR Code'
            src={qrUrl}
            className='tw-relative tw-w-[15rem] tw-aspect-square tw-border-8 tw-border-[#3D4863] tw-border-solid tw-object-cover tw-rounded-lg tw-p-2'
          />
          <div className='tw-font-bold tw-text-[1.5rem]'>{participantName}</div>
          <Button
            type='button'
            color='primary'
            className='tw-mt-[1rem]'
            disabled={false}
            onClick={() => {
              const link = document.createElement('a');
              link.download = `${event}_${participantName}.png`;
              link.href = qrUrl;
              link.click();
              toast.success('QR Code is being downloaded');
            }}
          >
            Download
          </Button>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default CheckInQrModal;
