import { AxiosError } from 'axios';

import { ResponseData } from 'types';

const defaultMessage = 'Something went wrong. Please try again.';

export function handleAxiosError<E = unknown>(
  error: E,
  cb: (message: string, error?: E) => void
): void {
  if (error instanceof AxiosError) {
    const responseError = error as AxiosError<ResponseData<unknown>>;

    if (responseError.response?.data instanceof ArrayBuffer) {
      try {
        const dataJSON = new TextDecoder().decode(responseError.response?.data as ArrayBuffer);

        const data = JSON.parse(dataJSON) as ResponseData;

        cb?.(data?.message ?? defaultMessage, error);
      } catch (e) {
        cb?.(responseError.response?.data?.message ?? defaultMessage, error);
      }
    } else {
      cb?.(responseError.response?.data?.message ?? defaultMessage, error);
    }
  } else {
    // Network error
    console.log(error);
    cb?.(defaultMessage, error);
  }
}
