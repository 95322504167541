import { startCase } from 'lodash';

import { SupportTicketStatus, SupportTicketSubject, UnitTicketSubject, UnitType } from 'types';

export const toStartCase = (phase: string) => {
  const convertedString = startCase(phase).replace(/\s+/g, '-').toLowerCase();
  return convertedString.charAt(0).toUpperCase() + convertedString.slice(1);
};

export const subjectTranslate = (subject: UnitTicketSubject | SupportTicketSubject) => {
  switch (subject) {
    case UnitTicketSubject.CIVIC_ACTITIVIES || SupportTicketSubject.CIVIC_ACTITIVIES:
      return 'Civic activities';
    case UnitTicketSubject.COURSE_REGISTRATION || SupportTicketSubject.COURSE_REGISTRATION:
      return 'Course registration';
    case UnitTicketSubject.GRADE_GRADUATION || SupportTicketSubject.GRADE_GRADUATION:
      return 'Grade graduation';
    case UnitTicketSubject.SCHOLARSHIP || SupportTicketSubject.SCHOLARSHIP:
      return 'Scholarship';
    case UnitTicketSubject.TUITION_FEE || SupportTicketSubject.TUITION_FEE:
      return 'Tuition fee';
    default:
      return 'Undefined';
  }
};

export const statusTranslate = (status: SupportTicketStatus) => {
  switch (status) {
    case SupportTicketStatus.FINISHED:
      return 'Finished';
    case SupportTicketStatus.PENDING:
      return 'Pending';
    case SupportTicketStatus.REJECTED:
      return 'Rejected';
    case SupportTicketStatus.PROCESSING:
      return 'Processing';
    case SupportTicketStatus.UNVERIFIED:
      return 'Unverified';
    default:
      return 'Undefined';
  }
};

export const unitTypeTranslate = (subject: UnitType) => {
  switch (subject) {
    case UnitType.ORGANIZATION:
      return 'Organization';
    case UnitType.TEACHER:
      return 'Teacher';
    default:
      return 'Undefined';
  }
};
