import { startCase } from 'lodash';

import { EventPermissions, EventRole } from 'types';

type RoleCardProps = {
  role: EventRole;
  setOpenRole: (role: EventRole) => void;
};

const RoleCard = ({ role, setOpenRole }: RoleCardProps) => {
  return (
    <div
      onClick={() => setOpenRole(role)}
      className='tw-relative tw-flex tw-flex-col tw-overflow-hidden tw-w-[15rem] tw-h-[10rem] tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg tw-cursor-pointer'
    >
      <div className='tw-relative tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-[#3D4863] tw-font-bold tw-text-white tw-py-2'>
        {role.title}
      </div>
      <div className='tw-relative tw-w-full tw-px-2 tw-max-h-[3rem] tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-mt-2'>
        {role.description}
      </div>
      <div className='tw-absolute tw-w-full tw-h-[2rem] tw-bottom-2 tw-overflow-hidden tw-px-2'>
        <div className='tw-relative tw-w-full tw-h-[2rem] tw-flex tw-flex-row tw-gap-1 tw-overflow-hidden'>
          {role.permissions.map((permission: EventPermissions) => {
            return (
              <div
                key={permission}
                className='tw-relative tw-flex font-size-12 tw-bg-[#3D4863] tw-rounded-lg tw-text-white tw-px-2 tw-py-1 tw-font-bold tw-items-center tw-whitespace-nowrap'
              >
                {startCase(permission)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RoleCard;
