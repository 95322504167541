import React from 'react';

import EventPermissionUserFriendlyMap from 'helpers/eventPermissionConverter';
import { EventPermissions } from 'types/core';

interface ToggleBadgeProps {
  selected: boolean;
  toggleOn: (permission: EventPermissions) => void;
  toggleOff: (permission: EventPermissions) => void;
  eventPermission: EventPermissions;
}

const ToggleBadge = ({ selected, toggleOn, toggleOff, eventPermission }: ToggleBadgeProps) => {
  return (
    <React.Fragment>
      <div
        className={`tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-font-bold tw-text-white tw-text-xs tw-h-6 tw-px-2 tw-cursor-pointer ${
          selected ? 'tw-bg-[#3D4863]' : 'tw-bg-[rgba(61,72,99,0.5)]'
        }`}
        onClick={() => {
          if (selected) toggleOff(eventPermission);
          else toggleOn(eventPermission);
        }}
      >
        {EventPermissionUserFriendlyMap[eventPermission]}
      </div>
    </React.Fragment>
  );
};

export default ToggleBadge;
