import ReactApexChart from 'react-apexcharts';

import { ChartData } from 'types';

type PieChartProps = {
  chartData: ChartData;
};

export const PieChart = ({ chartData }: PieChartProps) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 320,
      type: 'pie',
    },
    series: chartData.dataValues,
    labels: chartData.dataLabels,
    colors: chartData.dataColors,
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 320,
          },
          legend: {
            position: 'bottom',
            show: true,
          },
        },
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: 'left',
            show: true,
          },
        },
      },
      {
        breakpoint: 1536,
        options: {
          chart: {
            width: 480,
          },
          legend: {
            position: 'left',
            show: true,
          },
        },
      },
      {
        breakpoint: 1920,
        options: {
          chart: {
            width: 560,
          },
          legend: {
            position: 'left',
            show: true,
          },
        },
      },
    ],
  };

  return <ReactApexChart options={options} series={chartData.dataValues} type='pie' />;
};
