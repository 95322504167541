import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { Event, EventAction, EventStatus, ResponseData } from 'types';

const EventCoreService = {
  createEvent: async (event: {
    title: string;
    description: string;
    location: string;
    startAt: number;
    endAt: number;
  }) => {
    return axios.post<ResponseData<Event>>(`${API_URL}admin/events/`, event);
  },

  uploadPoster: async (eventId: string, file: File) => {
    const formData = new FormData();
    formData.append('eventId', eventId);
    formData.append('poster', file);

    return axios.post<ResponseData<string>>(`${API_URL}events/poster`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getAllEvents: async (
    order: 'asc' | 'desc',
    limit: number,
    offset: number,
    title?: string,
    status?: EventStatus
  ) => {
    return axios.get<ResponseData<{ eventList: Array<Event>; count: number }>>(
      `${API_URL}events/active?sortBy=createdAt&order=${order}&limit=${limit}&offset=${offset}${
        title ? `&title=${title}` : ''
      }${status ? `&status=${status}` : ''}`
    );
  },

  getEventById: async (eventId: string) => {
    return axios.get<ResponseData<Event>>(`${API_URL}events/${eventId}`);
  },

  updateEventMetadata: async (
    eventId: string,
    title: string,
    description: string,
    location: string,
    startAt: number,
    endAt: number
  ) => {
    return axios.put<ResponseData<Event>>(`${API_URL}events/${eventId}/metadata`, {
      title,
      description,
      location,
      startAt,
      endAt,
    });
  },

  getParticipatedEvents: async (
    order: 'asc' | 'desc',
    limit: number,
    offset: number,
    title?: string,
    status?: EventStatus
  ) => {
    return axios.get<ResponseData<{ eventList: Array<Event>; count: number }>>(
      `${API_URL}events/participated?sortBy=createdAt&order=${order}&limit=${limit}&offset=${offset}${
        title ? `&title=${title}` : ''
      }${status ? `&status=${status}` : ''}`
    );
  },

  getEventActions: async (eventId: string, limit: number, offset: number) => {
    return axios.get<ResponseData<Array<EventAction>>>(
      `${API_URL}events/${eventId}/actions?limit=${limit}&offset=${offset}`
    );
  },
};

export default EventCoreService;
