import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { CertParameter, Certificate, CertificateBatch, ResponseData } from 'types';

const CertificateBatchService = {
  getCertificateBatchList: async (limit: number, offset = 0) => {
    return axios.get<
      ResponseData<{
        count: number;
        batchList: CertificateBatch[];
      }>
    >(
      `${API_URL}admin/certificates/batches?sortBy=createdAt&order=desc&limit=${limit}&offset=${offset}`
    );
  },
  getCertificateBatchById: async (id: string) => {
    return axios.get<ResponseData<CertificateBatch>>(`${API_URL}admin/certificates/batches/${id}`);
  },
  getCertificateListByBatchId: async (id: string, limit: number, offset = 0) => {
    return axios.get<
      ResponseData<{
        certificateList: Certificate[];
        count: number;
      }>
    >(`${API_URL}admin/certificates/batches/${id}/all-cert?limit=${limit}&offset=${offset}`);
  },
  downloadCertificateBatch: async (id: string) => {
    return axios.get<ArrayBuffer>(`${API_URL}admin/certificates/batches/${id}/bulk-download`, {
      responseType: 'arraybuffer',
    });
  },
  createCertificateBatch: async (
    batch: {
      name: string;
      batchId: string;
      description?: string;
      template: string;
    },
    certificates: Array<{
      recipientEmail: string;
      parameters: CertParameter[];
    }>
  ) => {
    return axios.post<ResponseData<{ batch: CertificateBatch; certificates: Certificate[] }>>(
      `${API_URL}admin/certificates/batches`,
      {
        batch: JSON.stringify(batch),
        certificates: JSON.stringify(certificates),
      }
    );
  },
  editCertificateBatch: async (id: string, name: string, description: string) => {
    return axios.patch<ResponseData<CertificateBatch>>(
      `${API_URL}admin/certificates/batches/${id}`,
      {
        name,
        description,
      }
    );
  },
  updateBatchSignee: async (
    id: string,
    signee: string,
    signeePosition: string,
    signature?: File
  ) => {
    const formData = new FormData();
    if (signature) formData.append('file', signature);
    formData.append('signee', signee);
    formData.append('signeePosition', signeePosition);

    return axios.patch<ResponseData<CertificateBatch>>(
      `${API_URL}admin/certificates/batches/${id}/signee`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  updateBatchDecision: async (
    id: string,
    decisionId: string,
    decisionDate: number,
    decisionDocument: File
  ) => {
    console.log(1);
    const formData = new FormData();
    formData.append('file', decisionDocument);
    formData.append('decisionId', decisionId);
    formData.append('decisionDate', JSON.stringify(decisionDate));
    console.log(2);
    return axios.patch<ResponseData<CertificateBatch>>(
      `${API_URL}admin/certificates/batches/${id}/decision`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  deleteCertificateBatch: async (id: string) => {
    return axios.delete<ResponseData<CertificateBatch>>(
      `${API_URL}admin/certificates/batches/${id}`
    );
  },
};

export default CertificateBatchService;
