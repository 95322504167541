import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, SupportTicket, SupportTicketSubject } from 'types';

type PreviewFile = File & {
  preview: string;
};

const ContactSystemService = {
  getSupportTicketList: async (limit: number, offset = 0) => {
    return axios.get<
      ResponseData<{
        count: number;
        supportTicketList: SupportTicket[];
      }>
    >(`${API_URL}admin/support_ticket?limit=${limit}&offset=${offset}`);
  },
  getSupportTicketById: async (id: string) => {
    return axios.get<ResponseData<SupportTicket>>(`${API_URL}admin/support_ticket/${id}`);
  },
  downloadAttachment: async (id: string, attachment_id: string) =>
    axios.get<ArrayBuffer>(`${API_URL}admin/support_ticket/${id}/attachment/${attachment_id}`, {
      responseType: 'arraybuffer',
    }),
  approveTicket: async (id: string, subject: SupportTicketSubject) => {
    return axios.post<ResponseData<SupportTicket>>(`${API_URL}admin/support_ticket/${id}/approve`, {
      subject,
    });
  },
  rejectTicket: async (id: string, reason: string) => {
    return axios.post<ResponseData<SupportTicket>>(`${API_URL}admin/support_ticket/${id}/reject`, {
      reason,
    });
  },
  addMessage: async (id: string, content: string, files: PreviewFile[]) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });
    formData.append('content', content);
    return axios.post<ResponseData<SupportTicket>>(
      `${API_URL}admin/support_ticket/${id}/message`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};

export default ContactSystemService;
