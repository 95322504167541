import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import DeleteModal from 'Components/Common/DeleteModal';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import timeSince from 'helpers/time-since';
import useTitle from 'hooks/useTitle';
import CertificateBatchService from 'services/certificate-batch.service';
import { CertificateBatch, CertificateTemplate } from 'types';

import DownloadBatchModal from './DownloadBatchModal';

const initialTemplate: CertificateTemplate = {
  _id: '',
  name: '',
  description: '',
  parameters: [],
  background: {
    _id: '',
    originalName: '',
    filename: '',
    directoryId: '',
    refName: '',
    size: 0,
    mimetype: '',
    createdAt: 0,
    updatedAt: 0,
  },
  backgroundUrl: '',
  thumbnail: '',
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};

const initialBatch: CertificateBatch = {
  _id: '',
  name: '',
  batchId: '',
  description: '',
  template: initialTemplate,
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  signee: '',
  signeePosition: '',
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};

type CertificateCardProps = {
  certificate: CertificateBatch;
  openDownloadModal: () => void;
  setDeleteBatchId: (id: string) => void;
};

const limit = 20;

const CertificateCard = ({
  certificate,
  openDownloadModal,
  setDeleteBatchId,
}: CertificateCardProps) => {
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);

  const navigate = useNavigate();

  return (
    <Col xl={3} sm={4}>
      <Card>
        <CardHeader style={{ background: 'white', paddingBottom: '0px' }}>
          <Dropdown isOpen={menu} toggle={toggleMenu} className='float-end ms-2'>
            <DropdownToggle tag='a' className='text-muted'>
              <i className='mdi mdi-dots-horizontal font-size-18' />
            </DropdownToggle>
            <DropdownMenu>
              {/* <DropdownItem onClick={() => navigate('/certificate/' + certificate._id)}>
                <i className='bx bx-edit me-1' />
                Edit
              </DropdownItem> */}
              <DropdownItem
                onClick={() => {
                  openDownloadModal();
                }}
              >
                <i className='bx bx-download me-1' />
                Download
              </DropdownItem>
              <DropdownItem
                className='text-danger'
                onClick={() => setDeleteBatchId(certificate._id)}
              >
                <i className='bx bx-trash me-1' />
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </CardHeader>
        <CardBody
          onClick={() => navigate('/certificate/' + certificate._id)}
          style={{ paddingTop: '8px', cursor: 'pointer' }}
        >
          <img
            alt={certificate.name}
            className='px-2 py-2 img-fluid mx-auto d-block rounded'
            src={(certificate.template as CertificateTemplate).thumbnail || ''}
          />
          <div className='d-flex mt-4'>
            <div className='flex-grow-1 overflow-hidden'>
              <h5 className='text-truncate font-size-15 text-dark'>{certificate.name}</h5>
              <p className=' text-muted'>{certificate.description}</p>
            </div>
          </div>
        </CardBody>

        <div className='px-4 py-3 border-top'>
          <ul className='list-inline mb-0 justify-content-between'>
            <li className='list-inline-item me-3' id='dueDate'>
              <i className='bx bx-calendar me-1' />{' '}
              {Date.now() - certificate.createdAt >= 846000000
                ? new Date(certificate.createdAt).toLocaleString('vi-VN')
                : timeSince(certificate.createdAt)}
            </li>
            {/* <li className='list-inline-item me-3' id='member'>
              <i className='bx bx-group me-1' />
              {certificate.users.length}
            </li> */}
          </ul>
        </div>
      </Card>
    </Col>
  );
};

const CertificateList = () => {
  useTitle('Certificate', {
    restoreOnUnmount: true,
  });

  const [certificateBatchList, setCertificateBatchList] = useState<CertificateBatch[]>([]);
  const [certificateCount, setCertificateCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [downloadBatch, setDownloadBatch] = useState<CertificateBatch>(initialBatch);
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);

  //use this to check if the delete is in progress
  const [loading, setLoading] = useState(false);

  //use this to check if the page is loading
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await CertificateBatchService.deleteCertificateBatch(deleteId || '');
      await fetchCertificateList();
      toast.success('Delete batch successfully!');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
      setDeleteId(undefined);
    }
  };

  const fetchCertificateList = useCallback(async () => {
    try {
      const { data } = await CertificateBatchService.getCertificateBatchList(
        limit,
        limit * (currentPage - 1)
      );
      const { payload } = data;

      // Request thumbnail for each certificate
      // let batchList = payload.batchList;
      // const thumbnailSet = batchList.reduce((acc: Set<string>, curr: CertificateBatch) => {
      //   acc.add((curr.template as CertificateTemplate).background as string);
      //   return acc;
      // }, new Set<string>());

      // const thumbnailBlobMap = new Map<string, string>();

      // await Promise.all(
      //   Array.from(thumbnailSet).map(async (thumbnailId) => {
      //     const { data: thumbnailData } = await StorageService.downloadAttachment(thumbnailId);
      //     const blob: Blob = new Blob([thumbnailData]);
      //     const objectUrl: string = URL.createObjectURL(blob);
      //     thumbnailBlobMap.set(thumbnailId, objectUrl);
      //   })
      // );

      // batchList = batchList.map((batch) => {
      //   const thumbnailId = (batch.template as CertificateTemplate).background as string;
      //   const newBatch = { ...batch };
      //   (newBatch.template as CertificateTemplate).thumbnail =
      //     thumbnailBlobMap.get(thumbnailId) || '';
      //   return newBatch;
      // });

      setCertificateBatchList(payload.batchList);
      setCertificateCount(payload.count);
      setIsLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [currentPage]);

  const downloadBatchCertificates = async (batch: CertificateBatch, stopLoad: () => void) => {
    try {
      const { data } = await CertificateBatchService.downloadCertificateBatch(batch._id);

      const blob: Blob = new Blob([data]);
      const url: string = URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a');
      document.body.appendChild(link);

      link.href = url;
      link.download = `${batch.name}.zip`;
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setOpenDownloadModal(false);
      stopLoad();
    }
  };

  useEffect(() => {
    fetchCertificateList();
  }, [fetchCertificateList]);

  if (isLoading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteId !== undefined}
        title='Delete batch'
        content='Are you sure you want to delete this batch?'
        disabled={loading}
        onDeleteClick={() => {
          handleDelete();
          return;
        }}
        onCloseClick={() => setDeleteId(undefined)}
      />
      <DownloadBatchModal
        batch={downloadBatch}
        batchCount={null}
        show={openDownloadModal}
        onDownloadClick={downloadBatchCertificates}
        onCloseClick={() => setOpenDownloadModal(false)}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Certificate' />
        </Container>
        <Row className='mb-4'>
          <div className='text-end'>
            <Button
              type='button'
              color='primary'
              className='ms-1 btn mt-2'
              onClick={() => navigate('/certificate/create')}
            >
              Create new certificate
            </Button>
          </div>
        </Row>
        <Row>
          {certificateBatchList.map((certificateBatch: CertificateBatch) => {
            return (
              <CertificateCard
                key={certificateBatch._id}
                certificate={certificateBatch}
                openDownloadModal={() => {
                  setDownloadBatch(certificateBatch);
                  setOpenDownloadModal(true);
                }}
                setDeleteBatchId={setDeleteId}
              />
            );
          })}
        </Row>
        <Pagination
          count={certificateCount}
          pageSize={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </React.Fragment>
  );
};

export default CertificateList;
