import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, User } from 'types';

const UserService = {
  getMyProfile: async () => axios.get<ResponseData<User>>(`${API_URL}me`),
  getUserList: async (limit: number, email = '', name = '') => {
    return axios.get<ResponseData<{ count: number; userList: User[] }>>(
      `${API_URL}admin/user?limit=${limit}&email=${email}&name=${name}`
    );
  },
};

export default UserService;
