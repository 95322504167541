import { useState } from 'react';
// import { toast } from 'react-toastify';
import { Button, Modal, ModalBody } from 'reactstrap';

import Loading from 'Components/Common/LoadingIndicator';
import { CertificateBatch } from 'types';

type DownloadBatchModalProps = {
  batch: CertificateBatch;
  batchCount: number | null;
  show: boolean;
  onDownloadClick: (batch: CertificateBatch, stopLoad: () => void) => Promise<void>;
  onCloseClick: () => void;
};

const DownloadBatchModal = ({
  batch,
  batchCount,
  show,
  onDownloadClick,
  onCloseClick,
}: DownloadBatchModalProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Download batch's certificates</h5>
        <button
          type='button'
          onClick={onCloseClick}
          disabled={loading}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content '>
        {!loading && (
          <ModalBody className='px-4 py-4'>
            {batchCount && <div>Download {batchCount} certificates?</div>}
            {!batchCount && <div>Download all of this batch's certificates?</div>}
            <div className='text-end mt-3'>
              <Button
                type='button'
                color='primary'
                disabled={!batch}
                onClick={() => {
                  onDownloadClick(batch, () => setLoading(false));
                  setLoading(true);
                }}
              >
                Download
              </Button>
            </div>
          </ModalBody>
        )}
        {loading && (
          <ModalBody className='px-4 py-4'>
            <Loading width={200} height={200} />
          </ModalBody>
        )}
      </div>
    </Modal>
  );
};

export default DownloadBatchModal;
