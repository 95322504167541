import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap';
import * as Yup from 'yup';

import withRouter from 'Components/Common/withRouter';
import useAppSelector from 'hooks/useAppSelector';
import useTitle from 'hooks/useTitle';
import AuthService from 'services/auth.service';
import { RootState } from 'slices';
import { ResponseData } from 'types';

const initialForm = {
  email: '',
};

enum ForgotPasswordPhase {
  SEND = 'SEND',
  RESTORE = 'RESTORE',
}

const ForgotPassword = () => {
  useTitle('Đăng nhập', {
    restoreOnUnmount: true,
  });

  const [forgotPasswordPhase, setForgotPasswordPhase] = useState<ForgotPasswordPhase>(
    ForgotPasswordPhase.SEND
  );

  const [searchParams, setSearchParams] = useSearchParams({ token: '' });

  const { token: queryToken } = Object.fromEntries(searchParams);

  const navigate = useNavigate();

  const { isAuthenticated } = useAppSelector((state: RootState) => state.Login);

  const forgotPassword = (email: string) => {
    AuthService.forgotPassword(email)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res.data.payload));
        setForgotPasswordPhase(ForgotPasswordPhase.RESTORE);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          const resData = error?.response?.data as ResponseData;
          toast.error(resData.message);
        } else console.log(error);
      });
  };

  const validation = useFormik({
    initialValues: initialForm,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Please enter your email'),
    }),
    onSubmit: (values) => {
      forgotPassword(values.email);
    },
  });

  useEffect(() => {
    if (
      queryToken &&
      queryToken !== '' &&
      window.location.pathname.split('/')[1] === 'login' &&
      window.location.pathname.split('/').length === 2
    ) {
      localStorage.setItem('token', JSON.stringify(queryToken));
      setSearchParams({ token: '' });
    }
  }, [queryToken, setSearchParams]);

  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard');
  }, [isAuthenticated, navigate]);

  return (
    <React.Fragment>
      <div className='tw-h-full tw-w-screen tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-flex-row lg:tw-gap-[7.5rem] xl:tw-gap-[15rem] 2xl:tw-gap-[20rem] tw-mt-[4rem]'>
        <Row className='tw-w-[20rem] tw-text-[0.75rem] tw-flex tw-flex-col tw-items-center xs:tw-w-[25rem] xl:tw-w-[30rem] '>
          <Col>
            <div
              className='tw-overflow-hidden tw-p-[1.25rem] tw-rounded-lg tw-mb-0'
              style={{ marginBottom: '0px' }}
            >
              <div className='tw-bg-primary tw-text-white tw-flex tw-flex-col tw-justify-start tw-items-start tw-p-[1.25rem] tw-rounded-t-lg'>
                <h1 className='tw-content-center tw-mb-0 tw-text-[1.5rem] tw-font-bold'>
                  Forgot password
                </h1>
                <p className='tw-mt-2  tw-text-start'>Enter your email to restore password</p>
                <div className=''></div>
              </div>
              <div className='tw-p-1 tw-border-[1px] tw-border-primary tw-border-solid tw-rounded-b-lg'>
                <div className='auth-logo'></div>

                {forgotPasswordPhase === ForgotPasswordPhase.SEND && (
                  <div className='p-2'>
                    <Form
                      className='form-horizontal '
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className='mb-3'>
                        <Input
                          name='email'
                          className='form-control register'
                          placeholder='Enter email'
                          type='email'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />

                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className='mt-3 d-grid'>
                        <button className='btn btn-primary btn-block ' type='submit'>
                          Send email
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
                {forgotPasswordPhase === ForgotPasswordPhase.RESTORE && (
                  <div className='p-2 register'>
                    An email has been sent. Please check and follow the instruction to restore your
                    password.
                  </div>
                )}
              </div>
            </div>
            <div className='tw-mt-3 tw-text-center'>
              <p>
                Already have an account ?{' '}
                <Link to='/login' className='fw-medium text-primary' style={{ fontWeight: '700' }}>
                  {' '}
                  Login{' '}
                </Link>{' '}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotPassword);
