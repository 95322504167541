import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import TableContainer from 'Components/Common/TableContainer';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import StudentService from 'services/student.service';
import { Student } from 'types';

const limit = 20;

const StudentInfo = () => {
  useTitle('Thông tin sinh viên', {
    restoreOnUnmount: true,
  });

  const [studentList, setStudentList] = useState<Student[]>([]);
  const [studentCount, setStudentCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState<Partial<Student>>({});
  const [filterInput, setFilterInput] = useState({
    studentId: '',
    familyName: '',
    firstName: '',
    email: '',
  });

  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onToggleSort = (id: string) => {
    if (sortBy !== id) {
      setSortBy(id);
      setOrder('asc');
    } else if (order === 'asc') {
      setOrder('desc');
    } else {
      setSortBy('');
      setOrder('asc');
    }
  };

  useEffect(() => {
    const fetchAccessLevels = async () => {
      try {
        const filterQuery = _.keys(filter)
          .map((key: string) => {
            if (filter[key]) return `${key}=${_.toString(filter[key])}`;
            return '';
          })
          .join('&');

        const sortQuery = sortBy ? `sortBy=${sortBy}&order=${order}` : '';

        const { data } = await StudentService.getStudentList(
          limit,
          limit * (currentPage - 1),
          filterQuery,
          sortQuery
        );
        const { payload } = data;
        setStudentList(payload.studentList);
        setStudentCount(payload.count);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        setIsLoading(false);
      }
    };
    fetchAccessLevels().catch(() => {});
  }, [currentPage, filter, sortBy, order]);

  const columns = useMemo<ColumnDef<Student, keyof Student>[]>(
    () => [
      {
        header: 'Student ID',
        accessorKey: 'studentId',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Family Name',
        accessorKey: 'familyName',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'First Name',
        accessorKey: 'firstName',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: () => {
          return <div>••••••••••</div>; /* Encrypted */
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return <div>{cellProps.getValue()}</div>;
        },
      },
      {
        header: 'Date of birth',
        accessorKey: 'dob',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (cellProps) => {
          return (
            <div>
              {cellProps.getValue()
                ? new Date(cellProps.getValue()).toLocaleDateString('vi-VN')
                : 'Chưa cập nhật'}
            </div>
          );
        },
      },
      {
        header: 'Detail',
        accessorKey: '_id',
        filterable: false,
        cell: (cellProps) => {
          return (
            <Button
              type='button'
              color='primary'
              className='btn-rounded'
              onClick={() => navigate('/student-info/' + cellProps.getValue())}
            >
              Detail
            </Button>
          );
        },
      },
    ],
    [navigate]
  );

  if (isLoading) return <Loading width={600} height={600} />;

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Student' breadcrumbItem='Information' />
        </Container>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardTitle className='mt-4 ml-4'>Search and filter</CardTitle>
                <Row className='mb-2'>
                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Student ID</Label>
                      <input
                        type='text'
                        placeholder=' 2150001'
                        className='input-mini form-control'
                        value={filterInput.studentId}
                        onChange={(e) =>
                          setFilterInput({ ...filterInput, studentId: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Email</Label>
                      <input
                        type='text'
                        placeholder=' abc@hcmut.edu.vn'
                        className='input-mini form-control'
                        value={filterInput.email}
                        onChange={(e) => setFilterInput({ ...filterInput, email: e.target.value })}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>Family Name</Label>
                      <input
                        type='text'
                        placeholder=' Trần'
                        className='input-mini form-control'
                        value={filterInput.familyName}
                        onChange={(e) =>
                          setFilterInput({ ...filterInput, familyName: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl'>
                    <FormGroup className='mb-3'>
                      <Label>First Name</Label>
                      <input
                        type='text'
                        placeholder=' Khoa'
                        className='input-mini form-control'
                        value={filterInput.firstName}
                        onChange={(e) =>
                          setFilterInput({ ...filterInput, firstName: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} className='col-xl align-self-end'>
                    <div className='mb-3'>
                      <Button
                        type='button'
                        color='primary'
                        className='w-md'
                        onClick={() => setFilter(filterInput)}
                      >
                        {' '}
                        Search
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className='text-sm-end'>
                      <ButtonDropdown
                        isOpen={dropdownOpen}
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <Button color='success'>
                          <Link to='/student-info/add' className='text-white'>
                            Import
                          </Link>
                        </Button>
                        <DropdownToggle caret color='success' className='dropdown-toggle-split'>
                          <i className='mdi mdi-chevron-down' />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <Link to='/student-info/bulk' className='text-dark'>
                              Bulk import
                            </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </Col>

                  <TableContainer
                    columns={columns}
                    tableClass='table align-middle table-nowrap'
                    theadClass=''
                    sortBy={sortBy}
                    order={order}
                    onToggleSort={onToggleSort}
                    data={studentList}
                    pageSize={studentList?.length}
                  />
                </Row>
              </CardBody>
              <Pagination
                count={studentCount}
                pageSize={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default StudentInfo;
