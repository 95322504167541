import { useFormik } from 'formik';
import mime from 'mime';
import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from 'reactstrap';
import { WorkBook, WorkSheet, read, utils, writeFile } from 'xlsx';
import * as Yup from 'yup';

import ignoredParams from 'common/ignoredParams';
import { handleAxiosError } from 'helpers/handleError';
import { CertParameter, CertificateBatch, CertificateTemplate } from 'types';

type ConvertToAddCertFormatProps = {
  // specialParamsMap: Map<string, string>;
  specialParams: Array<string>;
  template: CertificateTemplate;
  data: unknown;
};

const convertToAddCertFormat = ({
  template,
  data,
  // specialParamsMap,
  specialParams,
}: ConvertToAddCertFormatProps) => {
  if (data && typeof data === 'object') {
    const keyList = Object.keys(data);
    const email = data[keyList[0]] as string;

    const params = template.parameters
      .filter((param) => !(specialParams.includes(param.key) && param.key !== 'date'))
      .map((param) => {
        if (specialParams.includes(param.key)) {
          if (param.key.toLowerCase().includes('date')) {
            if (!param.value)
              return {
                ...param,
                value: 'ENG',
              };
            return {
              ...param,
              // value: specialParamsMap.get(param.key) || 'VIE',
              value: param.value,
            };
          }
          return {
            ...param,
            // value: specialParamsMap.get(param.key) || null,
            value: null,
          };
        }

        if (param.key.toLowerCase().includes('date')) {
          if (!param.value)
            return {
              ...param,
              value: 'ENG',
            };
          return {
            ...param,
            value: 'VIE',
          };
        }

        return {
          ...param,
          value: data[keyList[template.parameters.indexOf(param) + 1]] as string,
        };
      });

    return {
      recipientEmail: email,
      parameters: params,
    };
  }
};

const downloadSheetTemplate = (params: CertParameter[]) => {
  const rows = [];
  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Parameters');

  const headers = ['Email'];

  params
    .filter((param) => {
      return !ignoredParams.includes(param.key);
    })
    .forEach((param) => {
      headers.push(param.name);
    });

  utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

  worksheet['!cols'] = [{ wch: 20 }];

  params.forEach(() => {
    if (worksheet['!cols']) worksheet['!cols'].push({ wch: 20 });
  });

  writeFile(workbook, 'Certificate template.xlsx', { compression: true });
};

interface AddRecipientModalProps {
  batch: CertificateBatch;
  show: boolean;
  certificateTemplate: CertificateTemplate;
  onSubmit: (newCert: {
    certificate: {
      recipientEmail: string;
      parameters: CertParameter[];
    };
    batchId: string;
  }) => Promise<void>;
  onCloseClick: () => void;
}

export const AddRecipientModal = ({
  batch,
  show,
  certificateTemplate,
  onSubmit,
  onCloseClick,
}: AddRecipientModalProps) => {
  const parameterKeys = certificateTemplate.parameters
    .filter((param) => {
      return !ignoredParams.includes(param.key);
    })
    .map((param) => param.key);

  const validation = useFormik({
    initialValues: {
      recipientEmail: '',
      ...parameterKeys.reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      recipientEmail: Yup.string().required('Please enter recipient email'),
    }),
    onSubmit: (values) => {
      const paramValues = certificateTemplate.parameters
        .filter((param) => param.key === 'date' || !ignoredParams.includes(param.key))
        .map((param) => {
          if (param.key === 'date') {
            if (!param.value)
              return {
                ...param,
                value: 'ENG',
              };
            return {
              ...param,
            };
          }

          return {
            ...param,
            value: (values[param.key] as string) || '',
          };
        });

      onSubmit({
        certificate: {
          recipientEmail: values.recipientEmail,
          parameters: paramValues,
        },
        batchId: batch._id,
      });
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='lg'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Add recipient</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4'>
          <div>
            <Form onSubmit={validation.handleSubmit} autoComplete='off' className='mt-3'>
              <Row>
                <FormGroup className='mb-4' row>
                  <Label
                    htmlFor='recipientEmail'
                    className='fw-semibold col-form-label col-lg-3'
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                  >
                    Email
                  </Label>
                  <Col lg={9}>
                    <Input
                      id='recipientEmail'
                      className='form-control'
                      name='recipientEmail'
                      style={{ marginTop: '8px', marginBottom: '8px' }}
                      rows={7}
                      placeholder='Enter recipient email...'
                      onChange={(e) => {
                        validation.handleChange(e);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.recipientEmail || ''}
                      invalid={
                        validation.touched.recipientEmail && validation.errors.recipientEmail
                          ? true
                          : false
                      }
                    />
                    {validation.touched.recipientEmail && validation.errors.recipientEmail ? (
                      <FormFeedback type='invalid'>{validation.errors.recipientEmail}</FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                {certificateTemplate.parameters
                  .filter((param) => {
                    return !ignoredParams.includes(param.key);
                  })
                  .map((param) => (
                    <React.Fragment key={param._id}>
                      <FormGroup className='mb-4' row>
                        <Label
                          htmlFor={param.key}
                          style={{ marginTop: '8px', marginBottom: '8px' }}
                          className='fw-semibold col-form-label col-lg-3'
                        >
                          {param.name}
                        </Label>
                        <Col lg={9}>
                          <Input
                            id={param.key}
                            className='form-control'
                            name={param.key}
                            onChange={(e) => {
                              validation.handleChange(e);
                            }}
                            style={{ marginTop: '8px', marginBottom: '8px' }}
                            type='text'
                            placeholder={`Enter ${param.name} ...`}
                          />
                        </Col>
                      </FormGroup>
                    </React.Fragment>
                  ))}
              </Row>
              <Row className='justify-content-end'>
                <div className='text-end'>
                  <Button type='submit' color='primary'>
                    Add recipient
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

interface CreateRecipientModalProps {
  // specialParamsMap: Map<string, string>;
  specialParams: Array<string>;
  show: boolean;
  certificateTemplate: CertificateTemplate;
  onSubmit: (
    certList: Array<{
      recipientEmail: string;
      parameters: CertParameter[];
    }>
  ) => void;
  onCloseClick: () => void;
}

export const CreateRecipientModal = ({
  show,
  certificateTemplate,
  // specialParamsMap,
  specialParams,
  onSubmit,
  onCloseClick,
}: CreateRecipientModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string>('');

  const convertSheetToJSON = async (f: File) => {
    const fileBuffer: ArrayBuffer = await f.arrayBuffer();
    const workbook: WorkBook = read(fileBuffer, { type: 'buffer' });
    const { SheetNames } = workbook;
    const worksheet: WorkSheet = workbook.Sheets[SheetNames[0]];
    const studentData = utils.sheet_to_json(worksheet);

    return studentData;
  };

  const handleConvert = () => {
    if (file) {
      convertSheetToJSON(file)
        .then((datas) => {
          const newrecipients = datas.map((data) => {
            return convertToAddCertFormat({
              template: certificateTemplate,
              data: data,
              specialParams,
            });
          });

          const validrecipients: Array<{
            recipientEmail: string;
            parameters: CertParameter[];
          }> = [];
          newrecipients.forEach((recipient) => {
            if (recipient) validrecipients.push(recipient);
          });

          onSubmit(validrecipients);
        })
        .catch((error: unknown) => {
          handleAxiosError(error, (message) => toast.error(message));
        });
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile: File | undefined = event.target.files?.[0];
    if (targetFile) {
      if (targetFile.type === mime.getType('xlsx')) {
        setUploadError('');
        setFile(targetFile);
      } else {
        setUploadError('File format is incorrect');
      }
    } else {
      setUploadError('Please select a file');
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='lg'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Add recipient</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 text-center'>
          <div>
            <Label htmlFor='formFileLg' className='form-label'>
              Upload an Excel file (.xlsx) following this{' '}
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  textDecoration: 'underline',
                  color: '#0b2878',
                  padding: '0px',
                }}
                onClick={() => downloadSheetTemplate(certificateTemplate.parameters)}
              >
                format
              </button>
            </Label>
            <div className='table-responsive'>
              <Table className=' table-borderless'>
                <thead>
                  <tr>
                    <th scope='col'>Email</th>
                    {certificateTemplate.parameters
                      .filter((param) => {
                        return !ignoredParams.includes(param.key);
                      })
                      .map((parameter: CertParameter) => (
                        <th key={parameter._id} scope='col'>
                          {parameter.name}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>abc@hcmut.edu.vn</td>
                    {certificateTemplate.parameters
                      .filter((param) => {
                        return !ignoredParams.includes(param.key);
                      })
                      .map((parameter: CertParameter) => (
                        <td
                          key={parameter._id}
                          // style={{
                          //   whiteSpace: 'pre-line',
                          //   display: 'flex',
                          //   alignItems: 'start',
                          //   justifyContent: 'start',
                          // }}
                          style={{ minWidth: '120px' }}
                        >
                          abc
                        </td>
                      ))}
                  </tr>
                </tbody>
              </Table>
            </div>
            <Input
              className='form-control mt-3'
              id='formFileLg'
              type='file'
              onChange={handleFileUpload}
              invalid={uploadError !== ''}
            />
            {uploadError ? (
              <FormFeedback type='invalid' className='font-size-12'>
                {uploadError}
              </FormFeedback>
            ) : null}
          </div>
          <div className='text-end mt-3'>
            <Button
              type='button'
              color='primary'
              disabled={!file}
              onClick={() => {
                handleConvert();
              }}
            >
              Add recipient
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
