import { Button, Modal, ModalBody } from 'reactstrap';

type VerificationRequestModalProps = {
  show: boolean;
  onSendClick: () => Promise<void>;
  onCloseClick: () => void;
};

const VerificationRequestModal = ({
  show,
  onCloseClick,
  onSendClick,
}: VerificationRequestModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered size='lg' className='tw-rounded-[0.5rem]'>
      <ModalBody>
        <div className='tw-flex tw-flex-col tw-gap-y-7 tw-items-start'>
          <span className='tw-font-bold tw-text-[#3D4863] tw-text-2xl'>
            Send verification request
          </span>

          <p>
            Once your verification has been sent, this event can not be modified or deleted until
            there is a response for it.
            <br></br>
            <br></br>
            Proceed to do this?
          </p>

          <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
            <Button type='button' color='primary' outline onClick={onCloseClick}>
              Cancel
            </Button>
            <Button
              type='button'
              color='primary'
              onClick={() => {
                onSendClick();
              }}
            >
              Confirm and send
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VerificationRequestModal;
