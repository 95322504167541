type Quote = {
  content: string;
  author: string;
};

const quotes: Array<Quote> = [
  {
    content: 'Creativity is seeing what others see and thinking what no one else ever thought.',
    author: 'Albert Einstein',
  },
  {
    content: 'Don’t wait for inspiration. It comes while working.',
    author: 'Henri Matisse',
  },
  {
    content:
      'Creativity is inventing, experimenting, growing, taking risks, breaking rules, making mistakes, and having fun.',
    author: 'Mary Lou Cook',
  },
  {
    content: 'Great things are done by a series of small things brought together.',
    author: 'Vincent Van Gogh',
  },
  {
    content: 'The only difference between success and failure is the ability to take action.',
    author: 'Alexander Graham Bell',
  },
  {
    content:
      'Talent is cheaper than table salt. What separates the talented individual from the successful one is a lot of hard work.',
    author: 'Stephen King',
  },
  {
    content: 'Diamonds are nothing more than chunks of coal that stuck to their jobs.',
    author: 'Malcolm Forbes',
  },
  {
    content: 'Perseverance, the secret of all triumphs.',
    author: 'Victor Hugo',
  },
];

const getRandomQuote = (): Quote => {
  if (quotes.length <= 0) {
    return { content: '', author: '' };
  }

  const quote = quotes[Math.floor(Math.random() * quotes.length)];

  return quote;
};

export default getRandomQuote;
