import { Button, Modal, ModalBody } from 'reactstrap';

type SendRequestModalProps = {
  show: boolean;
  onSendClick: () => void;
  onCloseClick: () => void;
};

const SendRequestModal = ({ show, onCloseClick, onSendClick }: SendRequestModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='md'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Send modification request</h5>
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-gap-4 '>
          <div className='tw-whitespace-pre-wrap'>
            Once your request has been sent, you cannot undo it!
            <br />
            <br />
            Proceed to do this?
          </div>
          <div className='tw-relative tw-flex tw-items-center tw-gap-4 tw-self-end'>
            <Button
              type='button'
              color='danger'
              className='tw-mt-[1rem]'
              disabled={false}
              onClick={onCloseClick}
            >
              Cancel
            </Button>
            <Button
              type='button'
              color='success'
              className='tw-mt-[1rem]'
              disabled={false}
              onClick={onSendClick}
            >
              Send
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default SendRequestModal;
