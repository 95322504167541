import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import StatusBadge from 'Components/CommonForBoth/StatusBadge';
import { handleAxiosError } from 'helpers/handleError';
import { subjectTranslate } from 'helpers/translate';
import useTitle from 'hooks/useTitle';
import ContactSystemService from 'services/contact-system.service';
import { SupportTicket, SupportTicketStatus, SupportTicketSubject } from 'types';

import SupportTicketTab from './SupportTicket';
import UnitTicketTab from './UnitTicket';

const initialValue = {
  _id: '',
  title: '',
  subject: SupportTicketSubject.COURSE_REGISTRATION,
  status: SupportTicketStatus.UNVERIFIED,
  messages: [],
  createdAt: 0,
  createdBy: {
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
    units: [],
  },
  updatedAt: 0,
};

enum Tab {
  SUPPORT_TICKET = 'SUPPORT_TICKET',
  UNIT_TICKET = 'UNIT_TICKET',
}

const DetailTicket = () => {
  useTitle('Ticket detail', {
    restoreOnUnmount: true,
  });

  const { id } = useParams();
  const [supportTicket, setSupportTicket] = useState<SupportTicket>(initialValue);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.SUPPORT_TICKET);

  const fetchSupportTicket = useCallback(async () => {
    try {
      const { data } = await ContactSystemService.getSupportTicketById(id || '');
      const { payload } = data;
      setSupportTicket(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  useEffect(() => {
    fetchSupportTicket();
  }, [fetchSupportTicket]);

  const toggleTab = (tab: Tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title='Contact system'
            breadcrumbItem='Support ticket'
            backTo='/contact-system'
          />
        </Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className='card-title mb-2'>{supportTicket.title}</CardTitle>
                <div style={{ marginTop: '16px' }}>by {supportTicket.createdBy.name}</div>
                <Row className='mb-4'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      marginTop: '16px',
                    }}
                  >
                    <div>
                      <Badge color='primary' className='font-size-16 mb-2'>
                        {subjectTranslate(supportTicket.subject)}
                      </Badge>
                    </div>
                    <div>
                      <StatusBadge value={supportTicket.status} />
                    </div>
                  </div>
                </Row>
                <Nav tabs role='tablist' className='nav-tabs-custom'>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === Tab.SUPPORT_TICKET,
                      })}
                      onClick={() => {
                        toggleTab(Tab.SUPPORT_TICKET);
                      }}
                    >
                      Support ticket
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === Tab.UNIT_TICKET,
                      })}
                      onClick={() => {
                        toggleTab(Tab.UNIT_TICKET);
                      }}
                    >
                      Unit ticket
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className='crypto-buy-sell-nav-content p-4'>
                  <TabPane tabId={Tab.SUPPORT_TICKET} id={Tab.SUPPORT_TICKET}>
                    <SupportTicketTab
                      id={id}
                      ticket={supportTicket}
                      status={supportTicket.status}
                      refetch={fetchSupportTicket}
                    />
                  </TabPane>

                  <TabPane tabId={Tab.UNIT_TICKET} id={Tab.UNIT_TICKET}>
                    <UnitTicketTab
                      supportTicketId={id}
                      supportTicketStatus={supportTicket.status}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DetailTicket;
