import { useState } from 'react';
import { Input, Table } from 'reactstrap';

import Pagination from 'Components/Common/Pagination';
import { Participant } from 'types';

type VerificationRequestParticipantsProps = {
  participants: Array<Participant>;
};

const VerificationRequestParticipants = ({
  participants,
}: VerificationRequestParticipantsProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredParticipants, setFilteredParticipants] = useState(participants);

  const searchUsers = () => {
    const input = document.getElementById('searchbarParticipant') as HTMLInputElement;
    const filteredList = participants.filter((participant: Participant) => {
      return (
        participant.user.name.toLowerCase().includes(input.value.toLowerCase()) ||
        participant.user.email.toLowerCase().includes(input.value.toLowerCase())
      );
    });
    setFilteredParticipants(filteredList);
  };
  return (
    <div className='tw-relative tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-6'>
      <div className='tw-font-bold'>Participants</div>
      <div className='tw-flex tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
        <i className='bx bx-search-alt' />
        <Input
          className='!tw-border-0'
          onKeyUp={searchUsers}
          id='searchbarParticipant'
          type='text'
          placeholder='Search...'
        />
      </div>
      <div className='table-responsive'>
        <Table className='project-list-table table-nowrap align-middle table-borderless'>
          <thead className='tw-font-bold tw-text-white'>
            <tr>
              <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                #
              </th>
              <th className='!tw-bg-[#3D4863]' scope='col'>
                Name
              </th>
              <th className='!tw-bg-[#3D4863]' scope='col'>
                Email
              </th>
              <th className='!tw-bg-[#3D4863]' scope='col'>
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredParticipants.map((participant: Participant) => (
              <tr key={participant._id}>
                <td className='text-truncate font-size-14'>
                  {participants.indexOf(participant) + 1}
                </td>
                <td className='text-truncate font-size-14'>{participant.user.name}</td>
                <td className='text-truncate font-size-14'>{participant.user.email}</td>
                <td className='text-truncate font-size-14'>{participant.role}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className='tw-relative tw-self-center tw-mt-4'>
        <Pagination
          count={participants.length}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default VerificationRequestParticipants;
