import { useNavigate } from 'react-router-dom';

import { Event } from 'types';

import defaultEventThumbnail from '../../assets/images/eventDefaultThumnail.png';

type VerificationRequestCardProps = {
  event: Event;
};

const VerificationRequestCard = ({ event }: VerificationRequestCardProps) => {
  const navigate = useNavigate();

  return (
    <div className='tw-relative tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-[17.5rem] lg:tw-w-[20rem] 2xl:tw-w-[25rem] tw-h-[9rem] tw-overflow-hidden tw-shadow-xl tw-p-2 tw-bg-white tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#3D4863]'>
      <img
        alt={`thumbnail of ${event.title}`}
        src={event.poster?.original || defaultEventThumbnail}
        className='tw-w-[6rem] tw-h-[8rem] tw-object-cover tw-rounded-lg'
      />
      <div className='tw-relative tw-flex tw-flex-col tw-w-[10rem] lg:tw-w-[12.5rem] 2xl:tw-w-[17rem] tw-h-[8rem] tw-text-[12px] tw-gap-2'>
        <div className='tw-font-bold tw-truncate tw-w-full'>{event.title}</div>
        <div className='tw-overflow-y-hidden tw-h-[2rem] tw-w-full'>{event.description}</div>
        <div className='tw-truncate tw-w-full'>by {event.createdBy.name}</div>
        <div
          onClick={() => navigate('/event/verification-requests/' + event._id)}
          className='tw-self-end tw-font-bold tw-cursor-pointer tw-mt-4'
        >
          View detail
        </div>
      </div>
    </div>
  );
};

export default VerificationRequestCard;
