import classnames from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import timeSince from 'helpers/time-since';
import useTitle from 'hooks/useTitle';
import CertificateBatchService from 'services/certificate-batch.service';
import CertificateService from 'services/certificate.service';
import { CertParameter, Certificate, CertificateBatch, CertificateTemplate } from 'types';

import tempThumnbnail from '../../assets/images/tempThumbnail.png';

import { AddRecipientModal } from './AddRecipientModal';
import { CertificateContainer } from './CertificateContainer';
import DownloadBatchModal from './DownloadBatchModal';
import EditParamModal from './EditParamModal';

// import CertificateEditspace from './CertificateEditspace';

const limit = 20;

const specialParameters = [
  'signee',
  'signeePosition',
  'signature',
  'date',
  'decisionId',
  'decisionDate',
  'decisionQr',
];

const initialTemplate: CertificateTemplate = {
  _id: '',
  name: '',
  description: '',
  parameters: [],
  background: {
    _id: '',
    originalName: '',
    filename: '',
    directoryId: '',
    refName: '',
    size: 0,
    mimetype: '',
    createdAt: 0,
    updatedAt: 0,
  },
  backgroundUrl: '',
  thumbnail: '',
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};
const initialBatch: CertificateBatch = {
  _id: '',
  name: '',
  batchId: '',
  description: '',
  template: initialTemplate,
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  signee: '',
  signeePosition: '',
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};

enum Tab {
  OVERVIEW = 'OVERVIEW',
  DETAIL = 'DETAIL',
  RECIPIENT = 'RECIPIENT',
}

type UpdateCertificateProps = {
  name: string;
  description?: string;
};

type CertificateCardProps = {
  certificate: Certificate;
  displayCertificate: Certificate | null;
  background: string;
  setDisplay: (certificate: Certificate) => void;
};

const CertificateCard = ({
  certificate,
  background,
  displayCertificate,
  setDisplay,
}: CertificateCardProps) => {
  const recipientName = certificate.parameters.find((param) => param.name === 'Name')?.value;
  const recipientEmail = certificate.parameters.find((param) => param.name === 'Email')?.value;

  return (
    <Col xs={12} sm={6} xl={4}>
      <Card
        className={`hover-pointer border ${
          displayCertificate === certificate ? 'bg-primary text-white' : ''
        }`}
        onClick={() => {
          setDisplay(certificate);
        }}
      >
        <CardBody>
          <div className='cert-item-imgCtn'>
            <img
              alt={certificate.certificateId}
              className='px-4 py-4 img-fluid mx-auto d-block rounded'
              src={background}
            />
          </div>
          <div className='d-flex mt-4'>
            <div className='flex-grow-1 overflow-hidden'>
              <h5
                className={`text-truncate font-size-15 ${
                  displayCertificate === certificate ? 'text-white' : 'text-dark'
                }`}
              >
                {certificate.certificateId}
              </h5>
              <div
                className={`text-truncate font-size-15 ${
                  displayCertificate === certificate ? 'text-white' : 'text-dark'
                }`}
              >
                {recipientName}
              </div>
              <div
                className={`text-truncate font-size-15 ${
                  displayCertificate === certificate ? 'text-white' : 'text-dark'
                }`}
              >
                {recipientEmail}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const DetailCertificate = () => {
  useTitle('Edit certificate', {
    restoreOnUnmount: true,
  });

  const { id } = useParams();
  const [certificateBatch, setCertificateBatch] = useState<CertificateBatch>(initialBatch);
  const [cerficateList, setCertificateList] = useState<Certificate[]>([]);
  const [certificateCount, setCertificateCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [displayCertificate, setDisplayCertificate] = useState<Certificate | null>(null);
  const [editCertificate, setEditCertificate] = useState<Certificate | null>({
    _id: '',
    certificateId: '',
    recipient: '',
    batch: initialBatch,
    template: initialTemplate,
    thumbnail: '',
    parameters: [],
    createdAt: 0,
    updatedAt: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  // state to manage fetching data process
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.OVERVIEW);
  const [addRecipientModal, setAddRecipientModal] = useState<boolean>(false);
  const [editParamModal, setEditParamModal] = useState<boolean>(false);
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);

  const certificateTemplate = certificateBatch.template as CertificateTemplate;

  const getFileFromCanvas = useCallback((canvas: fabric.Canvas) => {
    if (!canvas) return Promise.reject('Canvas is not found');
    return new Promise<File>((resolve, reject) => {
      canvas.getElement().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'thumbnail.jpeg', { type: 'image/jpeg' });
          resolve(file);
        } else {
          reject('Error while getting file from canvas');
        }
      });
    });
  }, []);

  const generateCertificateThumbnail = useCallback(
    async (_id: string, canvas: fabric.Canvas) => {
      console.log('generate thumbnail for ', _id);
      const file: File = await getFileFromCanvas(canvas);
      try {
        setLoading(true);
        await CertificateService.generateThumbnail(_id || '', file);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        setLoading(false);
      }
    },
    [getFileFromCanvas]
  );

  const onCertificateLoad = useCallback(
    (canvas: fabric.Canvas) => {
      _.debounce(() => {
        // Generate thumbnail for certificate if it doesn't have one
        if (displayCertificate && !displayCertificate.thumbnail && canvas)
          generateCertificateThumbnail(displayCertificate._id, canvas).catch(() => {});
      }, 500)();
    },
    [generateCertificateThumbnail, displayCertificate]
  );

  const fetchCertificateList = useCallback(
    async (batchId: string) => {
      try {
        setLoading(true);
        const { data } = await CertificateBatchService.getCertificateListByBatchId(
          batchId || '',
          limit,
          limit * (currentPage - 1)
        );
        const { payload } = data;
        setCertificateList(payload.certificateList);
        setCertificateCount(payload.count);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        setLoading(false);
      }
    },
    [currentPage]
  );

  const fetchCertificateBatch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await CertificateBatchService.getCertificateBatchById(id || '');
      const { payload } = data;
      setCertificateBatch(payload);
      await fetchCertificateList(payload._id);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  }, [id, fetchCertificateList]);

  const downloadBatch = async (batch: CertificateBatch, stopLoad: () => void) => {
    setLoading(true);
    toast.success(`Downloading ${batch.name} ...`);
    try {
      const { data } = await CertificateBatchService.downloadCertificateBatch(certificateBatch._id);

      const blob: Blob = new Blob([data]);
      const url: string = URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a');
      document.body.appendChild(link);

      link.href = url;
      link.download = `${certificateBatch.name}.zip`;
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setOpenDownloadModal(false);
      setLoading(false);
      stopLoad();
    }
  };

  const updateCertificateBatch = async ({ name, description }: UpdateCertificateProps) => {
    try {
      setLoading(true);
      await CertificateBatchService.editCertificateBatch(
        certificateBatch._id,
        name,
        description || ''
      );
      await fetchCertificateBatch();
      toast.success('Updated successfully');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const addCertificate = async (newCert: {
    certificate: {
      recipientEmail: string;
      parameters: CertParameter[];
    };
    batchId: string;
  }) => {
    try {
      setLoading(true);
      const { data } = await CertificateService.addCertificate(
        {
          recipientEmail: newCert.certificate.recipientEmail,
          parameters: newCert.certificate.parameters,
        },
        newCert.batchId
      );
      const { payload } = data;
      await fetchCertificateBatch();
      setDisplayCertificate(payload.addCertificate);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setAddRecipientModal(false);
      setLoading(false);
    }
  };

  const updateCertificateParam = async (certId: string, params: CertParameter[], email: string) => {
    try {
      setLoading(true);
      const { data } = await CertificateService.updateCertificateParams(certId, params, email);
      const { payload } = data;
      await fetchCertificateBatch();
      setEditCertificate(payload);
      setEditParamModal(false);
      toast.success('Update successfully!');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const deleteCertificate = async (certificateId: string) => {
    try {
      setLoading(true);
      await CertificateService.deleteCertificate(certificateId);
      await fetchCertificateBatch();
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const validation = useFormik({
    initialValues: certificateBatch,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your batch name'),
      description: Yup.string().default(''),
    }),
    onSubmit: (values) => {
      updateCertificateBatch({
        name: values.name,
        description: values.description,
        // signee: values.signee,
        // signeePosition: values.signeePosition,
      });
    },
  });

  useEffect(() => {
    setDisplayCertificate(null);
  }, [activeTab]);

  useEffect(() => {
    fetchCertificateBatch();
  }, [fetchCertificateBatch]);

  if (isLoading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <AddRecipientModal
        batch={certificateBatch}
        certificateTemplate={certificateTemplate}
        show={addRecipientModal}
        onCloseClick={() => {
          setAddRecipientModal(false);
        }}
        onSubmit={(newCert: {
          certificate: {
            recipientEmail: string;
            parameters: CertParameter[];
          };
          batchId: string;
        }) => addCertificate(newCert)}
      />

      <EditParamModal
        certificate={editCertificate}
        certificateTemlate={certificateTemplate}
        show={editParamModal}
        onCloseClick={() => {
          setEditCertificate(null);
          setEditParamModal(false);
        }}
        onSubmit={updateCertificateParam}
      />

      <DownloadBatchModal
        batch={certificateBatch}
        batchCount={certificateCount}
        show={openDownloadModal}
        onDownloadClick={downloadBatch}
        onCloseClick={() => setOpenDownloadModal(false)}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Certificate' breadcrumbItem='Edit certificate' backTo='/certificate' />

          <Row>
            <Nav tabs role='tablist' className='nav-tabs-custom'>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === Tab.OVERVIEW,
                  })}
                  onClick={() => {
                    setActiveTab(Tab.OVERVIEW);
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === Tab.DETAIL,
                  })}
                  onClick={() => {
                    setActiveTab(Tab.DETAIL);
                  }}
                >
                  Detail
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === Tab.RECIPIENT,
                  })}
                  onClick={() => {
                    setActiveTab(Tab.RECIPIENT);
                  }}
                >
                  Recipient
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={Tab.OVERVIEW} id={Tab.OVERVIEW}>
                    <CardTitle
                      className='mb-4'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>Template overview</div>
                    </CardTitle>

                    <Row className='mb-4'>
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        <CardTitle style={{ alignSelf: 'start' }}>Preview</CardTitle>
                        <div></div>
                        <CertificateContainer
                          canvasId='detailAdminCertificateCanvas'
                          templateBackground={certificateTemplate.backgroundUrl}
                          certificateTemplate={certificateTemplate}
                          displayCertificate={displayCertificate}
                          onLoad={onCertificateLoad}
                          editMode={false}
                          buttonOptions={{
                            copyButton: true,
                            viewButton: true,
                            downloadButton: true,
                          }}
                        />
                      </div>
                    </Row>

                    <Row className='mb-4'>
                      <CardTitle className='mb-4'>Certificate list</CardTitle>
                      <div className='text-end mb-4'>
                        <Button
                          type='button'
                          color='primary'
                          disabled={loading}
                          onClick={() => {
                            setOpenDownloadModal(true);
                          }}
                        >
                          Download batch
                        </Button>
                      </div>
                      <Row>
                        {cerficateList.map((certificate: Certificate) => {
                          return (
                            <CertificateCard
                              key={certificate._id}
                              background={certificate.thumbnail || tempThumnbnail}
                              certificate={certificate}
                              displayCertificate={displayCertificate}
                              setDisplay={(_certificate) => {
                                setDisplayCertificate(_certificate);
                                window.scroll(0, 0);
                              }}
                            />
                          );
                        })}
                      </Row>
                      <Pagination
                        count={certificateCount}
                        pageSize={limit}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Row>
                  </TabPane>
                  <TabPane tabId={Tab.DETAIL} id={Tab.DETAIL}>
                    <CardTitle
                      className='mb-4'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      Detail
                    </CardTitle>
                    <Form onSubmit={validation.handleSubmit} autoComplete='off' className='mt-3'>
                      <Row>
                        <FormGroup className='mb-4' row>
                          <Label htmlFor='name' className='fw-semibold col-form-label col-lg-3'>
                            Name
                          </Label>
                          <Col lg={9}>
                            <Input
                              id='name'
                              className='form-control'
                              name='name'
                              type='text'
                              placeholder='Enter certificate name...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ''}
                              invalid={
                                validation.touched.name && validation.errors.name ? true : false
                              }
                            />
                            {validation.touched.name && validation.errors.name ? (
                              <FormFeedback type='invalid'>{validation.errors.name}</FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className='mb-4' row>
                          <Label
                            htmlFor='description'
                            className='fw-semibold col-form-label col-lg-3'
                          >
                            Description
                          </Label>
                          <Col lg={9}>
                            <Input
                              tag='textarea'
                              id='description'
                              rows={7}
                              className='form-control'
                              name='description'
                              placeholder='Enter certificate description...'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ''}
                              invalid={
                                validation.touched.description && validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description && validation.errors.description ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                      </Row>
                      <Row>
                        <Col sm={4} lg={3}>
                          <div className='fw-semibold mb-3'>Batch's id</div>
                        </Col>
                        <Col>
                          <p className='text-muted'>{certificateBatch.batchId}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} lg={3}>
                          <div className='fw-semibold mb-3'>Signee</div>
                        </Col>
                        <Col>
                          <p className='text-muted'>
                            {certificateBatch.signeePosition} {certificateBatch.signee}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} lg={3}>
                          <div className='fw-semibold mb-3'>Author</div>
                        </Col>
                        <Col>
                          <p className='text-muted'>{certificateBatch.createdBy.name}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} lg={3}>
                          <div className='fw-semibold mb-3'>Create date</div>
                        </Col>
                        <Col>
                          <p className='text-muted'>
                            {Date.now() - certificateBatch.createdAt >= 846000000
                              ? new Date(certificateBatch.createdAt).toLocaleString('vi-VN')
                              : timeSince(certificateBatch.createdAt)}
                          </p>
                        </Col>
                      </Row>
                      {certificateBatch.decisionId && (
                        <Row>
                          <Col sm={4} lg={3}>
                            <div className='fw-semibold mb-3'>Decision's ID</div>
                          </Col>
                          <Col>
                            <p className='text-muted'>{certificateBatch.decisionId}</p>
                          </Col>
                        </Row>
                      )}
                      {certificateBatch.decisionDate && (
                        <Row>
                          <Col sm={4} lg={3}>
                            <div className='fw-semibold mb-3'>Decision's issue date</div>
                          </Col>
                          <Col>
                            <p className='text-muted'>
                              {Date.now() - certificateBatch.decisionDate >= 846000000
                                ? new Date(certificateBatch.decisionDate).toLocaleString('vi-VN')
                                : timeSince(certificateBatch.decisionDate)}
                            </p>
                          </Col>
                        </Row>
                      )}
                      <Row className='justify-content-end'>
                        <div className='text-end'>
                          <Button type='submit' color='primary' disabled={loading}>
                            Save
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane tabId={Tab.RECIPIENT} id={Tab.RECIPIENT}>
                    <CardTitle
                      className='mb-4'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      Recipients
                    </CardTitle>
                    <div className='text-end'>
                      <Button
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                          setAddRecipientModal(true);
                        }}
                      >
                        Add recipient
                      </Button>
                    </div>
                    <div className='table-responsive'>
                      <Table className='project-list-table table-nowrap align-middle table-borderless'>
                        <thead>
                          <tr>
                            <th scope='col' style={{ width: '100px' }}>
                              #
                            </th>
                            <th scope='col'>Certificate ID</th>
                            {certificateTemplate.parameters
                              .filter((param) => {
                                return (
                                  (!param.paramType || param.paramType === 'text') &&
                                  !specialParameters.includes(param.key)
                                );
                              })
                              .map((param) => (
                                <th scope='col' key={param.key}>
                                  {param.name}
                                </th>
                              ))}
                            {!certificateTemplate.parameters.find(
                              (param) => param.key === 'name'
                            ) && <th scope='col'>Recipient</th>}
                            <th scope='col'>Issued date</th>
                            {!certificateTemplate.parameters.find(
                              (param) => param.key === 'email'
                            ) && <th scope='col'>Recipient's email</th>}
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cerficateList.map((certificate: Certificate) => (
                            <tr key={certificate._id}>
                              <td>
                                <div className='text-truncate font-size-14'>
                                  {cerficateList.indexOf(certificate) + 1}
                                </div>
                              </td>
                              <td>
                                <div className='text-truncate font-size-14'>
                                  {certificate.certificateId}
                                </div>
                              </td>
                              {certificate.parameters
                                .filter((param) => {
                                  return !param.paramType || param.paramType === 'text';
                                })
                                .map((param) => (
                                  <td key={param.key}>
                                    <div className='text-truncate font-size-14'>{param.value}</div>
                                  </td>
                                ))}
                              <td className='d-flex gap-3'>
                                <div className='d-flex gap-3 justify-content-end hover-pointer'>
                                  <i
                                    className='mdi mdi-pencil font-size-18 text-success'
                                    onClick={() => {
                                      setEditCertificate(certificate);
                                      setEditParamModal(true);
                                    }}
                                  />
                                </div>

                                <div className='d-flex gap-3 justify-content-end hover-pointer'>
                                  <i
                                    className='mdi mdi-delete font-size-18 text-danger'
                                    onClick={() => {
                                      deleteCertificate(certificate._id);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={certificateCount}
                      pageSize={limit}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DetailCertificate;
