// import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';

type CreateProgressModalProps = {
  stages: Array<{ name: string; status: 'Waiting' | 'In progress' | 'Done' | 'Skipped' }>;
  show: boolean;
};

const CreateProgressModal = ({ stages, show }: CreateProgressModalProps) => {
  return (
    <Modal isOpen={show} centered={true}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Batch creation in progress ...</h5>
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4'>
          <div>
            Creation in progress. Please do not exit the page until all of the processes are
            completed!
          </div>
          <div className='tw-flex tw-flex-col tw-gap-2 tw-mt-2'>
            {stages.map((stage) => {
              return (
                <div key={stage.name} className='tw-flex tw-relative tw-flex-row tw-gap-2'>
                  <div className='tw-font-bold'>{stage.name}:</div>
                  <div
                    className={`${
                      stage.status === 'Waiting'
                        ? 'tw-text-[#A3ACC2]'
                        : stage.status === 'In progress'
                        ? 'tw-text-[#F1B44C]'
                        : stage.status === 'Done'
                        ? 'tw-text-[#0F9D58]'
                        : 'tw-text-[#EA4335]'
                    }`}
                  >
                    {stage.status}
                  </div>
                </div>
              );
            })}
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default CreateProgressModal;
