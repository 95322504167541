import React, { useEffect, useState } from 'react';
import { Card, Col, Container } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Pagination from 'Components/Common/Pagination';
import { defaultEvent1 } from 'data/defaultEvent';
import useTitle from 'hooks/useTitle';

import VerificationRequestCard from '../VerificationRequestCard';

const limit = 12;

const EventVerificationRequests = () => {
  useTitle('Verification requests', {
    restoreOnUnmount: true,
  });

  const [requestCount, setRequestCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setRequestCount(24);
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Event verification requests' />
        </Container>
        <Col>
          <Card className=' tw-overflow-hidden'>
            <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-gap-5 tw-p-4'>
              <div className='tw-flex tw-flex-col xs:tw-flex-row tw-justify-between xs:tw-items-end'>
                <div className='tw-relative tw-font-bold'>Verification requests</div>
              </div>
              <div className='tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-[1rem] tw-justify-items-center'>
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
                <VerificationRequestCard event={defaultEvent1} />
              </div>
              <Pagination
                count={requestCount}
                pageSize={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventVerificationRequests;
