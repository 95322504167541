import { Card } from 'reactstrap';

import { BarChart } from 'Components/Common/BarChart';
import { ChartData } from 'types';

type EventBarChartProps = {
  title: string;
  charts: Array<ChartData>;
};

const EventBarChart = ({ title, charts }: EventBarChartProps) => {
  return (
    <Card className='tw-relative tw-flex tw-flex-col tw-p-4 xl:tw-col-span-3'>
      <div className='tw-relative tw-font-bold'>{title}</div>
      <div className='tw-relative tw-flex tw-flex-col tw-items-center tw-mt-4 tw-gap-4'>
        {charts.map((chartData) => {
          return (
            <div key={chartData.title} className='tw-flex tw-flex-col tw-items-center'>
              <div className='tw-font-bold tw-mb-2'>{chartData.title}</div>
              <BarChart chartData={chartData} />
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default EventBarChart;
