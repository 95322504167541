import { SupportTicketSubject } from '../types';

export const SupportSubjectInformation: {
  [key: string]: {
    title: string;
  };
} = {
  [SupportTicketSubject.CIVIC_ACTITIVIES]: {
    title: 'Civic Activities',
  },
  [SupportTicketSubject.COURSE_REGISTRATION]: {
    title: 'Course Registration',
  },
  [SupportTicketSubject.GRADE_GRADUATION]: {
    title: 'Grade & Graduation',
  },
  [SupportTicketSubject.SCHOLARSHIP]: {
    title: 'Scholarship',
  },
  [SupportTicketSubject.TUITION_FEE]: {
    title: 'Tuition Fee',
  },
};
