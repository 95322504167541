import { Badge } from 'reactstrap';

import { SupportTicketStatus } from 'types';

type StatusProps = {
  value: SupportTicketStatus;
};

const StatusBadge = (status: StatusProps) => {
  switch (status.value) {
    case SupportTicketStatus.FINISHED:
      return (
        <Badge color='success' className='font-size-16 mb-2'>
          Finished
        </Badge>
      );
    case SupportTicketStatus.PENDING:
      return (
        <Badge color='warning' className='font-size-16 mb-2'>
          Pending
        </Badge>
      );
    case SupportTicketStatus.REJECTED:
      return (
        <Badge color='danger' className='font-size-16 mb-2'>
          Rejected
        </Badge>
      );
    case SupportTicketStatus.PROCESSING:
      return (
        <Badge color='light' className='font-size-16 mb-2 text-white'>
          Processing
        </Badge>
      );
    default:
      return (
        <Badge color='light' className='font-size-16 mb-2'>
          Unverified
        </Badge>
      );
  }
};

export default StatusBadge;
