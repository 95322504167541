import React from 'react';

const Footer = () => {
  const getYearNow = () => {
    return new Date().getFullYear();
  };

  return (
    <React.Fragment>
      <footer className='tw-h-[3.5rem] tw-w-screen tw-absolute tw-flex tw-flex-row tw-justify-between tw-items-center tw-pr-[1.5rem] sm:tw-pr-[2.5rem] tw-text-primary tw-pl-[1.5rem] sm:tw-pl-[0rem] tw-bottom-0 tw-bg-white'>
        <div className='tw-flex tw-flex-row tw-gap-[1.5rem] tw-h-full tw-items-center'>
          <div className='tw-bg-primary tw-h-full tw-w-[2.5rem] tw-hidden sm:tw-flex' />
          <a
            href='https://www.facebook.com/bkquocte'
            target='_blank'
            rel='noopener noreferrer'
            className='tw-hidden sm:tw-flex tw-justify-center tw-items-center'
          >
            <i className='bx bxl-facebook tw-text-[1.5rem]'></i>
          </a>
          <a
            href='https://www.instagram.com/quoctebachkhoa'
            target='_blank'
            rel='noopener noreferrer'
            className='tw-hidden sm:tw-flex tw-justify-center tw-items-center'
          >
            <i className='bx bxl-instagram tw-text-[1.5rem] tw-flex tw-justify-center tw-items-center'></i>
          </a>
          <a
            href='https://oisp.hcmut.edu.vn/'
            target='_blank'
            rel='noopener noreferrer'
            className='tw-hidden sm:tw-flex tw-justify-center tw-items-center'
          >
            <i className='bx bx-globe tw-text-[1.5rem] tw-flex tw-justify-center tw-items-center'></i>
          </a>
          <div className='tw-hidden lg:tw-flex'>OFFICE FOR INTERNATIONAL STUDY PROGRAMS</div>
          <div>{getYearNow()} © OISP.</div>
        </div>
        <div> @{getYearNow()} by Fessior Community</div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
