import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, UnitTicket, UnitTicketSubject } from 'types';

type PreviewFile = File & {
  preview: string;
};

const UnitTicketService = {
  getUnitTicketList: async (limit: number, offset = 0) => {
    return axios.get<
      ResponseData<{
        count: number;
        unitTicketList: UnitTicket[];
      }>
    >(`${API_URL}admin/unit_ticket?limit=${limit}&offset=${offset}`);
  },
  getUnitTicketById: async (id: string) => {
    return axios.get<ResponseData<UnitTicket>>(`${API_URL}admin/unit_ticket/${id}`);
  },
  createUnitTicket: async (
    title: string,
    initialContent: string,
    subject: UnitTicketSubject | undefined,
    supportTicketId: string,
    unitId: string,
    files: PreviewFile[]
  ) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('initialContent', initialContent);
    formData.append('supportTicketId', supportTicketId);
    formData.append('unitId', unitId);
    subject && formData.append('subject', subject);
    // console.log(formData.get('supportTicketId'));
    files.forEach((file) => {
      formData.append('file', file);
    });
    return axios.post<ResponseData<UnitTicket>>(`${API_URL}admin/unit_ticket/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  downloadAttachment: async (id: string, attachment_id: string) =>
    axios.get<ArrayBuffer>(`${API_URL}admin/unit_ticket/${id}/attachment/${attachment_id}`, {
      responseType: 'arraybuffer',
    }),
  addMessage: async (id: string, content: string, files: PreviewFile[]) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });
    formData.append('content', content);
    return axios.post<ResponseData<UnitTicket>>(
      `${API_URL}admin/unit_ticket/${id}/message`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};

export default UnitTicketService;
