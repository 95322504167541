import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import timeSince from 'helpers/time-since';
import UnitTicketService from 'services/unit-ticket.service';
import { SupportTicketStatus, UnitTicket } from 'types';

import CreateUnitTicket from './CreateUnitTicket';

type UnitCardProps = {
  unitTicket: UnitTicket;
};

const UnitCard = ({ unitTicket }: UnitCardProps) => {
  return (
    <Col xl={4} sm={6}>
      <Link to={`/unit-ticket/${unitTicket._id}`}>
        <Card>
          <CardBody>
            <div className='d-flex'>
              <div className='flex-grow-1 overflow-hidden'>
                <h5 className='text-truncate font-size-15 text-dark'>{unitTicket.title}</h5>
                <p className='text-muted mb-4'>to {unitTicket.unitId.name}</p>
                {/* <Badge color='primary' className='font-size-14 mb-2'>
                  {unitTicket.subject}
                </Badge> */}

                <div className='avatar-group'></div>
              </div>
            </div>
          </CardBody>
          <div className='px-4 py-3 border-top'>
            <ul className='list-inline mb-0'>
              <li className='list-inline-item me-3' id='dueDate'>
                <i className='bx bx-calendar me-1' />{' '}
                {Date.now() - unitTicket.createdAt >= 846000000
                  ? new Date(unitTicket.createdAt).toLocaleString('vi-VN')
                  : timeSince(unitTicket.createdAt)}
              </li>
            </ul>
          </div>
        </Card>
      </Link>
    </Col>
  );
};

const limit = 20;

type UnitTicketTabProps = {
  supportTicketId: string | undefined;
  supportTicketStatus: SupportTicketStatus;
};

enum UnitTicketFunctionPage {
  LIST = 'LIST',
  CREATE = 'CREATE',
}

const UnitTicketTab = ({ supportTicketId, supportTicketStatus }: UnitTicketTabProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [unitTicketCount, setUnitTicketCount] = useState<number>(0);
  const [unitTicketList, setUnitTicketList] = useState<UnitTicket[]>([]);
  const [unitTicketFunctionPage, setUnitTicketFuntionPage] = useState<UnitTicketFunctionPage>(
    UnitTicketFunctionPage.LIST
  );

  const fetchUnitTicketList = async () => {
    try {
      const { data } = await UnitTicketService.getUnitTicketList(limit, limit * (currentPage - 1));
      const { payload } = data;
      const allUnitTicketList = payload.unitTicketList;
      setUnitTicketList(
        allUnitTicketList.filter((unitTicket) => unitTicket.supportTicketId._id === supportTicketId)
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  useEffect(() => {
    UnitTicketService.getUnitTicketList(limit, limit * (currentPage - 1))
      .then((res) => {
        const { data } = res;
        const { payload } = data;
        const allUnitTicketList = payload.unitTicketList;
        setUnitTicketList(
          allUnitTicketList.filter(
            (unitTicket) => unitTicket.supportTicketId._id === supportTicketId
          )
        );
        console.log('fetched');
      })
      .catch((error: unknown) => {
        handleAxiosError(error, (message) => toast.error(message));
      });
  }, [currentPage, supportTicketId]);

  useEffect(() => {
    setUnitTicketCount(unitTicketList.length);
  }, [unitTicketList]);

  if (unitTicketFunctionPage === UnitTicketFunctionPage.CREATE)
    return (
      <React.Fragment>
        <Row className='mb-4'>
          <CreateUnitTicket
            supportTicketId={supportTicketId}
            onCreatedUnitTicket={async () => {
              await fetchUnitTicketList();
              console.log('run this');
              setUnitTicketFuntionPage(UnitTicketFunctionPage.LIST);
            }}
            returnList={() => setUnitTicketFuntionPage(UnitTicketFunctionPage.LIST)}
          />
        </Row>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Row className='mb-4'>
        <div className='text-end'>
          {supportTicketStatus === SupportTicketStatus.PROCESSING && (
            <Button
              type='button'
              color='primary'
              className='ms-1 btn mt-2'
              onClick={() => setUnitTicketFuntionPage(UnitTicketFunctionPage.CREATE)}
            >
              Create new Unit ticket
            </Button>
          )}
          {supportTicketStatus !== SupportTicketStatus.PROCESSING && (
            <div className='ms-1 mt-2'>
              You can not create unit ticket since this ticket is not being processed
            </div>
          )}
        </div>
      </Row>
      <Row>
        {unitTicketList.map((unitTicket: UnitTicket) => {
          return <UnitCard key={unitTicket._id} unitTicket={unitTicket} />;
        })}
      </Row>
      <Pagination
        count={unitTicketCount}
        pageSize={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </React.Fragment>
  );
};

export default UnitTicketTab;
