import { useFormik } from 'formik';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import { handleAxiosError } from 'helpers/handleError';
import { subjectTranslate } from 'helpers/translate';
import useTitle from 'hooks/useTitle';
import UnitTicketService from 'services/unit-ticket.service';
import { Unit, UnitTicketSubject } from 'types';

import ChooseUnitModal from './ChooseUnitModal';

type CreateUnitTicketProps = {
  supportTicketId: string | undefined;
  onCreatedUnitTicket: () => Promise<void>;
  returnList: () => void;
};

type PreviewFile = File & {
  preview: string;
};

const CreateUnitTicket = ({
  supportTicketId,
  onCreatedUnitTicket,
  returnList,
}: CreateUnitTicketProps) => {
  useTitle('Create unit', {
    restoreOnUnmount: true,
  });

  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<PreviewFile[]>([]);
  const [subjectButtonDrop, setSubjectButtonDrop] = useState(false);
  const [openChooseUnitModal, setOpenChooseUnitModal] = useState(false);
  const [receipent, setReceipent] = useState<Unit>();

  const createUnit = async (
    title: string,
    initialContent: string,
    unitId: string,
    chosenSubject: UnitTicketSubject | undefined
  ) => {
    setLoading(true);
    try {
      if (receipent) {
        await UnitTicketService.createUnitTicket(
          title,
          initialContent,
          chosenSubject,
          supportTicketId || '',
          unitId,
          selectedFiles
        );
        await onCreatedUnitTicket();
        toast.success('A new unit has been added successfully');
      } else toast.error('Choose a receipent first');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptedFiles = (files: File[]) => {
    const newFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setSelectedFiles(selectedFiles.concat(newFiles));
  };

  const validation = useFormik({
    initialValues: {
      unitticketname: '',
      unitticketsubject: undefined,
      unitticketmessage: '',
    },
    validationSchema: Yup.object({
      unitticketname: Yup.string().required('Please enter your unit ticket name'),
      unitticketsubject: Yup.mixed<UnitTicketSubject>().oneOf(Object.values(UnitTicketSubject)),
      unitticketmessage: Yup.string().required('Please enter your unit ticket message'),
    }),
    onSubmit: (values) => {
      createUnit(
        values.unitticketname,
        values.unitticketmessage,
        receipent?._id || '',
        values.unitticketsubject
      );
    },
  });

  return (
    <div>
      <ChooseUnitModal
        show={openChooseUnitModal}
        onCloseClick={() => setOpenChooseUnitModal(false)}
        onChosen={(newReceipent: Unit) => {
          setReceipent(newReceipent);
          setOpenChooseUnitModal(false);
        }}
      />

      <Row>
        <div className='text-end'>
          <Button
            type='button'
            color='primary'
            outline
            className='ms-1 btn mt-2'
            onClick={returnList}
          >
            Return to unit ticket list
          </Button>
        </div>
        <Col lg='12'>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Create new unit ticket</CardTitle>
              <Form onSubmit={validation.handleSubmit} autoComplete='off'>
                <FormGroup className='mb-4' row>
                  <Label htmlFor='unitticketname' className='col-form-label col-lg-2'>
                    Unit ticket name
                  </Label>
                  <Col lg={10}>
                    <Input
                      id='unitticketname'
                      name='unitticketname'
                      type='text'
                      placeholder='Enter Unit ticket name...'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.unitticketname || ''}
                      invalid={
                        validation.touched.unitticketname && validation.errors.unitticketname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.unitticketname && validation.errors.unitticketname ? (
                      <FormFeedback type='invalid'>{validation.errors.unitticketname}</FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup className='mb-4' row>
                  <Label htmlFor='subject' className='col-form-label col-lg-2'>
                    Subject
                  </Label>
                  <Col lg={10}>
                    <Dropdown
                      name='unitticketsubject'
                      isOpen={subjectButtonDrop}
                      toggle={() => setSubjectButtonDrop(!subjectButtonDrop)}
                    >
                      <DropdownToggle className='btn btn-secondary' caret>
                        {validation.values.unitticketsubject
                          ? subjectTranslate(validation.values.unitticketsubject)
                          : 'No subject chooen'}
                        <i className='mdi mdi-chevron-down' />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            validation.setFieldValue('unitticketsubject', undefined);
                          }}
                        >
                          None
                        </DropdownItem>
                        {(Object.keys(UnitTicketSubject) as (keyof typeof UnitTicketSubject)[]).map(
                          (subject) => {
                            return (
                              <DropdownItem
                                key={subject}
                                onClick={() => {
                                  validation.setFieldValue('unitticketsubject', subject);
                                }}
                              >
                                {subjectTranslate(subject as UnitTicketSubject)}
                              </DropdownItem>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                <FormGroup className='mb-4' row>
                  <Label htmlFor='unitticketname' className='col-form-label col-lg-2'>
                    Receipent
                  </Label>
                  <Col lg={10}>
                    {receipent && (
                      <Button
                        type='button'
                        outline={receipent ? true : false}
                        disabled={loading}
                        onClick={() => setOpenChooseUnitModal(true)}
                        className='btn btn-secondarye'
                      >
                        {receipent.name}
                      </Button>
                    )}
                    {!receipent && (
                      <Button
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => setOpenChooseUnitModal(true)}
                      >
                        Choose receipent
                      </Button>
                    )}
                  </Col>
                </FormGroup>

                <FormGroup className='mb-4' row>
                  <Label htmlFor='unitticketmessage' className='col-form-label col-lg-2'>
                    Unit ticket message
                  </Label>
                  <Col lg={10}>
                    <Input
                      tag='textarea'
                      id='unitticketmessage'
                      rows={10}
                      name='unitticketmessage'
                      placeholder='Enter Unit ticket message...'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.unitticketmessage || ''}
                      invalid={
                        validation.touched.unitticketmessage && validation.errors.unitticketmessage
                          ? true
                          : false
                      }
                    />
                    {validation.touched.unitticketmessage && validation.errors.unitticketmessage ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.unitticketmessage}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup className='mb-4' row>
                  <Label htmlFor='unitticketmessage' className='col-form-label col-lg-2'>
                    Attachments
                  </Label>
                  <Col lg={10}>
                    <Dropzone
                      onDrop={(acceptedFiles: File[]) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className='dropzone'>
                          <div className='dz-message needsclick' {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className='dz-message needsclick'>
                              <div className='mb-3'>
                                <i className='display-6 text-muted bx bxs-cloud-upload' />
                              </div>
                              <div style={{ fontSize: '16px' }}>
                                Drag and drop a file here or click to upload
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className='list-unstyled mb-0' id='file-previews'>
                      {selectedFiles.map((file: PreviewFile) => {
                        return (
                          <li
                            className='mt-2 dz-image-preview'
                            key={file.name + file.type + selectedFiles.indexOf(file).toString()}
                          >
                            <div className='border rounded'>
                              <div className='d-flex flex-wrap gap-2 p-2'>
                                <div className='flex-shrink-0 me-3'>
                                  <div className='avatar-sm bg-light rounded p-2'>
                                    <img
                                      data-dz-thumbnail=''
                                      className='img-fluid rounded d-block'
                                      src={file.preview}
                                      alt={file.name}
                                    />
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <div className='pt-1'>
                                    <h5 className='fs-md mb-1' data-dz-name>
                                      {file.webkitRelativePath}
                                    </h5>
                                    <strong
                                      className='error text-danger'
                                      data-dz-errormessage
                                    ></strong>
                                  </div>
                                </div>
                                <div className='flex-shrink-0 ms-3'>
                                  <Button
                                    variant='danger'
                                    size='sm'
                                    onClick={() => {
                                      const newFiles = [...selectedFiles];
                                      newFiles.splice(selectedFiles.indexOf(file), 1);
                                      setSelectedFiles(newFiles);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </FormGroup>
                <Row className='justify-content-end'>
                  <div className='text-end'>
                    <Button type='submit' color='primary' disabled={loading}>
                      Create unit ticket
                    </Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateUnitTicket;
