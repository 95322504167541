import { useFormik } from 'formik';
// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import UnitService from 'services/unit.service';
import { Unit, UnitType, User } from 'types';

import AddUserModal from './AddMemberModal';

const initialValue = {
  _id: '',
  name: '',
  description: 'none',
  type: UnitType.ORGANIZATION,
  users: [],
  contactEmail: '',
  contactPhone: '',
  createdAt: 0,
  createdBy: '',
  updatedAt: 0,
};

const EditUnit = () => {
  useTitle('Edit unit', {
    restoreOnUnmount: true,
  });

  const { id } = useParams();
  const [unit, setUnit] = useState<Unit>(initialValue);
  const [memberList, setMemberList] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  // const [memberList, setMemberList] = useState<User[]>([]);

  const fetchUnit = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await UnitService.getUnitById(id || '');
      const { payload } = data;
      setUnit(payload);
      setMemberList(payload.users);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id]);

  type UpdateUnitProps = {
    _id: string;
    name: string;
    description: string;
    type: UnitType;
    users: User[];
    contactEmail: string;
    contactPhone: string;
  };

  const updateUnit = async (updatedUnit: UpdateUnitProps) => {
    try {
      const newUserList = memberList.map((user) => user?._id);
      setLoading(true);
      await UnitService.editUnit(
        updatedUnit._id,
        updatedUnit.name,
        updatedUnit.description,
        updatedUnit.type,
        newUserList,
        updatedUnit.contactEmail,
        updatedUnit.contactPhone
      );
      await fetchUnit();
      toast.success('Updated successfully');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const addMember = (newMembers: User[]) => {
    const newMemberList = memberList.concat(newMembers);
    setMemberList(newMemberList);
  };

  const removeMember = (user: User) => {
    const newMemberList = memberList.filter((member) => {
      return member._id !== user._id;
    });
    setMemberList(newMemberList);
  };

  useEffect(() => {
    fetchUnit();
  }, [fetchUnit]);

  const validation = useFormik({
    initialValues: unit,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your unit name'),
      description: Yup.string().required('Please enter your unit description'),
      type: Yup.string().required('Please enter your unit type'),
      contactEmail: Yup.string()
        .email('Invalid email')
        .required('Please enter your unit contact email'),
      contactPhone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number'),
    }),
    onSubmit: (values) => {
      updateUnit(values);
    },
  });

  return (
    <React.Fragment>
      <AddUserModal
        show={addUserModal}
        oldUserList={memberList}
        onCloseClick={() => {
          setAddUserModal(false);
        }}
        onSubmit={(userList: User[]) => addMember(userList)}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title='Unit Management'
            breadcrumbItem={document.title}
            backTo='/unit-management'
          />
        </Container>
        <Row>
          <Col lg='12'>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>Edit unit</CardTitle>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete='off'
                >
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='name' className='col-form-label col-lg-2'>
                      Name
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='name'
                        className='form-control'
                        name='name'
                        type='text'
                        placeholder='Enter Unit name...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        invalid={validation.touched.name && validation.errors.name ? true : false}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type='invalid'>{validation.errors.name}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='type' className='col-form-label col-lg-2'>
                      Type
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='type'
                        className='form-control'
                        name='type'
                        type='select'
                        placeholder='Enter Unit type...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ''}
                        invalid={validation.touched.type && validation.errors.type ? true : false}
                      >
                        <option value={UnitType.ORGANIZATION}>Organization</option>
                        <option value={UnitType.TEACHER}>Teacher</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type='invalid'>{validation.errors.type}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='description' className='col-form-label col-lg-2'>
                      Description
                    </Label>
                    <Col lg={10}>
                      <Input
                        tag='textarea'
                        id='description'
                        rows={3}
                        className='form-control'
                        name='description'
                        placeholder='Enter Unit description...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                        invalid={
                          validation.touched.description && validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type='invalid'>{validation.errors.description}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='contactEmail' className='col-form-label col-lg-2'>
                      Email
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='contactEmail'
                        name='contactEmail'
                        className='form-control'
                        type='email'
                        placeholder='Enter Unit contact email...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactEmail || ''}
                        invalid={
                          validation.touched.contactEmail && validation.errors.contactEmail
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contactEmail && validation.errors.contactEmail ? (
                        <FormFeedback type='invalid'>{validation.errors.contactEmail}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='contactPhone' className='col-form-label col-lg-2'>
                      Phone number
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='contactPhone'
                        name='contactPhone'
                        className='form-control'
                        type='text'
                        autoComplete='on'
                        placeholder='Enter Unit contact phone...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactPhone || ''}
                        invalid={
                          validation.touched.contactPhone && validation.errors.contactPhone
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contactPhone && validation.errors.contactPhone ? (
                        <FormFeedback type='invalid'>{validation.errors.contactPhone}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label className='col-form-label col-lg-2'>Member</Label>
                    <div className='text-end'>
                      <Button
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                          setAddUserModal(true);
                        }}
                      >
                        Add member
                      </Button>
                    </div>
                    <Table className='project-list-table table-nowrap align-middle table-borderless'>
                      <thead>
                        <tr>
                          <th scope='col' style={{ width: '100px' }}>
                            #
                          </th>
                          <th scope='col'>Name</th>
                          <th scope='col'>Email</th>
                          <th scope='col'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberList.map((member: User) => (
                          <tr key={member._id}>
                            <td>
                              <h5 className='text-truncate font-size-14'>
                                {memberList.indexOf(member) + 1}
                              </h5>
                            </td>
                            <td>
                              <h5 className='text-truncate font-size-14'>{member.name}</h5>
                            </td>
                            <td>{member.email}</td>
                            <td>
                              <div className='d-flex gap-3 justify-content-end'>
                                <i
                                  className='mdi mdi-delete font-size-18 text-danger'
                                  onClick={() => removeMember(member)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroup>
                  <Row className='justify-content-end'>
                    <div className='text-end'>
                      <Button type='submit' color='primary' disabled={loading}>
                        Save
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EditUnit;
