import mime from 'mime';
import React from 'react';
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from 'reactstrap';

import formatBytes from 'helpers/format-bytes';
import timeSince from 'helpers/time-since';
import { Attachment } from 'types';

import FilesSVG from '../../assets/svg/files.svg';

type AttachmentListProps = {
  attachmentList: Attachment[];
  onDeleteClick: (id: string) => void;
  onRenameClick: (id: string, currentName: string) => void;
  onDownloadClick: (id: string, downloadName) => void;
};

const AttachmentList = ({
  attachmentList,
  onDownloadClick,
  onRenameClick,
  onDeleteClick,
}: AttachmentListProps) => {
  if (!attachmentList || attachmentList.length <= 0)
    return (
      <div className='mt-4 mb-4 d-flex align-items-center justify-content-center flex-column'>
        <img src={FilesSVG} alt='files' width={200} className='img-fluid' />
        <div className='mt-3'>
          <h5 className='font-size-16 mb-0'>Không có tệp tin nào</h5>
        </div>
      </div>
    );

  return (
    <React.Fragment>
      <div className='mt-4'>
        <div className='d-flex flex-wrap'>
          <h5 className='font-size-16 me-3'>File</h5>
        </div>
        <hr className='mt-2' />

        <div className='table-responsive'>
          <Table className='table align-middle table-nowrap table-hover mb-0'>
            <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Type</th>
                <th scope='col'>Created At</th>
                <th scope='col' colSpan={2}>
                  Size
                </th>
              </tr>
            </thead>
            <tbody>
              {attachmentList.map((attachment, key) => (
                <tr key={key}>
                  <td className='text-dark fw-medium font-weight-bold font-size-14'>
                    <i className='mdi mdi-file-document font-size-16 align-middle text-primary me-2"'></i>{' '}
                    {attachment.filename}
                  </td>
                  <td>{mime.getExtension(attachment.mimetype)}</td>
                  <td>
                    {Date.now() - attachment.updatedAt >= 846000000
                      ? new Date(attachment.updatedAt).toLocaleString('vi-VN')
                      : timeSince(attachment.updatedAt)}
                  </td>
                  <td>
                    {Date.now() - attachment.createdAt >= 846000000
                      ? new Date(attachment.createdAt).toLocaleString('vi-VN')
                      : timeSince(attachment.createdAt)}
                  </td>
                  <td>{formatBytes(attachment.size)}</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle tag='a' className='font-size-16 text-muted' role='button'>
                        <i className='mdi mdi-dots-horizontal'></i>
                      </DropdownToggle>

                      <DropdownMenu className='dropdown-menu-end'>
                        <DropdownItem
                          onClick={() =>
                            onDownloadClick(
                              attachment?._id || '',
                              `${attachment.filename}.${
                                mime.getExtension(attachment.mimetype) || ''
                              }`
                            )
                          }
                        >
                          Download
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => onRenameClick(attachment?._id || '', attachment.filename)}
                        >
                          Rename
                        </DropdownItem>
                        <div className='dropdown-divider'></div>
                        <DropdownItem onClick={() => onDeleteClick(attachment?._id || '')}>
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttachmentList;
