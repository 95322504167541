import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';

import { Directory } from 'types';

type DirectoryListProps = {
  directory: Directory;
  onDeleteClick: (id: string) => void;
  onRenameClick: (id: string, currentName: string) => void;
};

const DirectoryList = ({ directory, onDeleteClick, onRenameClick }: DirectoryListProps) => {
  const navigate = useNavigate();

  if (!directory || directory.directoryList.length === 0) {
    return null;
  }

  const directoryName: string = directory.name === 'root' ? 'System' : directory.name;

  return (
    <React.Fragment>
      <div>
        <Row className='mb-3'>
          <Col xl={3} sm={6}>
            <div className='mt-2'>
              <h5>{directoryName}</h5>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {directory.directoryList.map((dir) => (
            <Col xl={4} sm={6} key={dir?._id}>
              <Card onClick={(e) => e.stopPropagation()} className='shadow-none border'>
                <CardBody className='p-3'>
                  <div>
                    <div className='float-end ms-2'>
                      <UncontrolledDropdown className='mb-2'>
                        <DropdownToggle className='font-size-16 text-muted' tag='a'>
                          <i className='mdi mdi-dots-horizontal'></i>
                        </DropdownToggle>

                        <DropdownMenu className='dropdown-menu-end'>
                          <DropdownItem onClick={() => navigate(`/storage?folder=${dir._id}`)}>
                            Mở
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onRenameClick(dir._id, dir.name);
                            }}
                          >
                            Rename
                          </DropdownItem>
                          <div className='dropdown-divider'></div>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onDeleteClick(dir._id);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className='avatar-xs me-3 mb-3'>
                      <div className='avatar-title bg-transparent rounded'>
                        <i className='bx bxs-folder font-size-24 text-warning'></i>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='overflow-hidden me-auto'>
                        <h5 className='font-size-14 text-truncate mb-1'>
                          <Link
                            className='text-body'
                            style={{ fontWeight: 'bold' }}
                            to={`/storage?folder=${dir._id}`}
                          >
                            {dir.name}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DirectoryList;
