import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import UnitService from 'services/unit.service';
import { UnitType } from 'types';

const CreateUnit = () => {
  useTitle('Create unit', {
    restoreOnUnmount: true,
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  type AddUnitProps = {
    name: string;
    description: string;
    type: UnitType;
    contactEmail: string;
    contactPhone: string;
  };

  const addUnit = async (unit: AddUnitProps) => {
    setLoading(true);
    try {
      await UnitService.createUnit(
        unit.name,
        unit.description,
        unit.type,
        unit.contactEmail,
        unit.contactPhone
      );
      toast.success('A new unit has been added successfully');
      navigate('/unit-management');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const validation = useFormik({
    initialValues: {
      name: '',
      description: 'none',
      type: UnitType.ORGANIZATION,
      contactEmail: '',
      contactPhone: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your unit name'),
      description: Yup.string().required('Please enter your unit description'),
      type: Yup.string().required('Please enter your unit type'),
      contactEmail: Yup.string()
        .email('Invalid email')
        .required('Please enter your unit contact email'),
      contactPhone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number'),
    }),
    onSubmit: (values) => {
      addUnit(values);
    },
  });

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title='Unit Management'
            breadcrumbItem='Create unit'
            backTo='/unit-management'
          />
        </Container>
        <Row>
          <Col lg='12'>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>Create new unit</CardTitle>
                <Form onSubmit={validation.handleSubmit} autoComplete='off'>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='name' className='col-form-label col-lg-2'>
                      Name
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='name'
                        className='form-control'
                        name='name'
                        type='text'
                        placeholder='Enter Unit name...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        invalid={validation.touched.name && validation.errors.name ? true : false}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type='invalid'>{validation.errors.name}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='type' className='col-form-label col-lg-2'>
                      Type
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='type'
                        className='form-control'
                        name='type'
                        type='select'
                        placeholder='Enter Unit type...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ''}
                        invalid={validation.touched.type && validation.errors.type ? true : false}
                      >
                        <option value={UnitType.ORGANIZATION}>Organization</option>
                        <option value={UnitType.TEACHER}>Teacher</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type='invalid'>{validation.errors.type}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='description' className='col-form-label col-lg-2'>
                      Description
                    </Label>
                    <Col lg={10}>
                      <Input
                        tag='textarea'
                        id='description'
                        rows={3}
                        className='form-control'
                        name='description'
                        placeholder='Enter Unit description...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                        invalid={
                          validation.touched.description && validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type='invalid'>{validation.errors.description}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='contactEmail' className='col-form-label col-lg-2'>
                      Email
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='contactEmail'
                        name='contactEmail'
                        className='form-control'
                        type='email'
                        placeholder='Enter Unit contact email...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactEmail || ''}
                        invalid={
                          validation.touched.contactEmail && validation.errors.contactEmail
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contactEmail && validation.errors.contactEmail ? (
                        <FormFeedback type='invalid'>{validation.errors.contactEmail}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup className='mb-4' row>
                    <Label htmlFor='contactPhone' className='col-form-label col-lg-2'>
                      Phone number
                    </Label>
                    <Col lg={10}>
                      <Input
                        id='contactPhone'
                        name='contactPhone'
                        className='form-control'
                        type='text'
                        placeholder='Enter Unit contact phone...'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactPhone || ''}
                        invalid={
                          validation.touched.contactPhone && validation.errors.contactPhone
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contactPhone && validation.errors.contactPhone ? (
                        <FormFeedback type='invalid'>{validation.errors.contactPhone}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <Row className='justify-content-end'>
                    <div className='text-end'>
                      <Button type='submit' color='primary' disabled={loading}>
                        Create unit
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CreateUnit;
