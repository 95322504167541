import { Card } from 'reactstrap';

import { PieChart } from 'Components/Common/PieChart';
import { ChartData } from 'types';

type EventCompositionProps = {
  title: string;
  charts: Array<ChartData>;
};

const EventComposition = ({ title, charts }: EventCompositionProps) => {
  return (
    <Card className='tw-relative tw-flex tw-flex-col tw-p-4 xl:tw-col-span-3'>
      <div className='tw-relative tw-font-bold'>{title}</div>
      <div className='tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mt-4 tw-gap-4 tw-justify-between'>
        {charts.map((chartData) => {
          return (
            <div key={chartData.title} className='tw-flex tw-flex-col tw-items-center'>
              <div className='tw-font-bold tw-mb-2'>{chartData.title}</div>
              <PieChart chartData={chartData} />
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default EventComposition;
