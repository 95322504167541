import { Dispatch } from 'redux';

import UserService from 'services/user.service';
import { User } from 'types';

import { loginSuccess, logoutUserSuccess } from './reducer';

export const getProfile = () => async (dispatch: Dispatch) => {
  try {
    const res = await UserService.getMyProfile();
    const user: User = res.data.payload;

    dispatch(loginSuccess(user));
  } catch (error) {
    localStorage.removeItem('token');
    dispatch(logoutUserSuccess());
  }
};

export const logoutUser = () => (dispatch: Dispatch) => {
  try {
    localStorage.removeItem('token');
    dispatch(logoutUserSuccess());
  } catch (error) {
    return error;
  }
};
